import { z } from 'zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import * as Icons from 'lucide-react'

import { useToast } from '@prostpost/toast'
import { Input } from '@prostpost/uikit-next'
import { Modal, VStack, Heading } from '@prostpost/uikit'

import { useUserAnalyticsEvents } from 'app/config/analytics/events/user'
import { useZodValidators, useSupabaseAuth } from 'app/domains/User/hooks'

import { routesUrls } from 'app/routes/urls'

type FormData = {
	password: string
	repeatPassword: string
}

type Msg = { type: 'on_success' } | { type: 'on_close' }
type Props = {
	onMsg: (msg: Msg) => void
}

const useToasts = () => {
	const { t } = useTranslation()

	const changePasswordFailed = useToast({
		type: 'error',
		text: t('notify:auth.changePassword.error', 'Unable to change password. Please try again'),
	})

	const samePassword = useToast({
		type: 'error',
		text: t('notify:auth.samePassword.error', 'New password should be different from the old password'),
	})

	const passwordChanged = useToast({
		type: 'success',
		text: t('notify:auth.changePassword.success', 'Password changed successfully'),
	})

	return {
		samePassword,
		passwordChanged,
		changePasswordFailed,
	}
}

export const ChangePassword = ({ onMsg }: Props) => {
	const { t } = useTranslation()
	const { zodPassword } = useZodValidators()

	const toasts = useToasts()
	const auth = useSupabaseAuth()
	const userEvents = useUserAnalyticsEvents()

	const [isLoading, setIsLoading] = useState(false)
	const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false)
	const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false)

	const formSchema: z.ZodSchema<FormData> = z
		.object({
			password: z
				.string()
				.min(1, zodPassword.nonemptyMsg(t))
				.min(8, zodPassword.lengthMsg(t))
				.regex(zodPassword.regex, zodPassword.regexpMsg(t)),
			repeatPassword: z.string(),
		})
		.refine(zodPassword.matched, {
			path: ['repeatPassword'],
			message: zodPassword.matchedMsg(t),
		})

	const {
		control,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		reValidateMode: 'onSubmit',
		resolver: zodResolver(formSchema),
		defaultValues: {
			password: '',
			repeatPassword: '',
		},
	})

	const onChangePassword = async (data: FormData) => {
		try {
			setIsLoading(true)
			const { error } = await auth.updateUser(
				{
					password: data.password,
				},
				{
					emailRedirectTo: routesUrls.inbox,
				},
			)

			setIsLoading(false)
			if (error) {
				console.error(error)

				if (error.message === 'New password should be different from the old password.') {
					toasts.samePassword.show()
					return
				}

				toasts.changePasswordFailed.show()
				return
			}

			toasts.passwordChanged.show()
			onMsg({ type: 'on_success' })
		} catch (error: unknown) {
			setIsLoading(false)
			console.error(error)
			toasts.changePasswordFailed.show()
		}
	}

	return (
		<Modal variant="confirm">
			<Modal.Close
				onClick={() => {
					userEvents.clicks.passwordChangeCancel()
					onMsg({ type: 'on_close' })
				}}
			/>
			<Modal.Content>
				<Heading h={4}>{t('userSettings.security.changePasswordTitle', 'Change your password')}</Heading>
				<VStack as="form" w="100%" mt={3} space={3}>
					<Controller
						name="password"
						control={control}
						render={({ field }) => (
							<Input
								size="xs"
								type={isNewPasswordVisible ? 'text' : 'password'}
								variant={errors.password ? 'error' : 'normal'}
								message={errors.password?.message}
								useIcon={isNewPasswordVisible ? Icons.EyeOff : Icons.Eye}
								label={t('auth.changePassword.newPasswordPlaceholder', 'New password')}
								labelPosition="outside"
								placeholder={t(
									'auth.changePassword.newPasswordPlaceholder',
									'at least 8 characters and a number',
								)}
								{...field}
								onIconClick={() => setIsNewPasswordVisible(p => !p)}
								onChange={v => {
									field.onChange(v)
									clearErrors('password')
								}}
							/>
						)}
					/>

					<Controller
						name="repeatPassword"
						control={control}
						render={({ field }) => (
							<Input
								size="xs"
								type={isRepeatPasswordVisible ? 'text' : 'password'}
								variant={errors.repeatPassword ? 'error' : 'normal'}
								message={errors.repeatPassword?.message}
								useIcon={isRepeatPasswordVisible ? Icons.EyeOff : Icons.Eye}
								label={t('userSettings.security.repeatPasswordLabel', 'Repeat password')}
								labelPosition="outside"
								placeholder={t('auth.changePassword.repeatPasswordPlaceholder', 'enter it again')}
								{...field}
								onIconClick={() => setIsRepeatPasswordVisible(p => !p)}
								onChange={v => {
									field.onChange(v)
									clearErrors('repeatPassword')
								}}
							/>
						)}
					/>
				</VStack>
			</Modal.Content>
			<Modal.ConfirmSecondaryAction
				onClick={() => {
					userEvents.clicks.passwordChangeCancel()
					onMsg({ type: 'on_close' })
				}}
			>
				{t('channel.settings.archiveConfirm.secondaryAction', 'Cancel')}
			</Modal.ConfirmSecondaryAction>
			<Modal.ConfirmPrimaryAction
				state={isLoading ? 'pending' : 'normal'}
				onClick={() => {
					userEvents.clicks.passwordChangeSubmit()
					handleSubmit(onChangePassword)()
				}}
			>
				{t('userSettings.security.passwordChangeAction', 'Update')}
			</Modal.ConfirmPrimaryAction>
		</Modal>
	)
}
