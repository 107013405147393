import { useQuery } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseQueryOptions } from 'react-query'

import { parseFirstError, parseList, stringifyQueryParams } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, JsonApiResponseList, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { ChannelActive } from '..'

import { ActiveChannelSchema } from './schemas'

const knownErrors = [] as const

const fetchActiveChannels = (): Promise<ChannelActive[]> =>
	axiosInstance
		.get<JsonApiResponseList>(
			`/drafts/channel?${stringifyQueryParams({
				sort: ['createdAt'],
				filter: [{ name: 'archived_at', op: 'isnull', val: null }],
			})}`,
		)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			console.error(e)
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseList(response.data, ['membersDelta'])
			return (result.data || []).map(ch => ActiveChannelSchema.parse(ch))
		})

export const useActiveChannels = (
	options: Omit<
		UseQueryOptions<ChannelActive[], ParsedError<(typeof knownErrors)[number]>, ChannelActive[]>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		activeChannelsQuery: useQuery<ChannelActive[], ParsedError<(typeof knownErrors)[number]>, ChannelActive[]>(
			'active_channels',
			fetchActiveChannels,
			options,
		),
	}
}
