import type { ComponentProps } from 'react'

import { Box } from '@prostpost/uikit'

import { Icons } from 'app/resources'
import { notReachable } from '@prostpost/utils'

type Props = ComponentProps<typeof Box> &
	(
		| {
				variant: 'light'
				iconOnly: boolean
		  }
		| {
				variant: 'dark'
				iconOnly?: never
		  }
	)

export const ProstpostLogo = ({ variant, iconOnly, ...boxProps }: Props) => {
	switch (variant) {
		case 'dark':
			return (
				<Box {...boxProps}>
					<Icons.LogoWithTextBlueBlack />
				</Box>
			)
		case 'light':
			return iconOnly ? (
				<Box {...boxProps}>
					<Icons.LogoIconYellow />
				</Box>
			) : (
				<Box {...boxProps}>
					<Icons.LogoWithTextYellowWhite />
				</Box>
			)
		default:
			return notReachable(variant)
	}
}
