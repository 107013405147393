import { observer, Observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { HStack, VStack, Heading, Paragraph } from '@prostpost/uikit'

import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { useSubscriptionExpired } from 'app/domains/PricingPlan/hooks'
import { useLimitsResetDate } from 'app/domains/UserPreferences/hooks'
import { BillingAction } from 'app/domains/PricingPlan/features/BillingAction'
import { SubscribeAction } from 'app/domains/PricingPlan/features/SubscribeAction'

const Action = observer(() => {
	const isExpired = useSubscriptionExpired()
	const { preferences } = useOnboardedUserStore()

	if (!isExpired && !preferences.isSubscriptionCanceled && preferences.plan.name !== 'TRIAL') {
		return <BillingAction />
	}

	if (preferences.plan.name === 'MONTH' || preferences.plan.name === 'ANNUAL') {
		return <SubscribeAction planToActivate={preferences.plan} />
	}

	return null
})

// have to be a separate component with observer to update on change
const TrialDescription = () => {
	const { t } = useTranslation()

	const isExpired = useSubscriptionExpired()
	const limitsResetDate = useLimitsResetDate()
	const formattedLimitsResetDate = limitsResetDate.format('MMMM DD, YYYY')

	if (formattedLimitsResetDate) {
		return (
			<Observer>
				{() =>
					isExpired ? (
						<>
							{t(
								'userSettings.plans.limitsDescPassedTrial',
								'Trial period ended on {{formattedLimitsResetDate}}',
								{
									formattedLimitsResetDate,
								},
							)}
						</>
					) : (
						<>
							{t(
								'userSettings.plans.limitsDescTrial',
								'Trial period ends on {{formattedLimitsResetDate}}',
								{
									formattedLimitsResetDate,
								},
							)}
						</>
					)
				}
			</Observer>
		)
	}

	return t('userSettings.plans.limitsDescNoDateTrial', 'You are currently in trial period')
}

const Trial = () => {
	const { t } = useTranslation()
	return (
		<VStack space={1}>
			<Heading h={5}>{t('userSettings.plans.currentTrial', 'Trial period')}</Heading>
			<Paragraph size={14} color="blue_40">
				<TrialDescription />
			</Paragraph>
		</VStack>
	)
}

const PlanDescription = () => {
	const { t } = useTranslation()

	const user = useOnboardedUserStore()
	const isExpired = useSubscriptionExpired()
	const limitsResetDate = useLimitsResetDate()
	const formattedLimitsResetDate = limitsResetDate.format('MMMM DD, YYYY')

	if (isExpired) {
		return t('userSettings.plans.limitsDescExpired', 'Expired ∙ Last active day {{formattedLimitsResetDate}}', {
			formattedLimitsResetDate,
		})
	}

	if (user.preferences.isSubscriptionCanceled) {
		return t('userSettings.plans.limitsDescCanceled', 'Canceled ∙ Last active day {{formattedLimitsResetDate}}', {
			formattedLimitsResetDate,
		})
	}

	return (
		<Observer>
			{() => {
				return (
					<>
						{formattedLimitsResetDate && user.preferences.plan.name === 'MONTH'
							? t(
									'userSettings.plans.limitsDescMonth',
									'Billed monthly ∙ Next payment on {{formattedLimitsResetDate}}',
									{
										formattedLimitsResetDate,
									},
								)
							: formattedLimitsResetDate
								? t(
										'userSettings.plans.limitsDescAnnual',
										'Billed annually ∙ Next payment on {{formattedLimitsResetDate}}',
										{ formattedLimitsResetDate },
									)
								: t('userSettings.plans.limitsDescNoDate', 'You have an active subscription')}
					</>
				)
			}}
		</Observer>
	)
}

const Plan = () => {
	const { t } = useTranslation()
	return (
		<VStack space={1}>
			<HStack space={2} align="center" just="space-between">
				<VStack space={1}>
					<Heading h={5}>{t('userSettings.plans.currentSub', 'Active subscription')}</Heading>
					<Paragraph size={14} color="blue_40">
						<PlanDescription />
					</Paragraph>
				</VStack>
				<Action />
			</HStack>
		</VStack>
	)
}

export const SubscriptionSummary = { Trial, Plan }
