import { useEffect } from 'react'
import { observable, runInAction } from 'mobx'

import { getNow, datetime, notReachable } from '@prostpost/utils'

import { config } from 'app/config'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'

type Stickers = 'TRIAL' | 'SUBSCRIPTION_CANCELED' | 'NONE'

// we need a shared store here since we use useShowTopSticker is a lot of places
// to check if a sticker should be shown or not so local React state doesn't work here
const topStickerStore = observable<{ visible: Stickers }>({ visible: 'NONE' })

export const useShowTopSticker = () => {
	const { preferences } = useOnboardedUserStore()
	const showUntilDate = config.localStorage.getItem('showTopStickerTimeout')

	const onDismissSticker = () => {
		runInAction(() => {
			topStickerStore.visible = 'NONE'
			const yesterday = datetime(getNow(false).subtract(1, 'day'), false).format('YYYY-MM-DD[T]HH:mm:ss')
			config.localStorage.setItem('showTopStickerTimeout', yesterday)
		})
	}

	useEffect(() => {
		runInAction(() => {
			if (!showUntilDate) {
				config.localStorage.setItem(
					'showTopStickerTimeout',
					preferences.nextSubscriptionPeriod.format('YYYY-MM-DD[T]HH:mm:ss'),
				)
				return
			}

			let sticker: Stickers = 'NONE'

			switch (preferences.plan.name) {
				case 'TRIAL':
					sticker = 'TRIAL'
					config.localStorage.removeItem('showTopStickerTimeout')
					break
				case 'MONTH':
				case 'ANNUAL':
					if (preferences.isSubscriptionCanceled) {
						sticker = 'SUBSCRIPTION_CANCELED'
					}
					break
				case 'TEAM':
					break
				default:
					notReachable(preferences.plan)
			}

			if (datetime(showUntilDate, false).isAfter(getNow(false))) {
				topStickerStore.visible = sticker
			} else {
				topStickerStore.visible = 'NONE'
			}
		})
	}, [preferences.plan.name])

	return { topSticker: topStickerStore, onDismissSticker }
}
