import { useChannelsStore } from 'app/domains/Channel/store'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'

type Return = { channelsLimitUsed: number; channelsLimitTotal: number | null; channelsLimitReached: boolean }

export const useUserChannelsLimit = (): Return => {
	const channels = useChannelsStore()
	const user = useOnboardedUserStore()

	const channelsLimitUsed = channels.activeList.length
	const channelsLimitTotal = user.preferences.limits.channels

	return {
		channelsLimitReached: !!(channelsLimitTotal && channelsLimitUsed >= channelsLimitTotal),
		channelsLimitUsed,
		channelsLimitTotal: channelsLimitTotal || 1,
	}
}
