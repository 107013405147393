import { useQuery } from 'react-query'
import type { AxiosError } from 'axios'

import { parseFirstError, parseList } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, ParsedError, JsonApiResponseList } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import { WaitlistsSchema } from './schemas'
import type { Waitlists } from '..'

const knownErrors = ['UNABLE_TO_GET_WAITLISTS'] as const

const getWaitlists = (): Promise<Waitlists[]> =>
	axiosInstance
		.get<JsonApiResponseList>('/auth/waitlist')
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseList(response.data)
			return WaitlistsSchema.parse(result.data || [])
		})

export const useGetWaitlists = () => {
	return {
		waitlistsQuery: useQuery<Waitlists[], ParsedError<(typeof knownErrors)[number]>>('waitlists', getWaitlists),
	}
}
