import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useTheme } from '@emotion/react'
import * as Icons from 'lucide-react'

import { Toggle, Group } from '@prostpost/uikit'
import { Dropdown } from '@prostpost/uikit-next'

import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import {
	useLanguages,
	useTimezones,
	useUserLanguage,
	useUserTimezone,
	useDefaultTimezone,
} from 'app/domains/UserPreferences/hooks'
import type { UpdatePreferencesData, AdditionalPreferences } from 'app/domains/UserPreferences/api'

const DEFAULT_LANG = 'en'

type Msg =
	| { type: 'on_change_language'; value: string }
	| { type: 'on_change_time_zone'; value: string }
	| { type: 'on_change_time_format'; value: boolean }

type Props = {
	loadingState: Array<keyof UpdatePreferencesData | keyof AdditionalPreferences>
	onMsg: (msg: Msg) => void
}

export const UpdateTimeRegion = observer(function UpdateTimeRegion({ loadingState, onMsg }: Props) {
	const theme = useTheme()
	const { t } = useTranslation()
	const { preferences } = useOnboardedUserStore()

	const languages = useLanguages()
	const timezones = useTimezones()

	const language = useUserLanguage(preferences)
	const timezone = useUserTimezone(preferences)
	const defaultTimezone = useDefaultTimezone()

	return (
		<Group>
			<Group.Title title={t('userSettings.preferences.timeRegionTitle', 'Time & region')}>
				<Icons.Clock4 size={16} strokeWidth={2.5} color={theme.colors.black_80} />
			</Group.Title>
			<Group.Item>
				<Dropdown
					size="sm"
					name="lang"
					value={language}
					options={languages}
					label={t('userSettings.preferences.langLabel', 'Language')}
					variant={loadingState.includes('lang') ? 'disabled' : 'normal'}
					onSelect={option => onMsg({ type: 'on_change_language', value: option?.value ?? DEFAULT_LANG })}
					isSearchEnabled={false}
				/>
			</Group.Item>
			<Group.Item>
				<Dropdown
					size="sm"
					name="timeZone"
					value={timezone}
					options={timezones}
					label={t('userSettings.preferences.timeZoneLabel', 'Time zone')}
					variant={loadingState.includes('timeZone') ? 'disabled' : 'normal'}
					onSelect={option =>
						onMsg({ type: 'on_change_time_zone', value: option?.value ?? defaultTimezone.value })
					}
					isSearchEnabled
					searchPlaceholder={t(
						'content:userSettings.timeZoneSearchPlaceholder',
						'Start typing to filter list...',
					)}
				/>
			</Group.Item>
			<Group.Item>
				<Group.ItemContent>
					<Toggle
						on={preferences.timeFormat24}
						disabled={loadingState.includes('timeFormat24')}
						onToggle={value => onMsg({ type: 'on_change_time_format', value })}
					>
						<Toggle.Label color="black_80">
							{t('userSettings.preferences.timeFormatLabel', 'Show time using 24 hours format')}
						</Toggle.Label>
					</Toggle>
				</Group.ItemContent>
			</Group.Item>
		</Group>
	)
})
