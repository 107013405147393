import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from '@prostpost/toast'
import { notReachable } from '@prostpost/utils'
import { Modal, Paragraph, Heading } from '@prostpost/uikit'

import { useBotAnalyticsEvents } from 'app/config/analytics/events/bot'
import { useUnsetBotLink } from 'app/domains/Bot/features/UnlinkBotFromUser/hooks'

import { ReactModal } from 'app/modals'

export type Msg = { type: 'on_bot_unlink_modal_close' }
type MsgProp = {
	onMsg: (msg: Msg) => void
}

export type Interface = {
	isOpen: boolean
}

export const UnlinkBotFromUserModal = ({ isOpen, onMsg }: Interface & MsgProp) => {
	const { t } = useTranslation()
	const botEvents = useBotAnalyticsEvents()

	const botUnlinkSuccessToast = useToast({ type: 'success' })
	const botUnlinkErrorToast = useToast({ type: 'error' })

	const { botLinkStatus, unsetBotLink } = useUnsetBotLink()

	useEffect(() => {
		switch (botLinkStatus.type) {
			case 'idle':
			case 'loading':
				break
			case 'error':
				botUnlinkErrorToast.show({ text: botLinkStatus.message })
				break
			case 'success':
				onMsg({ type: 'on_bot_unlink_modal_close' })
				botUnlinkSuccessToast.show({ text: botLinkStatus.message })
				break
			default:
				notReachable(botLinkStatus)
		}
	}, [botLinkStatus])

	return (
		<ReactModal
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
			isOpen={isOpen}
			onRequestClose={e => {
				e.stopPropagation()
				e.preventDefault()
				onMsg({ type: 'on_bot_unlink_modal_close' })
			}}
		>
			<Modal variant="confirm">
				<Modal.Close
					onClick={() => {
						botEvents.clicks.unlinkCancel()
						onMsg({ type: 'on_bot_unlink_modal_close' })
					}}
				/>
				<Modal.Content>
					<Heading h={4}>{t('botLink.reconnectAccountTitle', 'Unlink bot')}</Heading>
					<Paragraph my={3} size={14} color="blue_40">
						{t(
							'bot.unlink.description',
							'By unlinking our bot you will loose many features. You can always connect your bot in user settings again. Are you sure you want to unlink bot from your account?',
						)}
					</Paragraph>
				</Modal.Content>
				<Modal.ConfirmSecondaryAction
					onClick={() => {
						botEvents.clicks.unlinkCancel()
						onMsg({ type: 'on_bot_unlink_modal_close' })
					}}
				>
					{t('channel.bot.linkCancel', 'Cancel')}
				</Modal.ConfirmSecondaryAction>
				<Modal.ConfirmPrimaryAction
					variant="danger"
					state={botLinkStatus.type === 'loading' ? 'pending' : 'normal'}
					onClick={() => {
						botEvents.clicks.unlinkSubmit()
						unsetBotLink()
					}}
				>
					{t('userSettings.bot.unlinkConfirm', 'Unlink bot')}
				</Modal.ConfirmPrimaryAction>
			</Modal>
		</ReactModal>
	)
}
