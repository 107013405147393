import ReactModal from 'react-modal'
import { observer } from 'mobx-react-lite'
import { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import * as Icons from 'lucide-react'

import { notReachable } from '@prostpost/utils'
import { Modal, Heading, Paragraph, TextLink, HStack, Button, Flex } from '@prostpost/uikit'

import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { usePlansAnalyticsEvents } from 'app/config/analytics/events/plans'
import type { PricingPlan } from 'app/domains/PricingPlan'

type Msg =
	| { type: 'on_close' }
	| { type: 'on_confirm'; count: number }
	| { type: 'on_click_contact_us' }
	| { type: 'on_click_switch_to_annual' }

type Props = {
	isOpen: boolean
	isPending: boolean
	currencySign: string
	plan: Extract<PricingPlan, { name: 'MONTH' | 'ANNUAL' }>
	onMsg: (msg: Msg) => void
}

const MAX_ADDITIONAL_CHANNELS_COUNT = 5

export const ModalExtraChannels = observer(({ isOpen, isPending, currencySign, plan, onMsg }: Props) => {
	const { t } = useTranslation()
	const planEvents = usePlansAnalyticsEvents()
	const { preferences } = useOnboardedUserStore()

	const [additionalChannelsCount, setAdditionalChannelsCount] = useState(1)

	const extraChannelsPurchased = preferences.limits.channels - plan.limits.channels
	const maxExtraChannelsAvailableToAdd =
		MAX_ADDITIONAL_CHANNELS_COUNT +
		plan.limits.channels -
		preferences.limits.channels -
		preferences.channelSlotsToReclaim

	const onClose = () => {
		planEvents.clicks.moreChannelsModalClosed()
		onMsg({ type: 'on_close' })
	}

	const onConfirm = () => {
		planEvents.clicks.moreChannelsModalConfirm()
		onMsg({ type: 'on_confirm', count: additionalChannelsCount + extraChannelsPurchased })
	}

	const onClickSwitchToAnnual = () => {
		planEvents.clicks.switchToAnnualOnAddingChannels()
		onMsg({ type: 'on_click_switch_to_annual' })
	}

	const onClickContactUs = () => {
		planEvents.clicks.contactUsForHigherLimits()
		onMsg({ type: 'on_click_contact_us' })
	}

	const onIncChannel = () => {
		if (additionalChannelsCount >= maxExtraChannelsAvailableToAdd) return
		setAdditionalChannelsCount(p => p + 1)
		planEvents.clicks.chooseAdditionalChannels('inc')
	}

	const onDecChannel = () => {
		if (additionalChannelsCount < 2) return
		setAdditionalChannelsCount(p => p - 1)
		planEvents.clicks.chooseAdditionalChannels('dec')
	}

	useEffect(() => {
		if (!isPending) onMsg({ type: 'on_close' })
	}, [isPending])

	return (
		<ReactModal
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
			isOpen={isOpen}
			onRequestClose={e => {
				e.preventDefault()
				e.stopPropagation()
				onClose()
			}}
		>
			<Modal variant="confirm">
				<Modal.Close onClick={onClose} />
				<Modal.Content>
					<Heading h={4}>
						<Trans i18nKey="content:plans.channelsLimit.title">
							How many channels
							<br />
							do you want to add?
						</Trans>
					</Heading>
					<Paragraph my={3} size={14} color="blue_40">
						{(() => {
							switch (plan.name) {
								case 'MONTH':
									return (
										<Trans
											i18nKey="content:plans.channelsLimit.descriptionMonth3"
											values={{
												currencySign,
												channelPrice: plan.limits.channelPrice || 0,
												channelsIncludedInPlan: plan.limits.channels,
												extraChannelsPurchased: extraChannelsPurchased
													? ` (and ${extraChannelsPurchased} extra)`
													: '',
											}}
										>
											{
												'You are on monthly plan with {{channelsIncludedInPlan}} channels included{{extraChannelsPurchased}} channels. You can save around 30% on additional channels by '
											}
											<TextLink variant="plain" onClick={onClickSwitchToAnnual}>
												{'switching to annual plan.'}
											</TextLink>
										</Trans>
									)
								case 'ANNUAL':
									return t(
										'content:plans.channelsLimit.descriptionAnnual3',
										'You are on annual plan with {{channelsIncludedInPlan}} included{{extraChannelsPurchased}} channels. You can add an extra channel for {{currencySign}}{{channelPrice}} per month.',
										{
											currencySign,
											channelPrice: plan.limits.channelPrice || 0,
											channelsIncludedInPlan: plan.limits.channels,
											extraChannelsPurchased: extraChannelsPurchased
												? ` (and ${extraChannelsPurchased} extra)`
												: '',
										},
									)
								default:
									return notReachable(plan)
							}
						})()}
					</Paragraph>
					<HStack space={6} align="center" just="center" py={5}>
						{additionalChannelsCount < 2 ? (
							<Button.Multi state="disabled" icon={{ Icon: Icons.Minus, position: 'left' }} />
						) : (
							<Button.Multi icon={{ Icon: Icons.Minus, position: 'left' }} onClick={onDecChannel} />
						)}

						{/* Since text width is variable set block's width to 30px to avoid layout jumping on change */}
						<Flex w="30px" align="center" just="center">
							<Heading h={3}>{additionalChannelsCount}</Heading>
						</Flex>

						{additionalChannelsCount >= maxExtraChannelsAvailableToAdd ? (
							<Button.Multi state="disabled" icon={{ Icon: Icons.Plus, position: 'left' }} />
						) : (
							<Button.Multi icon={{ Icon: Icons.Plus, position: 'left' }} onClick={onIncChannel} />
						)}
					</HStack>
					{additionalChannelsCount >= maxExtraChannelsAvailableToAdd && (
						<Paragraph centered size={12} mb={2} color="blue_40">
							<Trans i18nKey="content:plans.channelsLimit.max">
								{'To avoid system abuse we limit maximum amount of channels per account. Please '}
								<TextLink variant="plain" onClick={onClickContactUs}>
									{'contact us'}
								</TextLink>
								{' if you need more.'}
							</Trans>
						</Paragraph>
					)}
				</Modal.Content>
				<Modal.ConfirmPrimaryAction
					variant="primary"
					state={isPending || additionalChannelsCount > maxExtraChannelsAvailableToAdd ? 'pending' : 'normal'}
					onClick={onConfirm}
				>
					{(() => {
						switch (plan.name) {
							case 'MONTH':
								return t(
									'content:plans.channelsLimit.confirmMonth',
									'Add for {{currencySign}}{{totalPrice}} per month',
									{ totalPrice: plan.limits.channelPrice * additionalChannelsCount, currencySign },
								)
							case 'ANNUAL':
								return t(
									'content:plans.channelsLimit.confirmYear',
									'Add for {{currencySign}}{{totalPrice}} per month',
									{ totalPrice: plan.limits.channelPrice * additionalChannelsCount, currencySign },
								)
							default:
								return notReachable(plan)
						}
					})()}
				</Modal.ConfirmPrimaryAction>
			</Modal>
		</ReactModal>
	)
})
