import type { TFunction } from 'i18next'

type PasswordMatchData = {
	password: string
	repeatPassword: string
}

const zodPassword = {
	regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!$%@#£€*?&._-]{8,}$/,
	matched: (data: PasswordMatchData) => data.password === data.repeatPassword,
	matchedMsg: (t: TFunction) => t('auth.resetPassword.PasswordsDontMatch', "Passwords don't match"),
	lengthMsg: (t: TFunction) => t('auth.resetPassword.passwordFieldLength', 'Must contain at least 8 characters'),
	nonemptyMsg: (t: TFunction) => t('auth.resetPassword.passwordFieldRequired', 'Password is required'),
	nonemptyRepeatMsg: (t: TFunction) =>
		t('auth.resetPassword.passwordRepeatFieldRequired', 'Please repeat your password'),
	regexpMsg: (t: TFunction) =>
		t('auth.resetPassword.passwordFieldSecurityFailed', 'More than 8 symbols and number required'),
}

const zodEmail = {
	invalidMsg: (t: TFunction) => t('auth.forgotPassword.emailIsInvalid', 'Email is invalid'),
	nonemptyMsg: (t: TFunction) => t('auth.forgotPassword.emailFieldRequired', 'Email is required'),
}

export const useZodValidators = () => ({
	zodEmail,
	zodPassword,
})
