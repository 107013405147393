export const userRoles = ['ADMIN'] as const
export type UserRoles = (typeof userRoles)[number]

export type Common = {
	uuid: string
	email: string

	ava?: string
	firstName?: string
	lastName?: string
	fullName?: string
}

export type NotOnboardedUser = Common & {
	type: 'NOT_ONBOARDED'
	roles: []
}

export type OnboardedUser = Common & {
	type: 'ONBOARDED'
	roles: UserRoles[]
}

export type User = OnboardedUser | NotOnboardedUser

export const waitlists = {
	TEAM_PLAN: 'TEAM_PLAN',
} as const

export type Waitlists = keyof typeof waitlists
