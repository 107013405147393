import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { config } from 'app/config'

import { routesUrls } from './urls'

type Props = {
	to?: string
}

// #error=access_denied&error_code=403&error_description=Email+link+is+invalid+or+has+expired
export const Component = ({ to }: Props) => {
	const { hash } = useLocation()
	const params = hash
		? hash
				.replace('#', '')
				.split('&')
				.reduce(
					(acc, item) => {
						const [key, value] = item.split('=')
						acc[key] = value
						return acc
					},
					{} as Record<string, string>,
				)
		: null

	if (params?.error === 'access_denied') {
		config.cookies.setCookie('invalid_request', 'true')
		return <Navigate to={routesUrls.email.invalidRequest} />
	}

	if (to) return <Navigate to={to} />
	return <Outlet />
}
