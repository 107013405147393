import { Buy } from './Buy'
import { Busy } from './Busy'
import { Available } from './Available'
import { Reclaim } from './Reclaim'

export const SlotTile = {
	Buy,
	Busy,
	Available,
	Reclaim,
}
