import { useTheme } from '@emotion/react'
import { XIcon } from 'lucide-react'
import type { LucideIcon } from 'lucide-react'

import { useCurrentMedia } from '@prostpost/css'
import { HStack, Text, Box, TextLink } from '@prostpost/uikit'

type Props = {
	message: string
	icon?: LucideIcon
	action: { label: string; isVisible: boolean; onClick: () => void }
} & ({ isDismissible: false; onDismiss?: never } | { isDismissible: true; onDismiss: () => void })

export const TopSticker = ({ message, action, isDismissible, icon: Icon, onDismiss }: Props) => {
	const theme = useTheme()
	const media = useCurrentMedia()

	return (
		<HStack
			space={0}
			p={2}
			ml={media === 'MOBILE' ? '0' : '-10px'}
			align="center"
			just="space-between"
			position="relative"
			bg="yellow_100"
		>
			<HStack space={1} align="center">
				{Icon && <Icon size={14} strokeWidth={2.5} color={theme.colors.black_80} />}
				<Text size={13_500} color="black_80">
					{message}
				</Text>
			</HStack>

			<HStack space={5} position="absolute" align="center" right={3} top="9px">
				{action.isVisible && (
					<Text size={13_500} color="black_80" mt="-5px">
						<TextLink variant="plain" color="black_80" onClick={action.onClick}>
							{action.label}
						</TextLink>
					</Text>
				)}
				{isDismissible && (
					<Box as="button">
						<XIcon size={14} strokeWidth={2.5} color={theme.colors.black_80} onClick={onDismiss} />
					</Box>
				)}
			</HStack>
		</HStack>
	)
}
