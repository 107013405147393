import { useTranslation } from 'react-i18next'

import { Heading, Text } from '@prostpost/uikit'

import { ContainerStyled, LoadingOverlay } from './styled'

export const Reclaim = ({
	until,
	isPending,
	isDisabled,
	onClick,
}: {
	until: string
	isPending?: boolean
	isDisabled?: boolean
	onClick: () => void
}) => {
	const { t } = useTranslation()
	return (
		<ContainerStyled
			py={3}
			px={4}
			space={1}
			maxW="140px"
			borderColor="blue_20"
			bg="blue_20_opaque"
			borderWidth={1}
			borderRadius="small"
			position="relative"
			cursor={isDisabled ? 'default' : 'pointer'}
			isDisabled={!!isDisabled}
			isPending={!!isPending}
			onClick={onClick}
		>
			{isPending ? <LoadingOverlay /> : null}
			<Heading h={5} color="blue_40">
				{t('userSettings.usage.slot.restoreTitle', 'Reclaim')}
			</Heading>
			<Text size={12} color="blue_40">
				{t('userSettings.usage.slot.restoreDsk', 'until {{until}}', { until })}
			</Text>
		</ContainerStyled>
	)
}
