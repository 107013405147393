import { lazy } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import type { RouteObject } from 'react-router-dom'

import { Component as RootRoute } from './root'

export const publicRoutesConfig: RouteObject[] = [
	{
		path: 'legal',
		Component: lazy(() => import('./Policy/WithPolicy')),
		children: [
			{ path: 'privacy', index: true, Component: lazy(() => import('./Policy/Privacy')) },
			{ path: 'use', Component: lazy(() => import('./Policy/Use')) },
			{ path: 'refund', Component: lazy(() => import('./Policy/Refund')) },
			{ path: 'cookie', Component: lazy(() => import('./Policy/Cookies')) },
			{ path: 'impressum', Component: lazy(() => import('./Policy/Impressum')) },
			{ path: 'disclaimer', Component: lazy(() => import('./Policy/Disclaimer')) },
			{ path: 'terms', Component: lazy(() => import('./Policy/TermsAndConditions')) },
			{ path: '', element: <Navigate to="privacy" /> },
		],
	},

	{ path: 'contact', Component: lazy(() => import('./ContactUs')) },
	{ path: 'changelog', Component: lazy(() => import('./Changelog')) },

	// Email confirmations callbacks
	{
		path: 'callback',
		element: <Outlet />,
		children: [
			{ path: 'unsubscribe', Component: lazy(() => import('./EmailConfirmations/UnsubscribeFromNews')) },
			{ path: 'invalidrequest', Component: lazy(() => import('./EmailConfirmations/InvalidRequest')) },
		],
	},
]

export const authRoutesConfig: RouteObject[] = [
	{
		path: 'auth',
		Component: lazy(() => import('./Auth/WithAuth')),
		children: [
			{ index: true, path: 'up', Component: lazy(() => import('./Auth/Signup')) },
			{ path: 'in', Component: lazy(() => import('./Auth/Signin')) },
			{ path: 'in/otp-request', Component: lazy(() => import('./Auth/SignInCodeRequest')) },
			{ path: 'in/otp-verify', Component: lazy(() => import('./Auth/SigninCodeVerification')) },
			// Waitlist
			{ path: 'waitlist', Component: lazy(() => import('./Auth/Waitlist')) },
			{ path: 'waitlist/comment', Component: lazy(() => import('./Auth/WaitlistComment')) },
			{ path: 'waitlist/success', Component: lazy(() => import('./Auth/WaitlistSuccess')) },
			{ path: '', element: <Navigate to="up" /> },
		],
	},
	{ path: '*', element: <RootRoute to="/auth/up" /> },
]

// Pass to matchRoutes for SSR to pre-fetch data
// or useRoutes for client side to render routes (don't do it manually - use Routes below)
export const onboardedUserRoutesConfig: RouteObject[] = [
	// Inbox
	{ path: 'inbox', index: true, Component: lazy(() => import('./Inbox')) },
	// Channel
	{
		Component: lazy(() => import('./Channel/WithChannel')),
		children: [
			{ path: 'editor/:channelName', index: true, Component: lazy(() => import('./Channel/Editor')) },
			{ path: 'overview/:channelName', Component: lazy(() => import('./Channel/Overview')) },
			{ path: 'settings/:channelName', Component: lazy(() => import('./Channel/Settings')) },
		],
	},
	// Billing
	{ path: 'billing/error', Component: lazy(() => import('./Billing/CheckoutError')) },
	{ path: 'billing/success', Component: lazy(() => import('./Billing/CheckoutSuccess')) },
	// ---
	{ path: '*', element: <RootRoute to="/inbox" /> },
]

export const notOnboardedUserRoutesConfig: RouteObject[] = [
	{
		path: 'onboard',
		Component: lazy(() => import('./Onboarding/WithOnboarding')),
		children: [
			{ path: 'channel', index: true, Component: lazy(() => import('./Onboarding/AddChannel')) },
			{ path: 'link', Component: lazy(() => import('./Onboarding/LinkBot')) },
			{ path: 'success', Component: lazy(() => import('./Onboarding/Success')) },
			{ path: '', element: <Navigate to="channel" /> },
		],
	},
	{ path: '*', element: <RootRoute to="/onboard" /> },
]

export const serverRoutesConfig = [
	...publicRoutesConfig,
	...authRoutesConfig,
	...onboardedUserRoutesConfig.slice(0, -1),
	...notOnboardedUserRoutesConfig.slice(0, -1),
	{ path: '*', element: <RootRoute to="/inbox" /> },
]
