import { useTranslation } from 'react-i18next'

import { useToast } from '@prostpost/toast'

export const useToasts = () => {
	const { t } = useTranslation('notify')

	const subscriptionRenewedSuccessfully = useToast({
		type: 'success',
		text: t('userSettings.plans.renewToastSuccess', 'Your subscription is successfully renewed!'),
	})

	const subscriptionRenewalFailed = useToast({
		type: 'error',
		text: t('userSettings.plans.renewToastFailed', 'Unable to renew subscription :( Please contact support.'),
	})

	const subscriptionIsUpgradedToAnnual = useToast({
		type: 'success',
		text: t('userSettings.plans.upgradeToAnnualSucceed', 'Your subscription is successfully upgraded!'),
	})

	const subscriptionFailedToUpgradeToAnnual = useToast({
		type: 'error',
		text: t(
			'userSettings.plans.upgradeToAnnualFailed',
			'Unable to upgrade your subscription :( Please contact support.',
		),
	})

	const subscriptionSwitchedToMonthly = useToast({
		type: 'success',
		text: t('userSettings.plans.upgradeToMonthlySuccess', 'Your subscription is successfully changed!'),
	})

	const subscriptionFailedToSwitchToMonthly = useToast({
		type: 'error',
		text: t(
			'userSettings.plans.upgradeToMonthlyFailed',
			'Unable to switch your subscription :( Please contact support.',
		),
	})

	return {
		subscriptionRenewedSuccessfully,
		subscriptionRenewalFailed,
		subscriptionIsUpgradedToAnnual,
		subscriptionFailedToUpgradeToAnnual,
		subscriptionSwitchedToMonthly,
		subscriptionFailedToSwitchToMonthly,
	}
}
