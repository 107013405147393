import Cookie from 'js-cookie'

export type Cookies = {
	billing_failed: 'true'
	billing_success: 'true'
	invalid_request: 'true'
	unsubscribed: 'true'
	access_token: string
}

const defaultValues: Record<keyof Cookies, Cookie.CookieAttributes> = {
	// Billing
	billing_success: { expires: 1 },
	billing_failed: { expires: 1 },
	// Emails
	unsubscribed: { expires: 1 },
	invalid_request: { expires: 1 },
	// Auth
	access_token: { expires: 1 },
}

const setCookie = <K extends keyof Cookies>(name: K, value: Cookies[K], attrs: Cookie.CookieAttributes = {}) => {
	Cookie.set(name, value, { ...defaultValues[name], ...attrs })
}

const removeCookie = (name: keyof Cookies) => {
	Cookie.remove(name)
}

const getCookie = <K extends keyof Cookies>(name: K): Cookies[K] => {
	return Cookie.get(name) as Cookies[K]
}

export const cookiesTyped = { getCookie, setCookie, removeCookie }
