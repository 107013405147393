import styled from '@emotion/styled'
import type { ComponentProps } from 'react'

import { Flex } from '@prostpost/uikit'

type Props = ComponentProps<typeof Flex>

const Container = styled(Flex)`
	overflow: hidden;
`

export const Page = ({ children, dir = 'column', ...props }: Props) => (
	<Container just="center" h="100dvh" w="100%">
		<Flex dir={dir} w="100%" h="100%" {...props}>
			{children}
		</Flex>
	</Container>
)
