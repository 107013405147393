import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { Heading, Text } from '@prostpost/uikit'

import { useCurrency } from 'app/domains/PricingPlan/hooks'

import { ContainerStyled } from './styled'
import { FloatingButton } from './FloatingButton'

const StyledText = styled(Text)`
	color: #3f8526;
`

const StyledHeading = styled(Heading)`
	color: #3f8526;
`

type Props = {
	price: number
	isDisabled?: boolean
	period: 'mo' | 'yr'
	onClick: () => void
}

export const Buy = ({ price, period, onClick }: Props) => {
	const { t } = useTranslation()
	const { sign: currencySign } = useCurrency('EUR')
	return (
		<ContainerStyled
			py={3}
			px={4}
			space={1}
			customBg="#f9fff7"
			borderColor="green_100"
			borderWidth={1}
			maxW="130px"
			borderRadius="small"
			cursor="pointer"
			position="relative"
			style={{ borderStyle: 'dashed' }}
			isPending={false}
			isDisabled={false}
			onClick={onClick}
		>
			<StyledHeading h={5}>{t('userSettings.usage.slot.buyTitle', 'Buy')}</StyledHeading>
			<StyledText size={12}>
				{t('userSettings.usage.slot.buyDesc', `for {{currencySign}}{{price}}.00 /{{period}}.`, {
					currencySign,
					period,
					price,
				})}
			</StyledText>
			<FloatingButton variant="normal" icon={Icons.Euro} onClick={onClick} />
		</ContainerStyled>
	)
}
