import { useTheme } from '@emotion/react'
import type { LucideIcon } from 'lucide-react'

import { FloatingButtonStyled } from './styled'

type Props = {
	icon: LucideIcon
	variant: 'danger' | 'normal'
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const FloatingButton = ({ icon: Icon, variant = 'normal', ...props }: Props) => {
	const theme = useTheme()
	const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation()
		props.onClick?.(e)
	}

	return (
		<FloatingButtonStyled
			as="button"
			top="-8px"
			right="-8px"
			position="absolute"
			bg={variant === 'danger' ? 'red_100' : 'white_100'}
			borderRadius="big"
			w="24px"
			h="24px"
			just="center"
			align="center"
			shadow="mid"
			data-label="floating-button"
			// @ts-expect-error Flex has div as default tag but here we use button
			onClick={onClick}
		>
			<Icon
				size={14}
				strokeWidth={2.5}
				color={variant === 'danger' ? theme.colors.white_100 : theme.colors.blue_40}
			/>
		</FloatingButtonStyled>
	)
}
