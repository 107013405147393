import { useTranslation } from 'react-i18next'

import { notReachable } from '@prostpost/utils'

import { useActiveChannels } from 'app/domains/Channel/api'
import type { ChannelActive } from 'app/domains/Channel'

export const useActiveChannelsLoader = () => {
	const { t } = useTranslation()
	const { activeChannelsQuery } = useActiveChannels()

	switch (activeChannelsQuery.status) {
		case 'idle':
		case 'loading':
			return undefined

		case 'error':
			switch (activeChannelsQuery.error.code) {
				case 'UNKNOWN_ERROR':
				case 'NOT_VALID_ERROR':
				default:
					throw {
						...activeChannelsQuery.error,
						title: t('notify:channels.error.loadingFailed', 'Unable to get channels'),
					}
			}

		case 'success':
			return activeChannelsQuery.data.filter((ch): ch is ChannelActive => ch.type === 'ACTIVE')

		default:
			return notReachable(activeChannelsQuery)
	}
}
