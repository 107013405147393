import { useLocation } from 'react-router-dom'

import { trackEvent } from '../track'

type SignUpMethod = 'email' | 'google'
type SignInMethod = 'email' | 'google' | 'otp'
type SignEmailFields = 'email' | 'password'

export const useAuthSignInAnalyticEvents = () => {
	const ref = useLocation().pathname
	const trackClick = (e: string, params?: UmamiData) => trackEvent('auth', ref, 'click', e, params)
	const trackError = (e: string, params?: UmamiData) => trackEvent('auth', ref, 'error', e, params)
	return {
		clicks: {
			// Nav
			toSignin: () => trackClick('to_signin'),
			// Fields
			showPassword: () => trackClick('show_password'),
			hidePassword: () => trackClick('hide_password'),
			// Submit
			createAccount: () => trackClick('create_account'),
			signIn: (method: SignInMethod) => trackClick('sign_in', { method }),
			// Forgot password
			forgotPassword: () => trackClick('forgot_password'),
			forgotPasswordGotIt: () => trackClick('forgot_password_got_it'),
			forgotPasswordClosed: () => trackClick('forgot_password_closed'),
			// OTP
			verifyOTPCode: () => trackClick('verify_otp_code'),
			resendOTPCode: () => trackClick('resend_otp_code'),
			requestOTPCode: () => trackClick('request_otp_code'),
		},
		errors: {
			invalidField: (field: SignEmailFields) => trackError('invalid_input', { method: 'email', field }),
		},
	}
}

export const useAuthSignUpAnalyticEvents = () => {
	const ref = useLocation().pathname
	const trackClick = (e: string, params?: UmamiData) => trackEvent('auth', ref, 'click', e, params)
	const trackError = (e: string, params?: UmamiData) => trackEvent('auth', ref, 'error', e, params)
	return {
		clicks: {
			// Nav
			toSignup: () => trackClick('to_signup'),
			// Fields
			showPassword: () => trackClick('show_password'),
			hidePassword: () => trackClick('hide_password'),
			// Terms and conditions
			termsAcceptedCheckbox: () => trackClick('terms_accepted_checkbox'),
			termsAndPrivacyPolicy: () => trackClick('terms_and_privacy_policy'),
			// Submit
			signUp: (method: SignUpMethod) => trackClick('sign_up', { method }),
			signInWithExistingAccount: () => trackClick('sign_in_with_existing_account'),
		},
		errors: {
			invalidField: (field: SignEmailFields) => trackError('invalid_input', { method: 'email', field }),
		},
	}
}

export const useWaitlistEvents = () => {
	const ref = useLocation().pathname
	const trackClick = (e: string, params?: UmamiData) => trackEvent('waitlist', ref, 'click', e, params)
	return {
		clicks: {
			// Terms and conditions
			termsAcceptedCheckbox: () => trackClick('terms_accepted_checkbox'),
			termsAndPrivacyPolicy: () => trackClick('terms_and_privacy_policy'),
			// Navigation
			createAccount: () => trackClick('create_account'),
			backToSignUp: () => trackClick('back_to_sign_up'),
			// Submit
			join: () => trackClick('join'),
			comment: () => trackClick('comment'),
		},
	}
}
