import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useQueryClient } from 'react-query'
import camelcaseKeys from 'camelcase-keys'
import useWebSocket from 'react-use-websocket'
import type { PropsWithChildren } from 'react'

import { getWebsocketUrl } from 'app/config/websockets'

import { usePostsStore } from 'app/domains/Post/store'
import { useChannelsStore } from 'app/domains/Channel/store'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { PostHistorySchema } from 'app/domains/Post/api/schemas'
import { removeNullValues } from 'app/shared/utils'

import type { WSMessage } from './messages'

export const WithWebsockets = observer(({ children }: PropsWithChildren) => {
	const posts = usePostsStore()
	const channels = useChannelsStore()
	const user = useOnboardedUserStore()

	const queryClient = useQueryClient()

	const { lastJsonMessage } = useWebSocket<WSMessage>(getWebsocketUrl(user.uuid), {
		onOpen: () => '[WS] Connection open',
		onClose: () => '[WS] Connection closed',
	})

	useEffect(() => {
		if (!lastJsonMessage) return
		console.log(`[WS] ${lastJsonMessage.msgCode}`, camelcaseKeys(lastJsonMessage))

		switch (lastJsonMessage.msgCode) {
			case 'CHANNEL_TITLE_UPDATE_SUCCEED':
			case 'CHANNEL_AVA_UPDATE_SUCCEED': {
				const { channelName, ...data } = camelcaseKeys(lastJsonMessage.data)
				channels.updateChannel(channelName, data)
				break
			}

			case 'SCHEDULED_POST_PUBLISHED_SUCCEED': {
				const { channelName, ...data } = camelcaseKeys(lastJsonMessage.data)
				posts.updatePublishedPost(data.draftUuid, { publishedAt: data.publishedAt })
				break
			}

			case 'POST_PUBLISHED_VIA_TELEGRAM':
				if (!channels.current) return
				try {
					const newHistoryPost = PostHistorySchema.parse(
						camelcaseKeys(removeNullValues(lastJsonMessage.data)),
					)
					posts.addPublishedPost(newHistoryPost)
					break
				} catch (e) {
					console.error('[WS] Unable to parse new post from Telegram', e)
					break
				}

			case 'POST_EDITED_FROM_TELEGRAM_NOT_FOUND':
				console.log('TODO: Post edited via Telegram: ', lastJsonMessage.data)
				break

			case 'CHANNEL_POSTS_FETCHED_FROM_TELEGRAM': {
				const { channelName } = camelcaseKeys(lastJsonMessage.data)
				queryClient.invalidateQueries(['feed', channelName])
				break
			}

			default:
				console.warn('[WS] Received unknown message: ', lastJsonMessage)
		}
	}, [lastJsonMessage])

	return children
})
