import React from 'react'
import styled from '@emotion/styled'
import type { ReactNode, PropsWithChildren } from 'react'

import { getSpace } from '@prostpost/css'
import { VStack, Flex, HStack, Text, Box, Skeleton } from '@prostpost/uikit'

const Grid = styled(Box)<{ cols: number; children: ReactNode }>`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(${({ cols }) => cols}, 1fr);
	grid-template-rows: repeat(${({ cols, children }) => React.Children.count(children) / cols}, 1fr);
	grid-gap: ${getSpace(3)} ${getSpace(2)};
`

const getFlexAlign = (align: 'left' | 'right') => (align === 'left' ? 'flex-start' : 'flex-end')

const ColumnHeading = ({ title, align = 'right' }: { title: string; align?: 'left' | 'right' }) => (
	<Flex align="center" just={getFlexAlign(align)}>
		<Text size={14} weight={400} color="blue_40">
			{title}
		</Text>
	</Flex>
)

const ColumnCellContainer = ({
	layout = 'horizontal',
	align = 'right',
	children,
}: {
	align?: 'left' | 'right'
	layout?: 'vertical' | 'horizontal'
	children: ReactNode[]
}) =>
	layout === 'vertical' ? (
		<VStack space={0} just={getFlexAlign(align)}>
			{children}
		</VStack>
	) : (
		<HStack space={1} just={getFlexAlign(align)} align="center">
			{children}
		</HStack>
	)

const ColumnCell = ({
	value,
	details,
	layout = 'horizontal',
	align,
}: {
	value: string
	details?: string
	align?: 'left' | 'right'
	layout?: 'vertical' | 'horizontal'
}) => (
	<ColumnCellContainer layout={layout} align={align}>
		<Text size={14} color="black_80">
			{value}
		</Text>
		{details && (
			<Text size={12} color="blue_40">
				{details}
			</Text>
		)}
	</ColumnCellContainer>
)

const RowSkeleton = () => (
	<>
		<Flex align="center">
			<Skeleton width="100%" borderRadius="sm" height={28} />
		</Flex>
		<Flex align="center" just="flex-end">
			<Skeleton width="80%" borderRadius="sm" height={28} />
		</Flex>
		<Flex align="center" just="flex-end">
			<Skeleton width="80%" borderRadius="sm" height={28} />
		</Flex>
		<Flex align="center" just="flex-end">
			<Skeleton width="80%" borderRadius="sm" height={28} />
		</Flex>
	</>
)

const Row = ({ children }: PropsWithChildren) => <React.Fragment>{children}</React.Fragment>

export const SimpleGrid = Object.assign(Grid, {
	Row,
	RowSkeleton,
	Td: ColumnCell,
	Th: ColumnHeading,
})
