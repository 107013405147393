import i18next from 'i18next'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeProvider } from '@emotion/react'
import { QueryClientProvider } from 'react-query'
import { HelmetProvider } from 'react-helmet-async'
import type { i18n as I18n } from 'i18next'

import { theme } from '@prostpost/uikit'
import { canUseDOM } from '@prostpost/utils'
import { themeLight, ThemeProvider as ThemeProviderNext } from '@prostpost/uikit-next'

import { config } from 'app/config'

import { ErrorBoundary } from 'app/domains/Error/components'
import { useIsUserSignedIn } from 'app/domains/User/hooks'

import { routesUrls } from 'app/routes/urls'

import { Loadings } from './routes/Loadings'
import { RoutesDataManager, AuthRoutesDataManager } from './routes'
import { WithUserChecker } from './routes/Wrappers'

const helmetContext = {}

export const App = ({ i18n = i18next }: { i18n?: I18n }) => {
	const { t } = useTranslation()

	const isUserSignedIn = useIsUserSignedIn()
	const isLoadingRoute = isUserSignedIn === null

	return (
		<QueryClientProvider client={config.query.client}>
			<ThemeProvider theme={theme}>
				<ThemeProviderNext theme={themeLight}>
					<HelmetProvider context={helmetContext}>
						<ErrorBoundary
							actionTitle={t('errorBoundary.poster.actionHome', 'Home page')}
							onClickAction={() => canUseDOM(() => window.location.assign(routesUrls.inbox))}
						>
							<Suspense fallback={<Loadings.Spinner />}>
								{isLoadingRoute ? (
									<Loadings.Spinner />
								) : !isUserSignedIn ? (
									<AuthRoutesDataManager />
								) : (
									<WithUserChecker>
										<RoutesDataManager i18n={i18n} />
									</WithUserChecker>
								)}
							</Suspense>
						</ErrorBoundary>
					</HelmetProvider>
				</ThemeProviderNext>
			</ThemeProvider>
		</QueryClientProvider>
	)
}
