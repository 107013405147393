import { useAnalytics } from '.'

export type TrackingAction = 'click' | 'error' | 'input'
export type TrackingGroup = Record<string, string>

export type TrackEventFn<R extends string> = (
	cat: string,
	ref: R,
	action: TrackingAction,
	eventName: string,
	params?: UmamiData,
	options?: UmamiOptions,
) => void

export type TrackEventGroupedFn<R extends string> = (
	cat: string,
	ref: R,
	group: TrackingGroup,
	action: TrackingAction,
	eventName: string,
	params?: UmamiData,
	options?: UmamiOptions,
) => void

export const trackEvent = <R extends string>(
	cat: string,
	ref: R,
	action: TrackingAction,
	eventName: string,
	params?: UmamiData,
	options?: UmamiOptions,
) => {
	const { trackEvent } = useAnalytics()
	trackEvent(`${cat}_${action}_${eventName}`, { ref, ...params }, options)
}

export const trackEventGrouped = <R extends string>(
	cat: string,
	ref: R,
	group: TrackingGroup,
	action: TrackingAction,
	eventName: string,
	params?: UmamiData,
	options?: UmamiOptions,
) => {
	const { trackEvent } = useAnalytics()
	trackEvent(
		`${cat}_${action}_${eventName}`,
		{ ref, ...group, ...params },
		{ ...options, groupId: Object.values(group)[0] },
	)
}
