import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { getNow } from '@prostpost/utils'

import { UserSettings } from 'app/modals/UserSettings'
import { usePlansAnalyticsEvents } from 'app/config/analytics/events/plans'
import { useSubscriptionExpired } from 'app/domains/PricingPlan/hooks'
import { useLimitsResetDate } from 'app/domains/UserPreferences/hooks'

import { TopSticker } from '../TopSticker'

type Props = { onDismiss: () => void }

export const TrialSticker = ({ onDismiss }: Props) => {
	const { t } = useTranslation()
	const planEvents = usePlansAnalyticsEvents()

	const isExpired = useSubscriptionExpired()
	const limitsResetDate = useLimitsResetDate()
	const trialDaysLeft = limitsResetDate.diff(getNow(false, { to: 'local' }), 'days') + 1

	const [isUserSettingsModalOpen, setIsUserSettingsModalOpen] = useState(false)

	const trialNotificationMessage = (() => {
		if (isExpired) {
			return t(
				'content:sticker.trial.titleExpired',
				'Your trial period is expired. Upgrade plan to continue using the app.',
			)
		}

		if (trialDaysLeft === 1) {
			return t('content:sticker.trial.titleTomorrow$2', 'Your trial period ends tomorrow')
		} else if (trialDaysLeft === 0) {
			return t('content:sticker.trial.titleToday$2', 'Your trial period ends today')
		} else {
			return t('content:sticker.trial.title$2', 'Your trial period ends in {{count}} days', {
				count: trialDaysLeft,
			})
		}
	})()

	return (
		<>
			<TopSticker
				isDismissible
				icon={Icons.Gift}
				message={trialNotificationMessage}
				action={{
					isVisible: !isExpired,
					label: t('content:sticker.trial.action', 'Upgrade now'),
					onClick: () => {
						planEvents.clicks.trialBannerUpgradeNow()
						setIsUserSettingsModalOpen(true)
					},
				}}
				onDismiss={() => {
					planEvents.clicks.trialBannerClosed()
					onDismiss()
				}}
			/>

			<UserSettings
				currentTab="plan"
				isOpen={isUserSettingsModalOpen}
				onClose={() => setIsUserSettingsModalOpen(false)}
			/>
		</>
	)
}
