import { useTranslation } from 'react-i18next'

import { Modal } from '@prostpost/uikit'

import { useSetBotLink, useBotLinkForm } from 'app/domains/Bot/features/LinkBotToUser/hooks'

import { useCommonLocalizations } from 'app/shared/hooks'

import { ReactModal } from 'app/modals'
import { useBotAnalyticsEvents } from 'app/config/analytics/events/bot'

import { LinkBotToUserForm, LinkBotToUserHeader, LinkBotToUserDescription } from './components'
import type { Msg as MsgComponents } from './components'

export type Msg =
	| MsgComponents
	| {
			type: 'on_bot_link_modal_close'
	  }

export type Interface = {
	isOpen: boolean
}

type MsgProp = {
	onMsg: (msg: Msg) => void
}

export const LinkBotToUserModal = ({ isOpen, onMsg }: Interface & MsgProp) => {
	const { t } = useTranslation()
	const { actionCancel } = useCommonLocalizations(t)
	const botEvents = useBotAnalyticsEvents()

	const { botLinkStatus, setBotLink } = useSetBotLink()
	const form = useBotLinkForm({ onSubmit: setBotLink })

	return (
		<ReactModal
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
			isOpen={isOpen}
			onRequestClose={e => {
				e.stopPropagation()
				e.preventDefault()
				onMsg({ type: 'on_bot_link_modal_close' })
			}}
		>
			<Modal variant="confirm">
				<Modal.Close
					onClick={() => {
						botEvents.clicks.reconnectCancel()
						onMsg({ type: 'on_bot_is_linked_to_user' })
					}}
				/>
				<Modal.Content>
					<LinkBotToUserHeader />
					<LinkBotToUserDescription />
					<LinkBotToUserForm form={form} botLinkStatus={botLinkStatus} onMsg={onMsg} />
				</Modal.Content>
				<Modal.ConfirmSecondaryAction
					onClick={() => {
						botEvents.clicks.reconnectCancel()
						onMsg({ type: 'on_bot_is_linked_to_user' })
					}}
				>
					{actionCancel}
				</Modal.ConfirmSecondaryAction>
				<Modal.ConfirmPrimaryAction
					state={botLinkStatus.type === 'loading' ? 'pending' : 'normal'}
					onClick={() => {
						botEvents.clicks.reconnectSubmit()
						form.onSubmit()
					}}
				>
					{t('content:bot.linkBotToUser.modal.action', 'Connect account')}
				</Modal.ConfirmPrimaryAction>
			</Modal>
		</ReactModal>
	)
}
