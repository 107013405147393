import { useLocation } from 'react-router-dom'

import { trackEvent } from '../track'

export const useOtherAnalyticsEvents = () => {
	const ref = useLocation().pathname
	const trackClick = (e: string, params?: UmamiData) => trackEvent('other', ref, 'click', e, params)
	return {
		clicks: {
			logo: () => trackClick('logo'),
			logoLanding: () => trackClick('logo_landing'),
			sidebar: (state: 'expand' | 'collapse') => trackClick('sidebar', { state }),
			feed: (action: 'expand' | 'collapse') => trackClick('feed', { action }),
		},
	}
}
