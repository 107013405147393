import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { datetime, truncate } from '@prostpost/utils'
import { Heading, Text } from '@prostpost/uikit'

import type { ChannelActive } from 'app/domains/Channel'

import { ContainerStyled } from './styled'

type Props = {
	ch: ChannelActive
}

export const Busy = observer(({ ch }: Props) => {
	const { t } = useTranslation()
	return (
		<ContainerStyled
			key={ch.name}
			py={3}
			px={4}
			space={1}
			borderWidth={2}
			borderColor="yellow_100"
			borderRadius="small"
			position="relative"
			isDisabled={false}
			isPending={false}
		>
			<Heading h={5} color="black_80">
				{truncate(ch.alias, 16)}
			</Heading>
			<Text size={12} color="blue_40">
				{t('userSettings.usage.slot.addedOn', 'Added {{date}}', {
					date: datetime(ch.createdAt, true, { to: 'local', time: 'trim', year: 'yes' }),
				})}
			</Text>
		</ContainerStyled>
	)
})
