import { runInAction } from 'mobx'
import { useTheme } from '@emotion/react'
import { observer } from 'mobx-react-lite'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { Button, Tooltip } from '@prostpost/uikit'
import { notReachable } from '@prostpost/utils'

import { usePricingPlans } from 'app/domains/PricingPlan/api'
import { useStripeCheckout } from 'app/domains/PricingPlan/hooks'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { usePlansAnalyticsEvents } from 'app/config/analytics/events/plans'
import { useStripeSubscriptionModify } from 'app/domains/PricingPlan/api'
import { ModalSwitchAnnualWarning } from 'app/domains/PricingPlan/features/ModalSwitchToAnnual'
import type { PricingPlanAnnual, PricingPlanMonth } from 'app/domains/PricingPlan'

import { useSubscriptionStatus, useToasts, useSubPeriodFromPlan } from './hooks'

type Action = {
	plan: 'annual' | 'monthly'
	action: 'renew' | 'upgrade'
}

type Msg = { type: 'on_show_warning_modal' } | ({ type: 'on_click_upgrade' } & Action)

type ActionProps = {
	isModifying: boolean
	planToActivate: PricingPlanAnnual | PricingPlanMonth | PricingPlanAnnual['name'] | PricingPlanMonth['name']
	onMsg: (msg: Msg) => void
}

const SubscribeActionButton = observer(({ isModifying, planToActivate, onMsg }: ActionProps) => {
	const { t } = useTranslation()
	const { preferences } = useOnboardedUserStore()

	const theme = useTheme()
	const planEvents = usePlansAnalyticsEvents()
	const subscriptionStatus = useSubscriptionStatus()
	const subscriptionPeriodToActivate = useSubPeriodFromPlan(planToActivate)

	const { stripeSessionIsLoading, createStripeCheckoutSession } = useStripeCheckout()

	const buttonState = isModifying || stripeSessionIsLoading ? 'pending' : 'normal'

	switch (subscriptionStatus) {
		case 'TRIAL':
		case 'EXPIRED':
			return (
				<Button.Action
					size="mid"
					variant="primary"
					state={buttonState}
					icon={<Icons.CircleFadingArrowUp size={14} strokeWidth={2.5} color={theme.colors.black_80} />}
					onClick={() => {
						planEvents.clicks.renewSubscription()
						createStripeCheckoutSession({
							variant: subscriptionPeriodToActivate,
							extraChannels:
								preferences.limits.channels -
								preferences.plan.limits.channels -
								preferences.channelSlotsToReclaim,
						})
					}}
				>
					{t('userSettings.plans.createNewSubscription', 'Upgrade')}
				</Button.Action>
			)

		case 'ACTIVE_MONTHLY':
			if (subscriptionPeriodToActivate === 'monthly') {
				return (
					<Button.Action size="mid" variant="secondary" state="disabled">
						{t('userSettings.plans.currentLabel', 'Current')}
					</Button.Action>
				)
			}
			return (
				<Button.Action
					size="mid"
					variant="primary"
					state={buttonState}
					icon={<Icons.CircleFadingArrowUp size={14} strokeWidth={2.5} color={theme.colors.black_80} />}
					onClick={() => onMsg({ type: 'on_show_warning_modal' })}
				>
					{t('userSettings.plans.upgradeAction', 'Upgrade')}
				</Button.Action>
			)

		case 'ACTIVE_ANNUAL':
			if (subscriptionPeriodToActivate === 'annual') {
				return (
					<Button.Action size="mid" variant="secondary" state="disabled">
						{t('userSettings.plans.currentLabel', 'Current')}
					</Button.Action>
				)
			}
			return (
				<Tooltip
					maxW={200}
					placement="top"
					content={t(
						'userSettings.plans.downgradeLabel',
						'You currently are on an annual plan. To switch to monthly cancel your subscription manually first using "Billing" button above.',
					)}
				>
					<Button.Action
						size="mid"
						variant="secondary"
						state="disabled"
						icon={<Icons.Info size={14} strokeWidth={2.5} color={theme.colors.black_80} />}
					>
						{t('userSettings.plans.downgradeLabel', 'Switch')}
					</Button.Action>
				</Tooltip>
			)

		case 'RENEWABLE_ANNUAL':
			if (subscriptionPeriodToActivate === 'annual') {
				return (
					<Button.Action
						size="mid"
						variant="primary"
						state={buttonState}
						icon={<Icons.RefreshCw size={14} strokeWidth={2.5} color={theme.colors.black_80} />}
						onClick={() => {
							planEvents.clicks.renewSubscription()
							onMsg({
								type: 'on_click_upgrade',
								action: 'renew',
								plan: 'annual',
							})
						}}
					>
						{t('userSettings.plans.renewSubscription', 'Renew')}
					</Button.Action>
				)
			}
			return (
				<Button.Action
					size="mid"
					variant="primary"
					state={buttonState}
					icon={<Icons.CircleFadingArrowUp size={14} strokeWidth={2.5} color={theme.colors.black_80} />}
					onClick={() => {
						planEvents.clicks.renewSubscription()
						onMsg({
							type: 'on_click_upgrade',
							action: 'upgrade',
							plan: 'monthly',
						})
					}}
				>
					{t('userSettings.plans.upgradeSubscription', 'Upgrade')}
				</Button.Action>
			)

		case 'RENEWABLE_MONTHLY':
			if (subscriptionPeriodToActivate === 'monthly') {
				return (
					<Button.Action
						size="mid"
						variant="primary"
						state={buttonState}
						icon={<Icons.RefreshCw size={14} strokeWidth={2.5} color={theme.colors.black_80} />}
						onClick={() => {
							planEvents.clicks.renewSubscription()
							onMsg({
								type: 'on_click_upgrade',
								action: 'renew',
								plan: 'monthly',
							})
						}}
					>
						{t('userSettings.plans.renewSubscription', 'Renew')}
					</Button.Action>
				)
			}
			return (
				<Button.Action
					size="mid"
					variant="primary"
					state={buttonState}
					icon={<Icons.CircleFadingArrowUp size={14} strokeWidth={2.5} color={theme.colors.black_80} />}
					onClick={() => {
						planEvents.clicks.renewSubscription()
						onMsg({
							type: 'on_click_upgrade',
							action: 'upgrade',
							plan: 'monthly',
						})
					}}
				>
					{t('userSettings.plans.upgradeSubscription', 'Upgrade')}
				</Button.Action>
			)
		default:
			return null
	}
})

type Props = {
	planToActivate: PricingPlanAnnual | PricingPlanMonth | PricingPlanAnnual['name'] | PricingPlanMonth['name']
}

export const SubscribeAction = observer(({ planToActivate }: Props) => {
	const toasts = useToasts()
	const subscriptionPeriodToActivate = useSubPeriodFromPlan(planToActivate)

	const { pricingPlansQuery } = usePricingPlans()
	const { preferences } = useOnboardedUserStore()
	const { modifyStripeSubscriptionMutation } = useStripeSubscriptionModify()

	const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)
	const [action, setAction] = useState<Action | undefined>(undefined)

	const planToActivateName = typeof planToActivate === 'string' ? planToActivate : planToActivate.name

	useEffect(() => {
		switch (modifyStripeSubscriptionMutation.status) {
			case 'idle':
			case 'loading':
				break
			case 'error':
				if (!action) return
				switch (action.action) {
					case 'renew':
						toasts.subscriptionRenewalFailed.show()
						setAction(undefined)
						break
					case 'upgrade':
						if (action.plan === 'annual') toasts.subscriptionFailedToUpgradeToAnnual.show()
						else toasts.subscriptionFailedToSwitchToMonthly.show()
						setAction(undefined)
						break
					default:
						notReachable(action.action)
				}
				break
			case 'success':
				setIsWarningModalOpen(false)
				runInAction(() => {
					if (!pricingPlansQuery.data) return
					preferences.setPreferences({
						...preferences,
						isSubscriptionCanceled: false,
						plan: pricingPlansQuery.data[planToActivateName],
						limits: {
							...preferences.limits,
							posts: pricingPlansQuery.data[planToActivateName].limits.posts,
							channels:
								pricingPlansQuery.data[planToActivateName].limits.channels +
								// extra channels are already added to the main subscription
								(preferences.limits.channels - pricingPlansQuery.data.MONTH.limits.channels),
						},
					})
				})

				if (!action) return
				switch (action.action) {
					case 'renew':
						toasts.subscriptionRenewedSuccessfully.show()
						setAction(undefined)
						break
					case 'upgrade':
						if (action.plan === 'annual') toasts.subscriptionIsUpgradedToAnnual.show()
						else toasts.subscriptionSwitchedToMonthly.show()
						setAction(undefined)
						break
					default:
						notReachable(action.action)
				}
				break
			default:
				notReachable(modifyStripeSubscriptionMutation)
		}
	}, [modifyStripeSubscriptionMutation.status])

	return (
		<>
			<SubscribeActionButton
				planToActivate={planToActivate}
				isModifying={modifyStripeSubscriptionMutation.isLoading}
				onMsg={msg => {
					switch (msg.type) {
						case 'on_show_warning_modal':
							setIsWarningModalOpen(true)
							break
						case 'on_click_upgrade':
							setAction(msg)
							modifyStripeSubscriptionMutation.mutate({
								variant: subscriptionPeriodToActivate,
								extraChannels:
									preferences.limits.channels -
									preferences.plan.limits.channels -
									preferences.channelSlotsToReclaim,
							})
							break
						default:
							notReachable(msg)
					}
				}}
			/>

			<ModalSwitchAnnualWarning
				isOpen={isWarningModalOpen}
				currentPlanValidUntil={preferences.nextSubscriptionPeriod}
				onClose={() => setIsWarningModalOpen(false)}
				onConfirm={() => {
					setAction({ plan: 'annual', action: 'upgrade' })
					modifyStripeSubscriptionMutation.mutate({
						variant: 'annual',
						extraChannels: preferences.limits.channels - preferences.plan.limits.channels,
					})
				}}
			/>
		</>
	)
})
