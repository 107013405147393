import { useState } from 'react'
import { runInAction } from 'mobx'
import { Observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { UserCog2 } from 'lucide-react'
import * as LucideIcons from 'lucide-react'

import { useToast } from '@prostpost/toast'
import { useCurrentMedia } from '@prostpost/css'
import { Sidebar, Flex } from '@prostpost/uikit'
import { notReachable } from '@prostpost/utils'

import { config } from 'app/config'

import { useSignout } from 'app/domains/User/hooks'
import { MEDIA_URL } from 'app/domains/Media/constants'
import { useChannelsStore } from 'app/domains/Channel/store'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { useUpdateUserPreferences } from 'app/domains/UserPreferences/hooks'
import { AddChannel } from 'app/domains/Channel/features/AddChannel'
import { useNewChannelAnalyticsEvents, useChannelAnalyticsEvents } from 'app/config/analytics/events/channel'
import { useOtherAnalyticsEvents } from 'app/config/analytics/events/other'
import { useFeedbackAnalyticsEvents } from 'app/config/analytics/events/feedback'
import { useInboxAnalyticsEvents } from 'app/config/analytics/events/inbox'
import { useUserAnalyticsEvents } from 'app/config/analytics/events/user'

import { UserSettings } from 'app/modals/UserSettings'
import { UserFeedback } from 'app/modals/UserFeedback'

import { ReactLazyPreload, ProstpostLogo } from 'app/shared/components'
import { routesUrls, matchChannelRoute, useMatchRoute } from 'app/routes/urls'

type ModalTabs = 'account' | 'plan' | 'preferences'

type Props = { children: React.ReactElement }

const Inbox = ReactLazyPreload(() => import('../../Inbox'))
const ChannelEditor = ReactLazyPreload(() => import('../../Channel/Editor'))
const ChannelSettings = ReactLazyPreload(() => import('../../Channel/Settings'))
const ChannelOverview = ReactLazyPreload(() => import('../../Channel/Overview'))

export const WithSidebar = (props: Props) => {
	const MEDIA = useCurrentMedia()
	const navigate = useNavigate()
	const { pathname } = useLocation()

	const userEvents = useUserAnalyticsEvents()
	const inboxEvents = useInboxAnalyticsEvents()
	const otherEvents = useOtherAnalyticsEvents()
	const feedbackEvents = useFeedbackAnalyticsEvents()
	const channelEvents = useNewChannelAnalyticsEvents()
	const existingChannelEvents = useChannelAnalyticsEvents()

	const { t } = useTranslation()

	const channels = useChannelsStore()
	const user = useOnboardedUserStore()

	const { signOut } = useSignout({ redirectTo: routesUrls.auth.signin })
	const { mutate: mutatePreferences } = useUpdateUserPreferences()

	const isInboxRoute = useMatchRoute('inbox')
	const [openModalTab, setOpenModalTab] = useState<ModalTabs | undefined>()

	const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
	const [isAddChannelModalOpen, setIsAddChannelModalOpen] = useState(false)
	const addChannelModalToast = useToast({
		type: 'success',
		text: t('sidebar.context.addChannel.success', 'Channel was successfully added'),
	})

	const onLogoClick = () => {
		otherEvents.clicks.logo()
		if (isInboxRoute) return
		navigate(`${config.constants.DOMAIN}${routesUrls.inbox}`)
	}

	if (!MEDIA) return null

	return (
		<Flex position="relative" w="100%" h="100dvh" style={{ overflow: 'hidden' }}>
			<Observer>
				{() => {
					switch (MEDIA) {
						case 'MOBILE':
							return props.children

						case 'TABLET':
						case 'DESKTOP_WIDE':
						case 'DESKTOP_NARROW':
							return (
								<>
									<Sidebar
										isOpen={user.preferences.more.sidebarIsOpen}
										logo={{
											Short: <ProstpostLogo iconOnly variant="light" onClick={onLogoClick} />,
											Full: (
												<ProstpostLogo iconOnly={false} variant="light" onClick={onLogoClick} />
											),
										}}
										renderBottom={({ isOpen, onToggle }) => (
											<Sidebar.Content>
												<Sidebar.NavItem
													variant="iconed"
													isActive={false}
													title={t('sidebar.context.settings2', 'Account')}
													icon={UserCog2}
													onClick={() => {
														userEvents.clicks.accountModal()
														setOpenModalTab('account')
													}}
												/>
												<Sidebar.NavItem
													variant="iconed"
													isActive={false}
													title={t('sidebar.context.logout', 'Logout')}
													icon={LucideIcons.LogOut}
													onClick={() => {
														userEvents.clicks.logout('sidebar')
														signOut()
													}}
												/>
												<Sidebar.Divider />
												<Sidebar.NavItem
													variant="iconed"
													isActive={false}
													title={t('sidebar.context.feedback', 'Support')}
													icon={LucideIcons.MessageCircle}
													onClick={() => {
														feedbackEvents.clicks.open()
														setIsFeedbackModalOpen(true)
													}}
												/>
												<Sidebar.NavItem
													variant="iconed"
													isActive={false}
													title={t('sidebar.context.collapse', 'Collapse')}
													tooltip={t('sidebar.context.expand', 'Expand')}
													icon={isOpen ? LucideIcons.ChevronLeft : LucideIcons.ChevronRight}
													onClick={() => {
														otherEvents.clicks.sidebar(isOpen ? 'collapse' : 'expand')
														onToggle()
														mutatePreferences({ more: { sidebarIsOpen: !isOpen } })
													}}
												/>
											</Sidebar.Content>
										)}
									>
										<Sidebar.Content>
											<Sidebar.NavItem
												variant="iconed"
												isActive={isInboxRoute}
												title={t('sidebar.context.inbox', 'Inbox')}
												icon={LucideIcons.Inbox}
												onMouseOver={Inbox.preload}
												onClick={() => {
													inboxEvents.clicks.inbox()
													navigate(routesUrls.inbox)
												}}
											/>
										</Sidebar.Content>
										<Sidebar.Divider />
										<Sidebar.Content mt={5}>
											<Sidebar.NavItem
												variant="iconed"
												isActive={false}
												title={t('sidebar.context.addChannel', 'Add channel')}
												icon={LucideIcons.PlusCircle}
												onClick={() => {
													channelEvents.clicks.add()
													setIsAddChannelModalOpen(true)
												}}
											/>
											{channels.activeList.map(channel => {
												const ava = channel.avaSmall || channel.avaBig
												const isSelectedChannel = matchChannelRoute('any', pathname, channel)
												const editorRoute = routesUrls.channel.getEditorRoute(channel)
												const settingsRoute = routesUrls.channel.getSettingsRoute(channel)
												const overviewRoute = routesUrls.channel.getOverviewRoute(channel)
												return (
													<Sidebar.NavItem
														variant="ava"
														key={channel.name}
														isActive={isSelectedChannel}
														isNested={isSelectedChannel}
														title={channel.title || channel.name}
														ava={ava ? MEDIA_URL(ava) : ''}
														onClick={() => {
															existingChannelEvents.clicks.selectChannel()
															runInAction(() => {
																navigate(editorRoute)
															})
														}}
													>
														<Sidebar.NavSubItem
															title="Editor"
															icon={LucideIcons.File}
															isActive={pathname === editorRoute}
															onMouseOver={ChannelEditor.preload}
															onClick={() => {
																existingChannelEvents.clicks.editor()
																runInAction(() => navigate(editorRoute))
															}}
														/>
														<Sidebar.NavSubItem
															title="Overview"
															icon={LucideIcons.Activity}
															isActive={pathname === overviewRoute}
															onMouseOver={ChannelOverview.preload}
															onClick={() => {
																existingChannelEvents.clicks.overview()
																runInAction(() => navigate(overviewRoute))
															}}
														/>
														<Sidebar.NavSubItem
															title="Settings"
															icon={LucideIcons.Settings}
															isActive={pathname === settingsRoute}
															onMouseOver={ChannelSettings.preload}
															onClick={() => {
																existingChannelEvents.clicks.settings()
																runInAction(() => navigate(settingsRoute))
															}}
														/>
													</Sidebar.NavItem>
												)
											})}
										</Sidebar.Content>

										<AddChannel
											size="mid"
											variant="modal"
											title="Add new channel"
											isOpen={isAddChannelModalOpen}
											submitButton={{ label: 'Add channel', icon: LucideIcons.Plus }}
											onMsg={msg => {
												switch (msg.type) {
													case 'on_add_channel_modal_close':
														setIsAddChannelModalOpen(false)
														break
													case 'on_new_channel_added':
														setIsAddChannelModalOpen(false)
														addChannelModalToast.show()
														navigate(routesUrls.channel.getEditorRoute(msg.channel))
														break
													default:
														notReachable(msg)
												}
											}}
										/>

										<UserSettings
											nestedModal={null}
											isOpen={!!openModalTab}
											currentTab={openModalTab || 'plan'}
											onClose={() => setOpenModalTab(undefined)}
										/>

										<UserFeedback
											isOpen={isFeedbackModalOpen}
											onClose={() => setIsFeedbackModalOpen(false)}
											categories={['question', 'bug', 'review', 'feature_request']}
											defaultCategory="review"
										/>
									</Sidebar>

									{props.children}
								</>
							)
						default:
							return notReachable(MEDIA)
					}
				}}
			</Observer>
		</Flex>
	)
}
