import { useState, useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { useToast } from '@prostpost/toast'
import { notReachable } from '@prostpost/utils'
import { VStack, Flex, HStack, Hr, Group, Skeleton, Paragraph, Box } from '@prostpost/uikit'

import { routesUrls } from 'app/routes/urls'
import { useChannelsStore } from 'app/domains/Channel/store'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { useLimitsResetDate } from 'app/domains/UserPreferences/hooks'
import { useCurrency, useSubscriptionExpired } from 'app/domains/PricingPlan/hooks'
import { useStripeSubscriptionModify, usePricingPlans } from 'app/domains/PricingPlan/api'
import { UserFeedback } from 'app/modals/UserFeedback'
import { AddChannel } from 'app/domains/Channel/features/AddChannel'
import { ModalExtraChannels } from 'app/domains/PricingPlan/features/ModalExtraChannels'
import type { PricingPlan } from 'app/domains/PricingPlan'

import { SimpleGrid, SlotTile, ModalConfirmRemoveSlot, ModalConfirmReclaimSlot } from './components'

type Msg = { type: 'on_click_switch_to_annual' }
type Props = { plan: Extract<PricingPlan, { name: 'MONTH' | 'ANNUAL' }>; onMsg: (msg: Msg) => void }

const useCopies = (planName: Props['plan']): Record<'title' | 'period', string> => {
	const { t } = useTranslation()
	switch (planName.name) {
		case 'MONTH':
			return { title: t('userSettings.usage.month.title', 'Premium Monthly'), period: '/mo.' }
		case 'ANNUAL':
			return { title: t('userSettings.usage.annual.title', 'Premium Annual'), period: '/mo.' }
		default:
			return { title: 'Subscription', period: '/mo.' }
	}
}

const useCalculatedSumPrices = (plan: Props['plan']) => {
	const user = useOnboardedUserStore()
	if (!plan) return { extra: 0, total: 0 }
	const extraPrice = plan.limits.channelPrice * (user.preferences.limits.channels - plan.limits.channels)
	return { extra: extraPrice, total: extraPrice + plan.price }
}

const useToasts = () => {
	const { t } = useTranslation()

	const extraChannelsAdded = useToast({
		type: 'success',
		text: t('userSettings.plans.annualSwitch.extraChannelsAdded', 'More channels are available to you now!'),
	})

	const extraChannelsNotAdded = useToast({
		type: 'error',
		text: t('userSettings.plans.annualSwitch.extraChannelsNotAdded', 'Unable to add more channels to the account'),
	})

	const extraSpotRemoved = useToast({
		type: 'success',
		text: t('userSettings.plans.annualSwitch.extraSpotRemoved', 'Channel spot successfully removed!'),
	})

	const extraSpotNotRemoved = useToast({
		type: 'error',
		text: t('userSettings.plans.annualSwitch.extraSpotNotRemoved', 'Unable to remove channel spot'),
	})

	return { extraChannelsAdded, extraChannelsNotAdded, extraSpotRemoved, extraSpotNotRemoved }
}

export const UsageOverview = observer(({ plan, onMsg }: Props) => {
	const theme = useTheme()
	const toasts = useToasts()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const isExpired = useSubscriptionExpired()
	const limitsResetDate = useLimitsResetDate()
	const formattedLimitsResetDate = limitsResetDate.format('MMM DD, YYYY')

	const channels = useChannelsStore()
	const user = useOnboardedUserStore()
	const { pricingPlansQuery } = usePricingPlans()
	const { sign: currencySign } = useCurrency('EUR')
	const { modifyStripeSubscriptionMutation } = useStripeSubscriptionModify()

	const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
	const [isAddChannelModalOpen, setIsAddChannelModalOpen] = useState(false)
	const [isBuyExtraChannelsModalOpen, setIsBuyExtraChannelsModalOpen] = useState(false)
	const [isRemoveSlotModalOpen, setIsRemoveSlotModalOpen] = useState(false)
	const [isReclaimSlotModalOpen, setIsReclaimSlotModalOpen] = useState(false)

	const [removingEmptySlot, setRemovingEmptySlot] = useState<number | null>(null)

	const copies = useCopies(plan)
	const calculatedPriceSums = useCalculatedSumPrices(plan)

	const existingExtraChannels = channels.activeList.slice(user.preferences.plan.limits.channels)
	const userPurchasedSlots = user.preferences.limits.channels - user.preferences.plan.limits.channels
	const isPlansLimitReached = channels.activeList.length === user.preferences.plan.limits.channels

	const addChannelModalToast = useToast({
		type: 'success',
		text: t('sidebar.context.addChannel.success', 'Channel was successfully added'),
	})

	useEffect(() => {
		switch (modifyStripeSubscriptionMutation.status) {
			case 'idle':
			case 'loading':
				break
			case 'success': {
				const updatedExtraChannels =
					modifyStripeSubscriptionMutation.variables?.extraChannels ??
					user.preferences.limits.channels - plan.limits.channels

				if (updatedExtraChannels > user.preferences.limits.channels) toasts.extraChannelsAdded.show()
				else toasts.extraSpotRemoved.show()

				setIsBuyExtraChannelsModalOpen(false)
				setIsRemoveSlotModalOpen(false)
				user.preferences.setPreferences({
					...user.preferences,
					limits: {
						...user.preferences.limits,
						channels: plan.limits.channels + updatedExtraChannels,
					},
				})
				break
			}
			case 'error': {
				const updatedExtraChannels =
					modifyStripeSubscriptionMutation.variables?.extraChannels ??
					user.preferences.limits.channels - plan.limits.channels
				if (updatedExtraChannels > user.preferences.limits.channels) toasts.extraChannelsNotAdded.show()
				else toasts.extraSpotNotRemoved.show()
				break
			}
			default:
				notReachable(modifyStripeSubscriptionMutation)
		}
	}, [modifyStripeSubscriptionMutation.status])

	return (
		<VStack space={2}>
			<SimpleGrid cols={4}>
				<SimpleGrid.Row>
					<SimpleGrid.Th title={t('userSettings.usage.table.product', 'Product')} align="left" />
					<SimpleGrid.Th title={t('userSettings.usage.table.quantity', 'Qty.')} />
					<SimpleGrid.Th title={t('userSettings.usage.table.unitPrice', 'Unit price')} />
					<SimpleGrid.Th title={t('userSettings.usage.table.amount', 'Amount')} />
				</SimpleGrid.Row>

				{!pricingPlansQuery.isSuccess ? (
					<SimpleGrid.Row>
						<SimpleGrid.RowSkeleton />
						<SimpleGrid.RowSkeleton />
					</SimpleGrid.Row>
				) : (
					<SimpleGrid.Row>
						<SimpleGrid.Td
							layout="vertical"
							align="left"
							value={copies.title}
							details={t('userSettings.usage.details', '{{count}} channels included', {
								count: plan.limits.channels,
							})}
						/>
						<SimpleGrid.Td value="1" />
						<SimpleGrid.Td value={`${currencySign}${plan.price}`} details={copies.period} />
						<SimpleGrid.Td value={`${currencySign}${plan.price}`} details={copies.period} />
						<SimpleGrid.Td
							align="left"
							layout="vertical"
							value={t('userSettings.usage.extraChannels.title', 'Additional channels')}
						/>
						<SimpleGrid.Td value={`${user.preferences.limits.channels - plan.limits.channels}`} />
						<SimpleGrid.Td value={`${currencySign}${plan.limits.channelPrice}`} details={copies.period} />
						<SimpleGrid.Td value={`${currencySign}${calculatedPriceSums.extra}`} details={copies.period} />
					</SimpleGrid.Row>
				)}
			</SimpleGrid>

			<Hr my={2} />

			<SimpleGrid cols={4} mb={7}>
				<SimpleGrid.Td align="left" value="Total" />
				<SimpleGrid.Td value="" />
				<SimpleGrid.Td value="" />
				{!pricingPlansQuery.isSuccess ? (
					<Flex align="center" just="flex-end">
						<Skeleton width="80%" borderRadius="sm" height={28} />
					</Flex>
				) : (
					<SimpleGrid.Td value={`${currencySign}${calculatedPriceSums.total}`} details={copies.period} />
				)}
			</SimpleGrid>

			<VStack space={6}>
				<Group>
					<Group.Title
						title={
							!userPurchasedSlots
								? t('userSettings.usage.seats.title', 'Channel slots')
								: t('userSettings.usage.seats.title', 'Channel slots in plan')
						}
					>
						<Icons.Box size={16} strokeWidth={2.5} color={theme.colors.black_80} />
					</Group.Title>
					<HStack wrap space={3} gap={3}>
						{Array.from({ length: user.preferences.plan.limits.channels }).map((_, i) => {
							const ch = channels.activeList[i]
							if (ch) return <SlotTile.Busy key={ch.name} ch={ch} />
							return (
								<SlotTile.Available
									key={i}
									isRemovable={false}
									onClick={() => setIsAddChannelModalOpen(true)}
									onClickFloating={() => setIsAddChannelModalOpen(true)}
								/>
							)
						})}

						{!isExpired &&
						!userPurchasedSlots &&
						isPlansLimitReached &&
						!user.preferences.channelSlotsToReclaim &&
						!user.preferences.isSubscriptionCanceled ? (
							<SlotTile.Buy price={3} period="mo" onClick={() => setIsBuyExtraChannelsModalOpen(true)} />
						) : null}
					</HStack>
				</Group>

				<AddChannel
					size="mid"
					variant="modal"
					title={t('content:channel.addNewTitle', 'Add new channel')}
					isOpen={isAddChannelModalOpen}
					submitButton={{ label: t('content:channel.addNewAction', 'Add channel'), icon: Icons.Plus }}
					onMsg={msg => {
						switch (msg.type) {
							case 'on_add_channel_modal_close':
								setIsAddChannelModalOpen(false)
								break
							case 'on_new_channel_added':
								addChannelModalToast.show()
								setIsAddChannelModalOpen(false)
								navigate(routesUrls.channel.getEditorRoute(msg.channel))
								break
							default:
								notReachable(msg)
						}
					}}
				/>

				<ModalExtraChannels
					plan={plan}
					isPending={modifyStripeSubscriptionMutation.isLoading}
					isOpen={isBuyExtraChannelsModalOpen}
					currencySign={currencySign}
					onMsg={msg => {
						switch (msg.type) {
							case 'on_close':
								setIsBuyExtraChannelsModalOpen(false)
								break
							case 'on_confirm':
								modifyStripeSubscriptionMutation.mutate({ extraChannels: msg.count })
								break
							case 'on_click_switch_to_annual':
								setIsBuyExtraChannelsModalOpen(false)
								onMsg({ type: 'on_click_switch_to_annual' })
								break
							case 'on_click_contact_us':
								setIsBuyExtraChannelsModalOpen(false)
								setIsFeedbackModalOpen(true)
								break
							default:
								notReachable(msg)
						}
					}}
				/>

				<UserFeedback
					isOpen={isFeedbackModalOpen}
					onClose={() => setIsFeedbackModalOpen(false)}
					categories={['question', 'bug', 'review', 'feature_request']}
					defaultCategory="question"
				/>

				<ModalConfirmRemoveSlot
					isOpen={isRemoveSlotModalOpen}
					isPending={modifyStripeSubscriptionMutation.isLoading}
					onMsg={msg => {
						switch (msg.type) {
							case 'on_modal_close':
								setRemovingEmptySlot(null)
								setIsRemoveSlotModalOpen(false)
								break
							default:
								notReachable(msg.type)
						}
					}}
				/>

				<ModalConfirmReclaimSlot
					until={formattedLimitsResetDate}
					isOpen={isReclaimSlotModalOpen}
					onMsg={msg => {
						switch (msg.type) {
							case 'on_modal_close':
								setIsReclaimSlotModalOpen(false)
								break
							default:
								notReachable(msg.type)
						}
					}}
				/>

				{userPurchasedSlots || user.preferences.channelSlotsToReclaim ? (
					<>
						<Group>
							<Group.Title title={t('userSettings.usage.seatsExtra.title', 'Additional channel slots')}>
								<Icons.PackagePlus size={16} strokeWidth={2.5} color={theme.colors.black_80} />
							</Group.Title>
							<HStack wrap space={3} gap={3}>
								{existingExtraChannels.map(ch => (
									<SlotTile.Busy key={ch.name} ch={ch} />
								))}

								{Array.from({ length: userPurchasedSlots - existingExtraChannels.length }).map(
									(_, i) => (
										<SlotTile.Available
											key={i}
											isRemovable
											isPending={removingEmptySlot === i}
											isDisabled={removingEmptySlot !== null}
											onClick={() => setIsAddChannelModalOpen(true)}
											onClickFloating={() => {
												setRemovingEmptySlot(i)
												setIsRemoveSlotModalOpen(true)
											}}
										/>
									),
								)}

								{Array.from({ length: user.preferences.channelSlotsToReclaim }).map((_, i) => (
									<SlotTile.Reclaim
										key={i}
										until={formattedLimitsResetDate}
										isDisabled={removingEmptySlot !== null}
										onClick={() => setIsReclaimSlotModalOpen(true)}
									/>
								))}

								{!user.preferences.isSubscriptionCanceled && !isExpired ? (
									<SlotTile.Buy
										price={3}
										period="mo"
										onClick={() => setIsBuyExtraChannelsModalOpen(true)}
									/>
								) : null}
							</HStack>
						</Group>
						<Box maxW="500px">
							<Paragraph size={14} color="blue_40">
								{t(
									'userSettings.usage.seatsRemoval.desc',
									'To free up slots or remove them from billing, archive the channel in the channel settings first.',
								)}
							</Paragraph>
						</Box>
					</>
				) : null}
			</VStack>
		</VStack>
	)
})
