import { CacheProvider } from '@emotion/react'

import { canUseDOM, noop } from '@prostpost/utils'
import { useHistory, BrowserRouterExplicitHistory } from '@prostpost/uikit'

import { config } from 'app/config'

import 'app/resources/styles/base.less'
import 'app/resources/styles/prostpost.less'

import { App as CommonApp } from './app'
import { rootApp, rootModals, rootFloatingUI, RootModals, RootFloatingUI } from './roots'

const { ModalEmotionGlobalStyles } = config.modals

// to avoid umami errors for local development
if (process.env.NODE_ENV === 'development' && canUseDOM()) {
	window.umami = { track: noop, identify: noop }
}

export const App = () => {
	const history = useHistory()

	return (
		<CacheProvider value={config.emotion.cache}>
			<ModalEmotionGlobalStyles />
			{/* We need BrowserRouter here because App also used in server.tsx which has a StaticRouter instead */}
			{/* removeTrailingSlashFromUrl={false} otherwise Supabase redirects on errors are trimmed after # char */}
			<BrowserRouterExplicitHistory removeTrailingSlashFromUrl={false} history={history}>
				<CommonApp />
			</BrowserRouterExplicitHistory>
		</CacheProvider>
	)
}

rootApp.render(<App />)
rootModals.render(<RootModals />)
rootFloatingUI.render(<RootFloatingUI />)
