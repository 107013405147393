import _ from 'lodash';
import json5 from 'json5';
import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

var Methods;
(function (Methods) {
    Methods['LIST'] = 'LIST';
    Methods['GET'] = 'GET';
    Methods['POST'] = 'POST';
    Methods['PATCH'] = 'PATCH';
    Methods['DELETE'] = 'DELETE';
})(Methods || (Methods = {}));

function _arrayLikeToArray(r, a) {
    (null == a || a > r.length) && (a = r.length);
    for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
    return n;
}
function _arrayWithoutHoles(r) {
    if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _defineProperty(e, r, t) {
    return (
        (r = _toPropertyKey(r)) in e
            ? Object.defineProperty(e, r, {
                  value: t,
                  enumerable: true,
                  configurable: true,
                  writable: true,
              })
            : (e[r] = t),
        e
    );
}
function _iterableToArray(r) {
    if (('undefined' != typeof Symbol && null != r[Symbol.iterator]) || null != r['@@iterator']) return Array.from(r);
}
function _nonIterableSpread() {
    throw new TypeError(
        'Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.',
    );
}
function ownKeys(e, r) {
    var t = Object.keys(e);
    if (Object.getOwnPropertySymbols) {
        var o = Object.getOwnPropertySymbols(e);
        r &&
            (o = o.filter(function (r) {
                return Object.getOwnPropertyDescriptor(e, r).enumerable;
            })),
            t.push.apply(t, o);
    }
    return t;
}
function _objectSpread2(e) {
    for (var r = 1; r < arguments.length; r++) {
        var t = null != arguments[r] ? arguments[r] : {};
        r % 2
            ? ownKeys(Object(t), true).forEach(function (r) {
                  _defineProperty(e, r, t[r]);
              })
            : Object.getOwnPropertyDescriptors
              ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t))
              : ownKeys(Object(t)).forEach(function (r) {
                    Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
                });
    }
    return e;
}
function _toConsumableArray(r) {
    return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _toPrimitive(t, r) {
    if ('object' != typeof t || !t) return t;
    var e = t[Symbol.toPrimitive];
    if (void 0 !== e) {
        var i = e.call(t, r);
        if ('object' != typeof i) return i;
        throw new TypeError('@@toPrimitive must return a primitive value.');
    }
    return ('string' === r ? String : Number)(t);
}
function _toPropertyKey(t) {
    var i = _toPrimitive(t, 'string');
    return 'symbol' == typeof i ? i : i + '';
}
function _unsupportedIterableToArray(r, a) {
    if (r) {
        if ('string' == typeof r) return _arrayLikeToArray(r, a);
        var t = {}.toString.call(r).slice(8, -1);
        return (
            'Object' === t && r.constructor && (t = r.constructor.name),
            'Map' === t || 'Set' === t
                ? Array.from(r)
                : 'Arguments' === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t)
                  ? _arrayLikeToArray(r, a)
                  : void 0
        );
    }
}

var combineQueryParams = function combineQueryParams(params) {
    var userParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var sp = {};
    var filteredOptionsParams = _.omit(
        userParams || {},
        options.method === Methods.LIST ? [] : ['sort', 'page', 'filter'],
    );
    if (!params || _.isEmpty(params)) {
        return filteredOptionsParams;
    }
    var possibleParams =
        options.method === Methods.LIST ? ['sort', 'include', 'page', 'filter', 'fields'] : ['include', 'fields'];
    for (var _i = 0, _possibleParams = possibleParams; _i < _possibleParams.length; _i++) {
        var param = _possibleParams[_i];
        if (possibleParams.includes(param) && (filteredOptionsParams[param] || params[param])) {
            switch (param) {
                case 'sort':
                    sp.sort = (filteredOptionsParams.sort || []).concat(params.sort || []);
                    break;
                case 'include':
                    sp.include = (filteredOptionsParams.include || []).concat(params.include || []);
                    break;
                case 'page':
                    sp.page = _objectSpread2(_objectSpread2({}, filteredOptionsParams.page || {}), params.page || {});
                    break;
                case 'filter':
                    if (!params.filter) {
                        sp.filter = filteredOptionsParams.filter;
                    } else if (!filteredOptionsParams.filter) {
                        sp.filter = params.filter;
                    } else if (_.isArray(filteredOptionsParams.filter) && _.isArray(params.filter)) {
                        sp.filter = [].concat(
                            _toConsumableArray(filteredOptionsParams.filter || []),
                            _toConsumableArray(params.filter),
                        );
                    } else {
                        var mergeOR = function mergeOR(op) {
                            var _params$filter, _filteredOptionsParam;
                            var _default =
                                arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
                            var groupFilterParams = !_.isArray(params.filter)
                                ? (_params$filter = params.filter) === null || _params$filter === void 0
                                    ? void 0
                                    : _params$filter[op]
                                : op === 'and'
                                  ? params.filter
                                  : false;
                            var groupFilterOptionsParams =
                                !_.isArray(filteredOptionsParams.filter) &&
                                ((_filteredOptionsParam = filteredOptionsParams.filter) === null ||
                                _filteredOptionsParam === void 0
                                    ? void 0
                                    : _filteredOptionsParam[op]);
                            return !groupFilterParams && !groupFilterOptionsParams
                                ? _default
                                : _defineProperty(
                                      {},
                                      op,
                                      [].concat(
                                          _toConsumableArray(groupFilterOptionsParams || []),
                                          _toConsumableArray(groupFilterParams || []),
                                      ),
                                  );
                        };
                        sp.filter = mergeOR('or');
                        sp.filter = sp.filter
                            ? _objectSpread2(_objectSpread2({}, sp.filter), mergeOR('and', {}))
                            : mergeOR('and');
                    }
                    break;
                case 'fields':
                    filteredOptionsParams.fields = filteredOptionsParams.fields || [];
                    sp.fields = filteredOptionsParams.fields.length
                        ? filteredOptionsParams.fields.concat(params.fields || [])
                        : params.fields;
                    break;
            }
        }
    }
    var combinedCustom = _.omit(_objectSpread2(_objectSpread2({}, params), userParams), possibleParams);
    for (var _i2 = 0, _Object$keys = Object.keys(combinedCustom); _i2 < _Object$keys.length; _i2++) {
        var _param = _Object$keys[_i2];
        sp[_param] = combinedCustom[_param];
    }
    if (options.infinite) {
        sp.__infinite = true;
    }
    return sp;
};
var stringifyQueryParams = function stringifyQueryParams() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var querystring = '';
    var page = params.page,
        sort = params.sort,
        include = params.include,
        fields = params.fields,
        filter = params.filter;
    for (var _i3 = 0, _Object$keys2 = Object.keys(params); _i3 < _Object$keys2.length; _i3++) {
        var param = _Object$keys2[_i3];
        switch (param) {
            case 'page': {
                if (page !== null && page !== void 0 && page.number) {
                    querystring += '&page='.concat(page.number);
                }
                if (page !== null && page !== void 0 && page.size) {
                    querystring += '&size='.concat(page.size);
                }
                continue;
            }
            case 'sort': {
                if (sort) {
                    querystring += '&sort='.concat(sort.join());
                }
                continue;
            }
            case 'include': {
                if (include) {
                    querystring += '&include='.concat(include.join());
                }
                continue;
            }
            case 'fields': {
                if (fields) {
                    querystring += '&fields='.concat(fields.join(','));
                }
                continue;
            }
            case 'filter': {
                if (filter) {
                    querystring += '&filter='.concat(json5.stringify(filter));
                }
                continue;
            }
        }
        var val = params[param];
        if ((val && _.isString(val)) || _.isNumber(val)) {
            querystring += '&'.concat(param, '=').concat(val);
        } else if (_.isBoolean(val)) {
            querystring += '&'.concat(param, '=').concat(val ? 'true' : 'false');
        }
    }
    return _.replace(querystring, '&', '');
};
var generateJsonApiError = function generateJsonApiError(errResponse) {
    var _ref2 = (errResponse === null || errResponse === void 0 ? void 0 : errResponse.response) || {
            config: {},
        },
        status = _ref2.status,
        _ref2$config = _ref2.config,
        baseURL = _ref2$config.baseURL,
        url = _ref2$config.url,
        method = _ref2$config.method;
    return [
        {
            status: status || 500,
            code: 'NOT_VALID_ERROR',
            detail: errResponse === null || errResponse === void 0 ? void 0 : errResponse.message,
            source: ''.concat(baseURL || '/').concat(url || ''),
            details: {
                method: method || 'UNKNOWN',
            },
        },
    ];
};
var parseErrors = function parseErrors(errResponse, knownErrors) {
    var _errResponse$response, _errResponse$response2;
    return (
        (
            (errResponse === null || errResponse === void 0
                ? void 0
                : (_errResponse$response = errResponse.response) === null || _errResponse$response === void 0
                  ? void 0
                  : (_errResponse$response2 = _errResponse$response.data) === null || _errResponse$response2 === void 0
                    ? void 0
                    : _errResponse$response2.errors) || [
                {
                    status: 500,
                    title: 'Not valid JSONAPI error',
                    meta: {
                        code: 'NOT_VALID_ERROR',
                    },
                },
            ]
        ).map(function (error) {
            var _error$meta, _error$meta2, _error$meta3;
            var isKnownError =
                (error === null || error === void 0
                    ? void 0
                    : (_error$meta = error.meta) === null || _error$meta === void 0
                      ? void 0
                      : _error$meta.code) && knownErrors.includes(error.meta.code);
            return {
                status: error.status,
                title: error.title,
                details: error.details,
                detail: error.detail,
                code:
                    (_error$meta2 = error.meta) !== null && _error$meta2 !== void 0 && _error$meta2.code && isKnownError
                        ? (_error$meta3 = error.meta) === null || _error$meta3 === void 0
                            ? void 0
                            : _error$meta3.code
                        : 'UNKNOWN_ERROR',
            };
        }) || generateJsonApiError(errResponse)
    );
};
var parseFirstError = function parseFirstError(errResponse, knownErrors) {
    return (
        parseErrors(errResponse, knownErrors)[0] || {
            status: 500,
            code: 'NOT_VALID_ERROR',
        }
    );
};
var parseList = function parseList(response) {
    var ignorePaths = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    return {
        data: response.data.map(function (item) {
            var result = _objectSpread2(
                _objectSpread2({}, item.attributes),
                {},
                {
                    id: item.id,
                },
            );
            if (item.relationships) {
                var _loop = function _loop() {
                    var _rel$data, _rel$data2;
                    var relName = _Object$keys3[_i4];
                    var rel = item.relationships[relName];
                    var includedRel = (response.included || []).filter(function (inc) {
                        return (
                            rel.data &&
                            !_.isArray(rel.data) &&
                            inc.type === rel.data.type &&
                            inc.id &&
                            inc.id === rel.data.id
                        );
                    });
                    var relDataType = !_.isArray(rel.data)
                        ? (_rel$data = rel.data) === null || _rel$data === void 0
                            ? void 0
                            : _rel$data.type
                        : null;
                    var relDataId = !_.isArray(rel.data)
                        ? (_rel$data2 = rel.data) === null || _rel$data2 === void 0
                            ? void 0
                            : _rel$data2.id
                        : null;
                    if (relDataType) {
                        result[relDataType] = _objectSpread2(
                            {
                                id: relDataId,
                            },
                            !_.isEmpty(includedRel) ? includedRel[0].attributes : {},
                        );
                    }
                };
                for (var _i4 = 0, _Object$keys3 = Object.keys(item.relationships); _i4 < _Object$keys3.length; _i4++) {
                    _loop();
                }
            }
            return camelcaseKeys(result, {
                deep: true,
                stopPaths: ignorePaths,
            });
        }),
        meta: camelcaseKeys(response.meta || {}, {
            deep: true,
            stopPaths: ignorePaths,
        }),
    };
};
var parseInfiniteList = function parseInfiniteList(response) {
    var ignorePaths = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    return _objectSpread2(
        {
            data: response.map(function (item) {
                return parseList(item, ignorePaths).data || [];
            }),
        },
        response.length ? _.omit(response[0], 'data') : {},
    );
};
var parseSingle = function parseSingle(response) {
    var _response$data, _response$data2, _response$data3;
    var ignorePaths = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var obj = {
        data:
            (_response$data = response.data) === null || _response$data === void 0 ? void 0 : _response$data.attributes,
        meta: response.meta,
    };
    if ((_response$data2 = response.data) !== null && _response$data2 !== void 0 && _response$data2.id) {
        obj.id = response.data.id;
    }
    var relationships =
        response === null || response === void 0
            ? void 0
            : (_response$data3 = response.data) === null || _response$data3 === void 0
              ? void 0
              : _response$data3.relationships;
    if (relationships) {
        var _loop2 = function _loop2() {
            var r = _Object$keys4[_i5];
            var entity = (response.included || []).find(function (item) {
                return item.type === r;
            });
            if (entity) {
                obj.data[r] = entity.attributes;
            }
        };
        for (var _i5 = 0, _Object$keys4 = Object.keys(relationships); _i5 < _Object$keys4.length; _i5++) {
            _loop2();
        }
    }
    return camelcaseKeys(obj, {
        deep: true,
        stopPaths: ignorePaths,
    });
};
var serialize = function serialize(resource, data, method, params) {
    if (method === Methods.LIST || method === Methods.GET) {
        console.error('LIST and GET methods can not accept data body');
        return undefined;
    } else if (['POST', 'PATCH', 'PUT', 'DELETE'].includes(method)) {
        var result = {
            type: resource,
            attributes: data,
        };
        if (params && params.relations) {
            result.relationships = {};
            var _loop3 = function _loop3() {
                var rel = _Object$keys5[_i6];
                result.relationships[rel] = {
                    data: params.relations[rel].map(function (r) {
                        return {
                            type: rel,
                            id: ''.concat(r),
                        };
                    }),
                };
            };
            for (var _i6 = 0, _Object$keys5 = Object.keys(params.relations); _i6 < _Object$keys5.length; _i6++) {
                _loop3();
            }
        }
        return {
            data: snakeCaseKeys(result, {
                deep: true,
            }),
        };
    } else {
        console.error('Method "'.concat(method, '" is unknown'));
        return undefined;
    }
};

export {
    Methods,
    combineQueryParams,
    parseErrors,
    parseFirstError,
    parseInfiniteList,
    parseList,
    parseSingle,
    serialize,
    stringifyQueryParams,
};
