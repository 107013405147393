import { notReachable } from '@prostpost/utils'

import type { PricingPlanAnnual, PricingPlanMonth } from 'app/domains/PricingPlan'

type Plan = PricingPlanMonth | PricingPlanAnnual
type PlanName = 'ANNUAL' | 'MONTH'

export const useSubPeriodFromPlan = (plan: Plan | PlanName) => {
	if (typeof plan === 'string') {
		if (plan === 'ANNUAL') return 'annual'
		if (plan === 'MONTH') return 'monthly'
		return notReachable(plan)
	}

	if (plan.name === 'ANNUAL') return 'annual'
	if (plan.name === 'MONTH') return 'monthly'
	return notReachable(plan)
}
