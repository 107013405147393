import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { useSubscriptionExpired } from 'app/domains/PricingPlan/hooks'

export const useSubscriptionStatus = () => {
	const { preferences } = useOnboardedUserStore()

	const isExpired = useSubscriptionExpired()
	const isTrial = preferences.plan.name === 'TRIAL'
	const isCanceled = preferences.isSubscriptionCanceled

	if (isTrial) return 'TRIAL'
	if (isExpired) return 'EXPIRED'

	if (isCanceled && !isExpired) {
		if (preferences.plan.name === 'MONTH') return 'RENEWABLE_MONTHLY'
		if (preferences.plan.name === 'ANNUAL') return 'RENEWABLE_ANNUAL'
		return 'EXPIRED'
	}

	if (!isTrial && !isCanceled && !isExpired) {
		if (preferences.plan.name === 'MONTH') return 'ACTIVE_MONTHLY'
		if (preferences.plan.name === 'ANNUAL') return 'ACTIVE_ANNUAL'
		return 'EXPIRED'
	}

	return 'EXPIRED'
}
