import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { Button } from '@prostpost/uikit'
import { useOpenWindow } from '@prostpost/utils'

import { STRIPE_CUSTOMER_PORTAL_URL } from 'app/config/constants'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { usePlansAnalyticsEvents } from 'app/config/analytics/events/plans'

export const BillingAction = () => {
	const { t } = useTranslation()
	const user = useOnboardedUserStore()

	const openNewWindow = useOpenWindow()
	const planEvents = usePlansAnalyticsEvents()

	return (
		<Button.Multi
			icon={{ position: 'right', Icon: Icons.ExternalLink }}
			onClick={() => {
				planEvents.clicks.manageSubscription()
				openNewWindow(STRIPE_CUSTOMER_PORTAL_URL(user.email))
			}}
		>
			{t('userSettings.plans.billingSubscription', 'Billing')}
		</Button.Multi>
	)
}
