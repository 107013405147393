import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { Heading, Text } from '@prostpost/uikit'

import { ContainerStyled, LoadingOverlay } from './styled'
import { FloatingButton } from './FloatingButton'

type Props = {
	isPending?: boolean
	isDisabled?: boolean
	isRemovable: boolean
	onClickFloating: () => void
	onClick: () => void
}

export const Available = ({ isPending, isDisabled, isRemovable, onClick, onClickFloating }: Props) => {
	const { t } = useTranslation()
	return (
		<ContainerStyled
			py={3}
			px={4}
			space={1}
			maxW="130px"
			borderColor="blue_20"
			borderWidth={1}
			borderRadius="small"
			position="relative"
			cursor={isDisabled ? 'default' : 'pointer'}
			isDisabled={!!isDisabled}
			isPending={!!isPending}
			onClick={onClick}
		>
			{isPending ? <LoadingOverlay /> : null}

			<Heading h={5} color="blue_40">
				{t('userSettings.usage.slot.availableTitle', 'Available')}
			</Heading>
			<Text size={12} color="blue_40">
				{t('userSettings.usage.slot.availableDesc', 'Add a channel')}
			</Text>

			{isPending ? null : isRemovable ? (
				<FloatingButton variant="danger" icon={Icons.X} onClick={onClickFloating} />
			) : (
				<FloatingButton variant="normal" icon={Icons.Plus} onClick={onClickFloating} />
			)}
		</ContainerStyled>
	)
}
