import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { Modal, Button, VStack, Heading, Paragraph } from '@prostpost/uikit'

import { ReactModal } from 'app/modals'
import { UserSettings } from 'app/modals/UserSettings'
import { useChannelsStore } from 'app/domains/Channel/store'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { useNewChannelAnalyticsEvents } from 'app/config/analytics/events/channel'

import { AddChannelPlain } from '../Plain'
import type { Interface as AddChannelInterface, Msg as AddChannelMsg } from '../Plain'

export type Msg = AddChannelMsg | { type: 'on_add_channel_modal_close' }

type MsgProp = {
	onMsg: (msg: Msg) => void
}

export type Interface = AddChannelInterface & {
	isOpen: boolean
}

export const AddChannelModal = observer(({ isOpen, onMsg, ...props }: Interface & MsgProp) => {
	const theme = useTheme()
	const { t } = useTranslation()
	const channelEvents = useNewChannelAnalyticsEvents()

	const channelsStore = useChannelsStore()
	const userStore = useOnboardedUserStore()

	const [userSettingsModalOpenTab, setUserSettingsModalOpenTab] = useState<'plan' | 'usage' | false>(false)

	// we need this check only once on initial opening
	// because otherwise it will be triggered after we just added a new channel and instead of closing the modal
	// and redirecting to a channel we will see limits reached modal
	const [isLimitReached] = useState(channelsStore.activeList.length >= userStore.preferences.limits.channels)

	return (
		<>
			<ReactModal
				shouldCloseOnEsc
				shouldCloseOnOverlayClick
				isOpen={isOpen}
				onRequestClose={e => {
					e.stopPropagation()
					e.preventDefault()
					onMsg({ type: 'on_add_channel_modal_close' })
				}}
			>
				{isLimitReached ? (
					<Modal variant="confirm">
						<Modal.Close onClick={() => onMsg({ type: 'on_add_channel_modal_close' })} />
						<Modal.Content>
							<VStack space={6} p={6} align="center">
								<Icons.HeartCrack size={48} strokeWidth={2} color={theme.colors.blue_40} />

								<VStack space={1}>
									<Heading centered h={4}>
										{t('content:addChannel.modal.limitReachedTitle', 'Channels limit reached')}
									</Heading>
									<Paragraph centered size={14}>
										{t(
											'content:addChannel.modal.limitReachedDesc',
											'You have reached your plan limit of channels. Please upgrade your plan to continue.',
										)}
									</Paragraph>
								</VStack>

								<Button.Action
									variant="primary"
									size="mid"
									icon={<Icons.ArrowBigUp strokeWidth={2.5} size={20} />}
									onClick={e => {
										e?.stopPropagation()
										e?.preventDefault()
										if (userStore.preferences.plan.name === 'TRIAL') {
											setUserSettingsModalOpenTab('plan')
										} else {
											setUserSettingsModalOpenTab('usage')
										}
										onMsg({ type: 'on_add_channel_modal_close' })
									}}
								>
									{userStore.preferences.plan.name === 'TRIAL'
										? t('content:addChannel.modal.upgradePlan', 'Upgrade plan')
										: t('content:addChannel.modal.increaseLimits', 'Increase limits')}
								</Button.Action>
							</VStack>
						</Modal.Content>
					</Modal>
				) : (
					<Modal variant="content">
						<Modal.Close
							onClick={() => {
								channelEvents.clicks.modalClosed()
								onMsg({ type: 'on_add_channel_modal_close' })
							}}
						/>
						<Modal.Content>
							<AddChannelPlain {...props} isFullWidth onMsg={onMsg} />
						</Modal.Content>
					</Modal>
				)}
			</ReactModal>

			{userSettingsModalOpenTab && (
				<UserSettings
					currentTab={userSettingsModalOpenTab}
					isOpen={!!userSettingsModalOpenTab}
					onClose={() => setUserSettingsModalOpenTab(false)}
				/>
			)}
		</>
	)
})
