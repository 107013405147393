import qs from 'qs'
import { useTimer } from 'use-timer'
import { Helmet } from 'react-helmet-async'
import React, { useMemo, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { canUseDOM } from '@prostpost/utils'
import { useCurrentMedia } from '@prostpost/css'
import { Heading, Paragraph, Button, Box, Image, VStack } from '@prostpost/uikit'

import { config } from 'app/config'
import type { Cookies } from 'app/config/cookies'

import { useIsUserSignedIn } from 'app/domains/User/hooks'

import { PublicPageLayout } from 'app/shared/components'

type Msg = { type: 'on_before_redirect' } | { type: 'on_unavailable_opening' } | { type: 'on_mount' }

type Props = {
	title: string
	cookie?: keyof Cookies
	redirectKnownUserTo: string
	isTestMode?: boolean
	onMsg?: (msg: Msg) => void
	children: ({
		isAuthenticated,
		actionAlreadyCompleted,
	}: {
		isAuthenticated: boolean
		actionAlreadyCompleted: boolean
	}) => React.ReactElement<{ children: React.ReactElement[] }>
}

export const Container: React.FC<Props> = ({ title, cookie, redirectKnownUserTo, isTestMode, children, onMsg }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const isUserSignedIn = useIsUserSignedIn()
	const isMobile = useCurrentMedia() === 'MOBILE'

	const actionAlreadyCompleted = useMemo<boolean>(() => {
		const query = canUseDOM() ? qs.parse(location.search, { ignoreQueryPrefix: true }) : {}
		return !!query.already
	}, [])

	const redirect = (): void => {
		if (cookie) config.cookies.removeCookie(cookie)
		onMsg?.({ type: 'on_before_redirect' })
		if (isUserSignedIn) {
			canUseDOM(() => window.location.assign(redirectKnownUserTo))
		} else {
			navigate('/')
		}
	}

	const { time } = useTimer({
		initialTime: isTestMode ? 100000 : 100,
		endTime: 0,
		timerType: 'DECREMENTAL',
		autostart: true,
		onTimeOver: redirect,
	})

	// Do not display that page if user lands here
	// not from unsubscribed link
	useEffect(() => {
		if (cookie && !config.cookies.getCookie(cookie) && !isTestMode) {
			onMsg?.({ type: 'on_unavailable_opening' })
			navigate('/')
		} else {
			onMsg?.({ type: 'on_mount' })
		}
	}, [])

	return (
		<PublicPageLayout w="100%" h="100dvh">
			<Helmet>
				<title>{title}</title>
			</Helmet>

			<PublicPageLayout.Header />
			<PublicPageLayout.Content centered align="center" h="80%" minH="auto">
				<VStack space={2} align="center" just="center" h={isMobile ? '100%' : 'auto'}>
					{React.Children.map(
						children({ isAuthenticated: !!isUserSignedIn, actionAlreadyCompleted }).props.children,
						(child: React.ReactElement) => {
							if (child.type === Action) return React.cloneElement(child, { __onClick: redirect })
							return child
						},
					)}
					<Paragraph mt={3} size={14} color="blue_40">
						{`Automatic redirect in ${time} seconds`}
					</Paragraph>
				</VStack>
			</PublicPageLayout.Content>
		</PublicPageLayout>
	)
}

export const Icon = ({ icon: Icon, alt }: { icon: string; alt: string } | { icon: React.FC; alt?: never }) =>
	typeof Icon === 'string' ? <Image alt={alt || ''} width="100px" src={Icon} /> : <Icon />

export const Title = ({ children }: { children: string }) => (
	<Heading h={2} mt={3} centered color="black_80" title={children} />
)

export const Description = ({ children }: { children: string }) => (
	<Box w="100%" maxW="600px" mt={5}>
		<Paragraph centered size={14} color="blue_40">
			{children}
		</Paragraph>
	</Box>
)

export const Action = ({ __onClick, children }: { __onClick?: () => void; children: string }) => (
	<Button.Action variant="primary" mt={7} title={children} onClick={__onClick} />
)
