import { useLocation } from 'react-router-dom'

import { trackEvent } from '../track'

export const useBotAnalyticsEvents = () => {
	const ref = useLocation().pathname
	const trackClick = (e: string, params?: UmamiData) => trackEvent('bot', ref, 'click', e, params)
	return {
		clicks: {
			// Reconnect
			reconnect: () => trackClick('reconnect'),
			reconnectSubmit: () => trackClick('reconnect_submit'),
			reconnectCancel: () => trackClick('reconnect_cancel'),
			reconnectTelegramUrl: () => trackClick('reconnect_telegram_url'),
			// Unlink
			unlink: () => trackClick('unlink'),
			unlinkSubmit: () => trackClick('unlink_submit'),
			unlinkCancel: () => trackClick('unlink_cancel'),
		},
	}
}
