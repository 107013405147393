import styled from '@emotion/styled'

import { TextLink } from '@prostpost/uikit'

export const Banner = styled.div`
	position: absolute;
	bottom: ${({ theme }) => theme.space[3]};

	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.space[3]};

	width: 100%;
	padding: ${({ theme }) => theme.space[4]};

	background: ${({ theme }) => theme.colors.yellow_100};
	border-radius: ${({ theme }) => theme.radii.small};
`

export const BannerTextLink = styled(TextLink)`
	text-decoration: underline;
`
