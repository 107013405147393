import { useTranslation } from 'react-i18next'

import { useToast } from '@prostpost/toast'
import { Modal, Paragraph, Heading } from '@prostpost/uikit'

import { ReactModal } from 'app/modals'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { useReclaimChannelSlot } from 'app/domains/UserPreferences/api'
import { useStripeSubscriptionModify } from 'app/domains/PricingPlan/api'
import { useSubscriptionExpired } from 'app/domains/PricingPlan/hooks'

type Msg = { type: 'on_modal_close' }

type MsgProp = {
	onMsg: (msg: Msg) => void
}

type Interface = {
	until: string
	isOpen: boolean
}

const useToasts = () => {
	const { t } = useTranslation()

	const unableToUpdateSubscription = useToast({
		type: 'error',
		text: t('content:modals.reclaim.error.subscription', 'Unable to update subscription'),
	})

	const unableToReclaimSlot = useToast({
		type: 'error',
		text: t('content:modals.reclaim.error.slot', 'Unable to reclaim slot'),
	})

	return { unableToUpdateSubscription, unableToReclaimSlot }
}

export const ModalConfirmReclaimSlot = ({ until, isOpen, onMsg }: Interface & MsgProp) => {
	const toasts = useToasts()
	const { t } = useTranslation()

	const user = useOnboardedUserStore()
	const isExpired = useSubscriptionExpired()

	const { reclaimChannelSlotMutation } = useReclaimChannelSlot({
		onError: () => toasts.unableToReclaimSlot.show(),
		onSuccess: () => {
			user.preferences.setPreferences({
				...user.preferences,
				channelSlotsToReclaim: user.preferences.channelSlotsToReclaim - 1,
				limits: {
					...user.preferences.limits,
					channels: user.preferences.limits.channels + 1,
				},
			})
			onMsg({ type: 'on_modal_close' })
		},
	})

	const { modifyStripeSubscriptionMutation } = useStripeSubscriptionModify({
		// decrement available to reclaim slots after a spot reclaimed
		onSuccess: () => reclaimChannelSlotMutation.mutate(),
		onError: () => toasts.unableToUpdateSubscription.show(),
	})

	const isPending = reclaimChannelSlotMutation.isLoading || modifyStripeSubscriptionMutation.isLoading

	return (
		<ReactModal
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
			isOpen={isOpen}
			onRequestClose={e => {
				e.stopPropagation()
				e.preventDefault()
				onMsg({ type: 'on_modal_close' })
			}}
		>
			<Modal variant="confirm">
				<Modal.Close onClick={() => onMsg({ type: 'on_modal_close' })} />
				<Modal.Content>
					<Heading h={4}>{t('userSettings.usage.reclaimSlotModal.title', 'Reclaim channel slot')}</Heading>
					{isExpired || user.preferences.isSubscriptionCanceled ? (
						<Paragraph my={3} size={14} color="blue_40">
							{t(
								'userSettings.usage.reclaimSlotModal.descDisabled',
								'To reclaim a slot you need to have a paid plan. You can upgrade your plan to continue using Prostpost.',
								{ until },
							)}
						</Paragraph>
					) : (
						<Paragraph my={3} size={14} color="blue_40">
							{t(
								'userSettings.usage.reclaimSlotModal.desc',
								'This slot has already been paid for. You can restore it now and assign a new channel to it immediately. Starting from your next billing period ({{until}}), this slot will be counted as an additional slot and billed separately.',
								{ until },
							)}
						</Paragraph>
					)}
				</Modal.Content>
				<Modal.ConfirmSecondaryAction onClick={() => onMsg({ type: 'on_modal_close' })}>
					{t('userSettings.usage.reclaimSlotModal.cancel', 'Cancel')}
				</Modal.ConfirmSecondaryAction>
				<Modal.ConfirmPrimaryAction
					state={
						isPending
							? 'pending'
							: isExpired || user.preferences.isSubscriptionCanceled
								? 'disabled'
								: 'normal'
					}
					onClick={() =>
						modifyStripeSubscriptionMutation.mutate({
							extraChannels: user.preferences.limits.channels - user.preferences.plan.limits.channels + 1,
						})
					}
				>
					{t('userSettings.usage.reclaimSlotModal.confirm', 'Reclaim')}
				</Modal.ConfirmPrimaryAction>
			</Modal>
		</ReactModal>
	)
}
