import type { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import type { UseMutationOptions } from 'react-query'

import type { JsonApiErrorResponse, ParsedError, JsonApiResponseSingle } from '@prostpost/jsonapi'
import { parseFirstError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

const knownErrors = ['UNABLE_TO_UPDATE_PREFERENCES'] as const

const reclaimChannelSlot = (): Promise<null> =>
	axiosInstance
		.post<JsonApiResponseSingle>('/auth/preferences/reclaim-slot')
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(() => null)

export const useReclaimChannelSlot = (
	options?: UseMutationOptions<null, ParsedError<(typeof knownErrors)[number]>>,
) => {
	return {
		reclaimChannelSlotMutation: useMutation<null, ParsedError<(typeof knownErrors)[number]>>(
			reclaimChannelSlot,
			options,
		),
	}
}
