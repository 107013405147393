import { z } from 'zod'

import type { PricingPlan, PricingPlanTrial, PricingPlanMonth, PricingPlanAnnual, PricingPlanTeam } from '..'

const PricingPlanTrialSchema: z.ZodSchema<PricingPlanTrial, z.ZodTypeDef, unknown> = z
	.object({
		id: z.number(),
		price: z.number(),
		name: z.literal('TRIAL'),
		details: z.object({ postsLimit: z.number().min(0), channelsLimit: z.number().min(0) }),
	})
	.transform(response => {
		return {
			id: response.id,
			price: response.price,
			name: response.name,
			limits: { posts: response.details.postsLimit, channels: response.details.channelsLimit },
		}
	})

const PricingPlanMonthSchema: z.ZodSchema<PricingPlanMonth, z.ZodTypeDef, unknown> = z
	.object({
		id: z.number(),
		price: z.number(),
		name: z.literal('MONTH'),
		details: z.object({
			postsLimit: z.number().min(0),
			channelsLimit: z.number().min(0),
			channelPrice: z.number().min(0),
		}),
	})
	.transform(response => {
		return {
			id: response.id,
			price: response.price,
			name: response.name,
			limits: {
				posts: response.details.postsLimit,
				channels: response.details.channelsLimit,
				channelPrice: response.details.channelPrice,
			},
		}
	})

const PricingPlanAnnualSchema: z.ZodSchema<PricingPlanAnnual, z.ZodTypeDef, unknown> = z
	.object({
		id: z.number(),
		price: z.number(),
		name: z.literal('ANNUAL'),
		details: z.object({
			postsLimit: z.number().min(0),
			channelsLimit: z.number().min(0),
			channelPrice: z.number().min(0),
		}),
	})
	.transform(response => {
		return {
			id: response.id,
			price: response.price,
			name: response.name,
			limits: {
				posts: response.details.postsLimit,
				channels: response.details.channelsLimit,
				channelPrice: response.details.channelPrice,
			},
		}
	})

const PricingPlanTeamSchema: z.ZodSchema<PricingPlanTeam, z.ZodTypeDef, unknown> = z
	.object({
		id: z.number(),
		price: z.number(),
		name: z.literal('TEAM'),
		details: z.object({
			postsLimit: z.number().min(0),
			membersLimit: z.number().min(0),
			channelsLimit: z.number().min(0),
			memberPrice: z.number().min(0),
			channelPrice: z.number().min(0),
		}),
	})
	.transform(response => {
		return {
			id: response.id,
			price: response.price,
			name: response.name,
			limits: {
				posts: response.details.postsLimit,
				members: response.details.membersLimit,
				channels: response.details.channelsLimit,
				channelPrice: response.details.channelPrice,
				memberPrice: response.details.memberPrice,
			},
		}
	})

export const PricingPlanSchema: z.ZodSchema<PricingPlan, z.ZodTypeDef, unknown> = z.union([
	PricingPlanTrialSchema,
	PricingPlanMonthSchema,
	PricingPlanAnnualSchema,
	PricingPlanTeamSchema,
])

export const StripeCheckoutNewSessionSchema: z.ZodSchema<{ url: string }, z.ZodTypeDef, unknown> = z.object({
	url: z.string().url(),
})

export const StripeCheckoutExistingSubSessionSchema: z.ZodSchema<{ url: string }, z.ZodTypeDef, unknown> = z.object({
	url: z.literal('EMPTY'),
	reason: z.literal('EXISTING_ACTIVE_SUBSCRIPTION_ASSIGNED'),
})

export const StripeCheckoutSessionSchema: z.ZodSchema<
	{ url: string } | { url: 'EMPTY'; reason: 'EXISTING_ACTIVE_SUBSCRIPTION_ASSIGNED' },
	z.ZodTypeDef,
	unknown
> = z.union([StripeCheckoutNewSessionSchema, StripeCheckoutExistingSubSessionSchema])
