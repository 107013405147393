import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { captureException } from '@sentry/react'

import { notReachable } from '@prostpost/utils'

import { useChannelsStore } from 'app/domains/Channel/store'
import { useAddNewChannel as useAddNewChannelApi } from 'app/domains/Channel/api'
import type { ChannelActive } from 'app/domains/Channel'

type Status =
	| { type: 'loading' | 'idle' }
	| { type: 'error'; message: string }
	| { type: 'success'; message: string; data: ChannelActive }

export const useAddNewChannel = () => {
	const { t } = useTranslation()

	const channels = useChannelsStore()
	const { addNewChannelMutation } = useAddNewChannelApi()
	const [status, setStatus] = useState<Status>({ type: 'idle' })

	useEffect(() => {
		switch (addNewChannelMutation.status) {
			case 'idle':
				setStatus({ type: 'idle' })
				break
			case 'loading':
				setStatus({ type: 'loading' })
				break
			case 'error':
				switch (addNewChannelMutation.error.code) {
					case 'UNKNOWN_ERROR':
					case 'NOT_VALID_ERROR':
						captureException(addNewChannelMutation.error)
						setStatus({
							type: 'error',
							message: t('channel.addChannel.error.unknown', 'Unable to get channel from Telegram'),
						})
						break
					case 'NOT_CHANNEL':
						setStatus({
							type: 'error',
							message: t('channel.addChannel.error.notChannel', 'This is not a channel'),
						})
						break
					case 'BOT_IS_NOT_ADMIN':
						setStatus({
							type: 'error',
							message: t(
								'channel.addChannel.error.botNotAdmin',
								'Please add bot to channel admins first',
							),
						})
						break
					case 'TELEGRAM_CHANNEL_NOT_FOUND':
						setStatus({
							type: 'error',
							message: t('channel.addChannel.error.notFound', 'Channel not found in Telegram'),
						})
						break
					case 'CHANNEL_ALREADY_EXISTS':
					case 'CHANNEL_OWNER_IS_DIFFERENT_FROM_CURRENT_USER':
						setStatus({
							type: 'error',
							message: t('channel.addChannel.error.conflict', 'This channel is already connected'),
						})
						break
					default:
						notReachable(addNewChannelMutation.error.code)
				}
				break
			case 'success':
				channels.addNewChannel(addNewChannelMutation.data)
				setStatus({
					type: 'success',
					data: addNewChannelMutation.data,
					message: t('channel.addChannel.success', 'Bot was successfully linked to your account'),
				})
				break
			default:
				notReachable(addNewChannelMutation)
		}
	}, [addNewChannelMutation.status])

	return { addNewChannelStatus: status, addNewChannel: addNewChannelMutation.mutate }
}
