import type { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError, parseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { ChannelActive } from '..'

import { ActiveChannelSchema } from './schemas'

const knownErrors = [
	'TELEGRAM_CHANNEL_NOT_FOUND',
	'NOT_CHANNEL',
	'BOT_IS_NOT_ADMIN',
	'CHANNEL_ALREADY_EXISTS',
	'CHANNEL_OWNER_IS_DIFFERENT_FROM_CURRENT_USER',
] as const

type Data = {
	channelName: string
}

const createNewChannel = ({ channelName }: Data): Promise<ChannelActive> =>
	axiosInstance
		.post<JsonApiResponseSingle>(`/drafts/channel/${channelName}`)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return ActiveChannelSchema.parse(result.data)
		})

export const useAddNewChannel = () => {
	return {
		addNewChannelMutation: useMutation<ChannelActive, ParsedError<(typeof knownErrors)[number]>, Data>(
			'channel',
			(data: Data) => createNewChannel(data),
		),
	}
}
