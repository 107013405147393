import { useLocation } from 'react-router-dom'

import { trackEvent } from '../track'

export const useInboxAnalyticsEvents = () => {
	const ref = useLocation().pathname
	const trackClick = (e: string, params?: UmamiData) => trackEvent('inbox', ref, 'click', e, params)
	return {
		clicks: {
			inbox: () => trackClick('inbox'),
			channelPopup: () => trackClick('channel_popup'),
			assignChannel: () => trackClick('assign_channel'),
			unassignChannel: () => trackClick('unassign_channel'),
			openInChannel: () => trackClick('open_in_channel'),
		},
	}
}
