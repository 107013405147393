import { lazy } from 'react'
import type { ComponentType } from 'react'

type ComponentTypeWithPreload = ComponentType<unknown> & { preload: () => Promise<{ default: ComponentType<unknown> }> }

export const ReactLazyPreload = (importStatement: () => Promise<{ default: ComponentType<unknown> }>) => {
	const Component = lazy(importStatement) as unknown as ComponentTypeWithPreload
	Component.preload = importStatement
	return Component
}
