import { z } from 'zod'

import { MEDIA_URL } from 'app/domains/Media/constants'

import { userRoles } from '..'
import type { User, OnboardedUser, NotOnboardedUser, Waitlists } from '..'

export const UserMetaAuthSchema: z.ZodSchema<
	{ accessCsrfToken: string; refreshCsrfToken: string },
	z.ZodTypeDef,
	unknown
> = z.object({ accessCsrfToken: z.string(), refreshCsrfToken: z.string() })

const UserNotOnboardedSchema: z.ZodSchema<NotOnboardedUser, z.ZodTypeDef, unknown> = z
	.object({
		uuid: z.string().uuid(),
		email: z.string().email(),
		isOnboarded: z.literal(false),
		name: z.optional(z.string().max(50)),
		lastName: z.optional(z.string().max(50)),
	})
	.transform(response => ({
		type: 'NOT_ONBOARDED',
		roles: [],
		uuid: response.uuid,
		email: response.email,
		firstName: response.name,
		lastName: response.lastName,
		fullName:
			(response.name && response.lastName
				? `${response.name} ${response.lastName}`
				: response.name || response.lastName) || undefined,
	}))

const UserOnboardedSchema: z.ZodSchema<OnboardedUser, z.ZodTypeDef, unknown> = z
	.object({
		uuid: z.string().uuid(),
		email: z.string().email(),
		isOnboarded: z.literal(true),
		ava: z.optional(z.object({ url: z.string() })),
		firstName: z.optional(z.string().max(50)),
		lastName: z.optional(z.string().max(50)),
		roles: z.array(z.enum(userRoles)),
	})
	.transform(response => ({
		type: 'ONBOARDED',
		uuid: response.uuid,
		email: response.email,
		roles: response.roles || [],
		ava: response.ava ? MEDIA_URL(response.ava.url) : undefined,
		firstName: response.firstName,
		lastName: response.lastName,
		fullName:
			(response.firstName && response.lastName
				? `${response.firstName} ${response.lastName}`
				: response.firstName || response.lastName) || undefined,
	}))

export const UserSchema: z.ZodSchema<User, z.ZodTypeDef, unknown> = z.union([
	UserNotOnboardedSchema,
	UserOnboardedSchema,
])

export const WaitlistSchema: z.ZodSchema<{ email: string; updateToken: string }, z.ZodTypeDef, unknown> = z.object({
	email: z.string().email(),
	updateToken: z.string(),
})

export const WaitlistsSchema: z.ZodSchema<Waitlists[], z.ZodTypeDef, unknown> = z
	.array(z.object({ name: z.enum(['TEAM_PLAN']) }))
	.transform(response => response.map(r => r.name))
