import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import * as Icons from 'lucide-react'

import { PricingPlanTile } from '@prostpost/uikit'

import { UserFeedback } from 'app/modals/UserFeedback'
import { useCurrency } from 'app/domains/PricingPlan/hooks'
import { useChannelsStore } from 'app/domains/Channel/store'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { useSubscriptionExpired } from 'app/domains/PricingPlan/hooks'
import { SubscribeAction } from 'app/domains/PricingPlan/features/SubscribeAction'
import type { PricingPlanMonth } from 'app/domains/PricingPlan'

type Props = { plan: PricingPlanMonth }

export const MonthlyPlanTile = observer(({ plan }: Props) => {
	const { t } = useTranslation()

	const { preferences } = useOnboardedUserStore()
	const { sign: currencySign } = useCurrency('EUR')
	const { activeList: channels } = useChannelsStore()

	const isExpired = useSubscriptionExpired()

	const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)

	return (
		<>
			{preferences.plan.name === 'MONTH' && (
				<UserFeedback
					isOpen={isFeedbackModalOpen}
					onClose={() => setIsFeedbackModalOpen(false)}
					categories={['question', 'bug', 'review', 'feature_request']}
					defaultCategory="question"
				/>
			)}

			<PricingPlanTile w="100%" variant={preferences.plan.name === 'MONTH' && !isExpired ? 'active' : 'normal'}>
				<PricingPlanTile.Title>{t('userSettings.plans.monthlyTitle', 'Monthly')}</PricingPlanTile.Title>
				<PricingPlanTile.Price
					period={t('userSettings.plans.periodMonth', 'month')}
					currencySign={currencySign}
					value={plan.price}
					hint={
						plan.limits.channelPrice
							? t('userSettings.plans.monthlyHint2', '+ {{currencySign}}{{count}} per extra channel', {
									count: plan.limits.channelPrice,
									currencySign,
								})
							: undefined
					}
				/>
				<PricingPlanTile.Features>
					<PricingPlanTile.Feature
						variant="iconed"
						icon={Icons.Check}
						iconColor={channels.length === plan.limits.channels ? 'green_100' : 'blue_40'}
					>
						{t('userSettings.plans.channelFeature3', '{{channels}}/{{count}} Channels', {
							channels: channels.length,
							count: plan.limits.channels,
						})}
					</PricingPlanTile.Feature>
					{plan.limits.posts < 9999 ? (
						<PricingPlanTile.Feature variant="iconed" icon={Icons.Calendar} iconColor="blue_40">
							{t('userSettings.plans.postsFeature2', '{{count}} Posts per month', {
								count: plan.limits.posts,
							})}
						</PricingPlanTile.Feature>
					) : (
						<PricingPlanTile.Feature variant="iconed" icon={Icons.Calendar} iconColor="blue_40">
							{t('userSettings.plans.postsFeatureUnlimited', 'Unlimited posts')}
						</PricingPlanTile.Feature>
					)}
					<PricingPlanTile.Feature variant="iconed" icon={Icons.BarChart2} iconColor="blue_40">
						{t('userSettings.plans.analyticsFeature', 'Advanced analytics')}
					</PricingPlanTile.Feature>
				</PricingPlanTile.Features>
				<PricingPlanTile.Side>
					<SubscribeAction planToActivate="MONTH" />
				</PricingPlanTile.Side>
			</PricingPlanTile>
		</>
	)
})
