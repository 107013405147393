import { toJS, runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { useToast } from '@prostpost/toast'
import { Uploader } from '@prostpost/uploader'
import { notReachable } from '@prostpost/utils'
import { Button, Flex } from '@prostpost/uikit'

import { useUserAnalyticsEvents } from 'app/config/analytics/events/user'

import { useUpdateUser } from 'app/domains/User/api'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { uploadImages, useRemoveUploadedImagesByUrls } from 'app/domains/Media/api'
import type { UploadImageError } from 'app/domains/Media/api'

const useToasts = () => {
	const { t } = useTranslation()

	const uploadFailed = useToast({
		type: 'error',
		text: t('userSettings.ava.updateFailed', 'Unable to update your account image'),
	})

	const invalidFileExt = useToast({
		type: 'error',
		text: t('userSettings.ava.updateFailed.invalidFileExt', 'Please use PNG or JPG files for your account image'),
	})

	const fileSizeExceeded = useToast({
		type: 'error',
		text: t('userSettings.ava.updateFailed.fileSizeExceeded', 'Maximum size of an image exceeded (up to 10 MB)'),
	})

	const removeImageFailed = useToast({
		type: 'error',
		text: t('userSettings.ava.removeFailed', 'Unable to remove your account image'),
	})

	const updateUserFailed = useToast({
		type: 'error',
		text: t('userSettings.ava.updateFailed', 'Unable to update your account image'),
	})

	const updateUserSucceed = useToast({
		type: 'success',
		text: t('userSettings.ava.updateSucceed', 'Your account image was updated successfully'),
	})

	return { uploadFailed, invalidFileExt, fileSizeExceeded, removeImageFailed, updateUserFailed, updateUserSucceed }
}

export const UserAvaUploader = observer(function UserAvaUploader({
	...flexProps
}: Omit<React.ComponentProps<typeof Flex>, 'width' | 'align' | 'dir'>) {
	const { t } = useTranslation()

	const toasts = useToasts()
	const user = useOnboardedUserStore()
	const userEvents = useUserAnalyticsEvents()

	const controller = new AbortController()
	const [isImageUploading, setIsImageUploading] = useState(false)

	const { updateUserMutation } = useUpdateUser()
	const { removeUploadedImagesMutation } = useRemoveUploadedImagesByUrls()

	useEffect(() => {
		switch (removeUploadedImagesMutation.status) {
			case 'idle':
			case 'loading':
			case 'success': // notification for success raise after user data is updated
				break

			case 'error':
				switch (removeUploadedImagesMutation.error.code) {
					case 'UNKNOWN_ERROR':
					case 'NOT_VALID_ERROR':
					case 'FILES_WERE_NOT_REMOVED':
					case 'INVALID_MEDIA_URLS_TO_REMOVE':
						toasts.removeImageFailed.show()
						break
					default:
						notReachable(removeUploadedImagesMutation.error.code)
				}
				break

			default:
				notReachable(removeUploadedImagesMutation)
		}
	}, [removeUploadedImagesMutation.status])

	useEffect(() => {
		switch (updateUserMutation.status) {
			case 'idle':
			case 'loading':
				break

			case 'success':
				runInAction(() => {
					user.setCurrent({
						...toJS(user.getUser()),
						preferences: user.preferences,
						ava: updateUserMutation.data.ava,
					})
				})

				toasts.updateUserSucceed.show()
				break

			case 'error':
				switch (updateUserMutation.error.code) {
					case 'UNKNOWN_ERROR':
					case 'NOT_VALID_ERROR':
					case 'EMAIL_ALREADY_IN_USE':
						toasts.updateUserFailed.show()
						break
					default:
						notReachable(updateUserMutation.error.code)
				}
				break

			default:
				notReachable(updateUserMutation)
		}
	}, [updateUserMutation.status])

	const isLoading =
		isImageUploading || [updateUserMutation.status, removeUploadedImagesMutation.status].includes('loading')

	return (
		<Flex {...flexProps} w="fit-content" dir="column" align="center" mb={5}>
			<Uploader
				size="big"
				crop="disabled"
				variant="rounded"
				maxImagesAllowed={1}
				isUploadDisabled={false}
				openUploadWindowOnMount={false}
				images={user.ava ? [user.ava] : []}
				remove={urls => {
					userEvents.clicks.avaRemove()
					return removeUploadedImagesMutation
						.mutateAsync({ urls })
						.then(() => updateUserMutation.mutate({ ava: undefined }))
				}}
				upload={(formData, axiosConfig) => {
					setIsImageUploading(true)
					userEvents.clicks.avaUpload()
					return uploadImages({ formData, axiosConfig }, controller.signal)
						.then(urls => {
							updateUserMutation.mutate({ ava: urls[0] })
							setIsImageUploading(false)
							return urls
						})
						.catch((error: UploadImageError) => {
							setIsImageUploading(false)
							switch (error.code) {
								case 'UNKNOWN_ERROR':
								case 'NOT_VALID_ERROR':
								case 'INVALID_MEDIA_LOCATION':
								case 'CAN_NOT_MAKE_FILE_PUBLIC_CDN':
									toasts.uploadFailed.show()
									break
								case 'INVALID_FILE_EXTENSION':
									toasts.invalidFileExt.show()
									break
								case 'MAXIMUM_FILE_SIZE_EXCEEDED':
									toasts.fileSizeExceeded.show()
									break
								default:
									notReachable(error.code)
							}
							return []
						})
				}}
			>
				{({ openUploadWindow }) => (
					<Button.Action
						mt={3}
						size="mid"
						variant="secondary"
						state={isLoading ? 'pending' : 'normal'}
						icon={<Icons.UploadCloud size={16} strokeWidth={2} />}
						onClick={() => {
							openUploadWindow()
						}}
					>
						{t('labels.upload', 'Upload')}
					</Button.Action>
				)}
			</Uploader>
		</Flex>
	)
})
