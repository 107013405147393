import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { Nav, Button, Modal, Null } from '@prostpost/uikit'

import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { useUserAnalyticsEvents } from 'app/config/analytics/events/user'

import type { Tabs } from '../../types'

type Props = { currentTab: Tabs; onClose: () => void; setCurrentTab: (tab: Tabs) => void }

export const Header = observer(({ currentTab, setCurrentTab, onClose }: Props) => {
	const { t } = useTranslation()
	const userEvents = useUserAnalyticsEvents()
	const { preferences } = useOnboardedUserStore()

	const getNavButtonProps = useCallback(
		(tab: Tabs) => ({
			state: currentTab === tab ? ('active' as const) : ('normal' as const),
			onClick: () => {
				userEvents.clicks.accountModalTab(tab)
				setCurrentTab(tab)
			},
		}),
		[currentTab],
	)

	return (
		<Modal.Header>
			<Modal.Title>{t('user.settings.title', 'Settings')}</Modal.Title>
			<Nav variant="dimmed">
				<Nav.Item variant="plain">
					<Button.Nav {...getNavButtonProps('account')}>
						{t('userSettings.nav.account', 'Account')}
					</Button.Nav>
				</Nav.Item>
				<Nav.Item variant="plain">
					<Button.Nav {...getNavButtonProps('preferences')}>
						{t('userSettings.nav.preferences', 'Preferences')}
					</Button.Nav>
				</Nav.Item>
				<Nav.Item variant="plain">
					<Button.Nav {...getNavButtonProps('plan')}>{t('userSettings.nav.plan', 'Plan')}</Button.Nav>
				</Nav.Item>
				{preferences.plan.name === 'TRIAL' ? (
					<Null />
				) : (
					<Nav.Item variant="plain">
						<Button.Nav {...getNavButtonProps('usage')}>{t('userSettings.nav.usage', 'Usage')}</Button.Nav>
					</Nav.Item>
				)}
			</Nav>
			<Modal.Close onClick={onClose} />
		</Modal.Header>
	)
})
