import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import type { PropsWithChildren } from 'react'

import { canUseDOM } from '@prostpost/utils'
import { useCurrentMedia } from '@prostpost/css'
import { Button, Nav, Flex, Box } from '@prostpost/uikit'

import { useIsUserSignedIn, useSignout } from 'app/domains/User/hooks'
import { useAuthSignInAnalyticEvents, useAuthSignUpAnalyticEvents } from 'app/config/analytics/events/auth'
import { useInboxAnalyticsEvents } from 'app/config/analytics/events/inbox'
import { useOtherAnalyticsEvents } from 'app/config/analytics/events/other'

import { config } from 'app/config'
import { routesUrls } from 'app/routes/urls'
import { ProstpostLogo } from 'app/shared/components'

export const Header = ({ children }: PropsWithChildren) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const isMobile = useCurrentMedia() === 'MOBILE'
	const isUserSignedIn = useIsUserSignedIn()

	const inboxEvents = useInboxAnalyticsEvents()
	const otherEvents = useOtherAnalyticsEvents()
	const authSigninEvents = useAuthSignInAnalyticEvents()
	const authSignupEvents = useAuthSignUpAnalyticEvents()

	const { signOut } = useSignout({ redirectTo: routesUrls.auth.signin })

	return (
		<Flex gap={6} as="header" align="center" just="space-between" py={isMobile ? 5 : 6} px={isMobile ? 5 : 9}>
			<ProstpostLogo
				mt={-3}
				variant="dark"
				onClick={() => {
					otherEvents.clicks.logoLanding()
					navigate(routesUrls.landing)
				}}
			/>

			<Box mr="auto">{children}</Box>

			{isUserSignedIn ? (
				<Nav variant="light">
					<Nav.Item variant="nav">
						<Button.Nav title={t('pp.nav.logout', 'Log out')} onClick={signOut} />
					</Nav.Item>
					<Nav.Item variant="cta" mediaHideOnRes="mobile">
						<Button.Nav
							title={t('pp.nav.goToApp', 'Continue to Inbox')}
							onClick={() => {
								inboxEvents.clicks.inbox()
								canUseDOM(() => window.location.assign(`${config.constants.DOMAIN}${routesUrls.inbox}`))
							}}
						/>
					</Nav.Item>
				</Nav>
			) : (
				<Nav>
					<Nav.Item variant="nav">
						<Button.Nav
							to={routesUrls.auth.signin}
							title={t('pp.nav.signin', 'Sign in')}
							onClick={authSigninEvents.clicks.toSignin}
						/>
					</Nav.Item>
					<Nav.Item variant="cta" mediaHideOnRes="mobile">
						<Button.Nav
							to={routesUrls.auth.signup}
							title={t('pp.nav.getStarted', 'Get Started')}
							onClick={authSignupEvents.clicks.toSignup}
						/>
					</Nav.Item>
				</Nav>
			)}
		</Flex>
	)
}
