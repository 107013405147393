import { HStack } from '@prostpost/uikit'

import './style.css'

type Props = {
	height?: string
}

export const Spinner = ({ height = '100dvh' }: Props) => {
	return (
		<HStack space={4} just="center" align="center" w="100%" h={height}>
			<div className="spinner-2y3be"></div>
		</HStack>
	)
}
