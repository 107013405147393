import { observer } from 'mobx-react-lite'
import { motion, AnimatePresence } from 'framer-motion'
import type { MotionProps } from 'framer-motion'

import { notReachable } from '@prostpost/utils'
import { Main, VStack, Box } from '@prostpost/uikit'

import { useShowTopSticker } from 'app/shared/hooks'
import { HEADER_HEIGHT, HEADER_HEIGHT_WITH_STICKER } from 'app/shared/constants'

const animation: MotionProps = {
	layout: true,
	exit: 'collapsed',
	initial: 'collapsed',
	transition: { ease: 'easeInOut', duration: 0.4 },
	style: { height: '100%' },
	variants: { open: { marginRight: 0 }, collapsed: { marginRight: -432 } },
}

type Props = { isOpen: boolean; variant: 'CHANNEL' | 'NO_CHANNEL'; children: React.ReactNode | React.ReactNode[] }

export const StaticAside = observer((props: Props) => {
	const { topSticker } = useShowTopSticker()
	return (
		<AnimatePresence initial={false}>
			<motion.div {...animation} animate={props.isOpen ? 'open' : 'collapsed'}>
				<Main.Aside
					withHeader
					headerHeight={topSticker.visible !== 'NONE' ? HEADER_HEIGHT_WITH_STICKER : HEADER_HEIGHT}
					variant="static"
					borderColor="blue_20_opaque"
				>
					<Main.AsideContent>
						{(() => {
							switch (props.variant) {
								case 'CHANNEL':
									return (
										<Box w="100%" h="100%" pt={4}>
											{props.children}
										</Box>
									)
								case 'NO_CHANNEL':
									return (
										<VStack space={4} w="100%" pt={4}>
											{props.children}
										</VStack>
									)
								default:
									return notReachable(props.variant)
							}
						})()}
					</Main.AsideContent>
				</Main.Aside>
			</motion.div>
		</AnimatePresence>
	)
})
