import { useState, useEffect } from 'react'

import { useSupabaseAuth } from 'app/domains/User/hooks'

type UserData = {
	id: string
	email?: string
}

type Args = {
	onUserSignedIn?: (user: UserData) => void
}

export const useIsUserSignedIn = ({ onUserSignedIn }: Args = {}) => {
	const [isSignedIn, setIsSignedIn] = useState<boolean | null>(null)
	const auth = useSupabaseAuth()

	useEffect(() => {
		auth.getSession()
			.then(response => {
				if (response.data.session && !response.error) {
					setIsSignedIn(true)
					onUserSignedIn?.({
						id: response.data.session.user.id,
						email: response.data.session.user.email,
					})
				} else {
					setIsSignedIn(false)
				}
				return null
			})
			.catch((error: unknown) => {
				console.error(error)
				setIsSignedIn(false)
			})
	}, [])

	return isSignedIn
}
