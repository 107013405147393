import styled from '@emotion/styled'
import type { PropsWithChildren, ComponentProps } from 'react'

import { Flex } from '@prostpost/uikit'
import { useCurrentMedia } from '@prostpost/css'

type FlexProps = ComponentProps<typeof Flex>

const RightAfter = styled(Flex)`
	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: -9999px;

		width: 9999px;
		height: 100%;

		background: ${({ theme }) => theme.colors.blue_10};
	}
`

export const Right = ({ children, ...flexProps }: PropsWithChildren<Partial<FlexProps>>) => {
	const MEDIA = useCurrentMedia()
	const isDesktop = MEDIA === 'DESKTOP_WIDE' || MEDIA === 'DESKTOP_NARROW'
	return (
		<RightAfter
			py={5}
			px={7}
			w="40%"
			as="aside"
			dir="column"
			align="center"
			minH="100dvh"
			bg="blue_10"
			just="center"
			position="relative"
			visibility={isDesktop ? 'visible' : 'hidden'}
			{...flexProps}
		>
			{children}
		</RightAfter>
	)
}
