import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import * as Icons from 'lucide-react'

import { Group } from '@prostpost/uikit'

import { PricingPlans } from 'app/domains/PricingPlan/features/PricingPlans'

export const PlansGroup = () => {
	const theme = useTheme()
	const { t } = useTranslation()

	return (
		<Group>
			<Group.Title title={t('userSettings.plans.title', 'Plans')}>
				<Icons.Crown size={16} strokeWidth={2.5} color={theme.colors.black_80} />
			</Group.Title>
			<PricingPlans variant="tiles-group" />
		</Group>
	)
}
