import { useLocation } from 'react-router-dom'

import { trackEvent } from '../track'

export const useFeedbackAnalyticsEvents = () => {
	const ref = useLocation().pathname
	const trackClick = (e: string, params?: UmamiData) => trackEvent('feedback', ref, 'click', e, params)
	return {
		clicks: {
			open: () => trackClick('feedback'),
			submit: () => trackClick('submit'),
			rating: (stars: number) => trackClick('rating', { stars }),
			modalClosed: () => trackClick('modal_closed'),
			categorySelect: (category: string) => trackClick('category_select', { category }),
			subcategorySelect: (category: string) => trackClick('subcategory_select', { category }),
		},
	}
}
