import { useEffect, useState, useRef } from 'react'

import { notReachable } from '@prostpost/utils'

import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { useUpdateUserPreferences as useUpdateUserPreferencesApi } from 'app/domains/UserPreferences/api'
import type { UpdatePreferencesData, AdditionalPreferences } from 'app/domains/UserPreferences/api'

const getCurrentRequestVariables = (
	mutation: ReturnType<typeof useUpdateUserPreferencesApi>['updateUserPreferencesMutation'],
) => {
	const loadingVariables: Array<keyof UpdatePreferencesData | keyof AdditionalPreferences> = []
	if (mutation.variables) {
		for (const variable in mutation.variables) {
			loadingVariables.push(variable as keyof UpdatePreferencesData)
		}
	}
	return loadingVariables
}

export const useUpdateUserPreferences = () => {
	const timer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)

	const { preferences } = useOnboardedUserStore()

	const { updateUserPreferencesMutation } = useUpdateUserPreferencesApi()
	const [loadingState, setLoadingState] = useState<
		Array<keyof UpdatePreferencesData | keyof AdditionalPreferences> | []
	>([])

	useEffect(() => {
		switch (updateUserPreferencesMutation.status) {
			case 'idle':
			case 'loading':
				break
			case 'success':
				preferences.setPreferences(updateUserPreferencesMutation.data)
				break
			case 'error':
				switch (updateUserPreferencesMutation.error.code) {
					case 'UNKNOWN_ERROR':
					case 'NOT_VALID_ERROR':
					case 'UNABLE_TO_UPDATE_PREFERENCES':
						break
					default:
						notReachable(updateUserPreferencesMutation.error.code)
				}
				break
			default:
				notReachable(updateUserPreferencesMutation)
		}
	}, [updateUserPreferencesMutation.status])

	useEffect(() => {
		switch (updateUserPreferencesMutation.status) {
			case 'idle':
				break
			case 'loading':
				timer.current = setTimeout(() => {
					setLoadingState(getCurrentRequestVariables(updateUserPreferencesMutation))
				}, 300)
				break
			case 'error':
			case 'success':
				if (timer.current) clearTimeout(timer.current)
				setLoadingState(loadingState =>
					loadingState.filter(x => !getCurrentRequestVariables(updateUserPreferencesMutation).includes(x)),
				)
				break
			default:
				notReachable(updateUserPreferencesMutation)
		}
	}, [updateUserPreferencesMutation.isLoading])

	return { mutate: updateUserPreferencesMutation.mutate, loadingState }
}
