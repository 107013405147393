import i18next from 'i18next'
import { useMemo, useEffect } from 'react'
import { wrapUseRoutesV7 } from '@sentry/react'
import { useRoutes, useNavigate } from 'react-router-dom'
import type { i18n as I18n } from 'i18next'

import { notReachable } from '@prostpost/utils'

import { useUserLoader, useAuthUser } from 'app/domains/User/hooks'
import { useGetBotLink } from 'app/domains/Bot/hooks'
import { useFeaturesLoader } from 'app/domains/Feature/hooks'
import { useActiveChannelsLoader } from 'app/domains/Channel/hooks'
import { useUserPreferencesLoader } from 'app/domains/UserPreferences/hooks'
import { StoreFeatures, FeaturesStoreContext } from 'app/domains/Feature/store'
import { StoreChannels, ChannelsStoreContext } from 'app/domains/Channel/store'
import { StorePosts, PostsStoreContext } from 'app/domains/Post/store'
import { StoreBot, BotStoreContext } from 'app/domains/Bot/store'
import {
	OnboardedUserStore,
	NotOnboardedUserStore,
	OnboardedUserStoreContext,
	NotOnboardedUserStoreContext,
} from 'app/domains/User/store/slices'

import { Loadings } from 'app/routes/Loadings'
import { WithWebsockets } from 'app/routes/Wrappers'
import { useAnalyticsIdentity } from 'app/config/analytics'
import { config } from 'app/config'

import { routesUrls } from './urls'
import { onboardedUserRoutesConfig, notOnboardedUserRoutesConfig, authRoutesConfig, publicRoutesConfig } from './config'

const useSentryRoutes = wrapUseRoutesV7(useRoutes)

type Stores = {
	bot: StoreBot
	features: StoreFeatures
	channels: StoreChannels
	posts: StorePosts
	user: {
		onboarded?: OnboardedUserStore
		notOnboarded?: NotOnboardedUserStore
	}
}

export const AuthRoutesDataManager = () => {
	return useSentryRoutes([...authRoutesConfig, ...publicRoutesConfig])
}

export const RoutesDataManager = ({ i18n = i18next }: { i18n: I18n }) => {
	const navigate = useNavigate()
	const supabaseUser = useAuthUser()

	const OnboardedUserRoutes = useSentryRoutes([...onboardedUserRoutesConfig, ...publicRoutesConfig])
	const NotOnboardedUserRoutes = useSentryRoutes([...notOnboardedUserRoutesConfig, ...publicRoutesConfig])

	const { data: userData } = useUserLoader({ throwError: true })
	const featuresData = useFeaturesLoader()
	const botLinkStatus = useGetBotLink()
	const userPreferencesData = useUserPreferencesLoader({ i18n, throwError: true })
	const activeChannels = useActiveChannelsLoader()

	const requiredDataIsLoaded =
		userData &&
		userPreferencesData &&
		botLinkStatus !== undefined &&
		activeChannels &&
		!supabaseUser.isUserLoading &&
		!!featuresData

	// Bind tracking events to user UUID
	useAnalyticsIdentity(
		userData && userPreferencesData?.more.personalizedTracking
			? { isAllowed: true, userId: userData.uuid }
			: { isAllowed: false },
	)

	// Disable tracking for dev users
	useEffect(() => {
		if (userPreferencesData?.more.disableTracking === false) {
			window.__dev__toggle_umami(false)
		}
	}, [userPreferencesData?.more.disableTracking])

	useEffect(() => {
		if (supabaseUser.user) {
			config.localStorage.removeItem('isSigningIn')
		}
	}, [supabaseUser.user])

	const stores: Stores | undefined = useMemo(() => {
		if (requiredDataIsLoaded && supabaseUser.user) {
			const stores = {
				bot: new StoreBot(botLinkStatus),
				features: new StoreFeatures({ features: featuresData }),
				channels: new StoreChannels({
					activeChannels,
					callbacks: {
						// so doesn't matter where and what we call archive channel, it will always navigate to inbox
						onArchiveChannel: () => {
							navigate(routesUrls.inbox)
						},
					},
				}),
				posts: new StorePosts({
					monthlyPostsLimit: userPreferencesData.limits.posts,
					monthlyPostsQuantity: userPreferencesData.limits.monthlyPublishedPosts,
				}),
			}

			switch (userData.type) {
				case 'ONBOARDED':
					return {
						...stores,
						user: {
							onboarded: new OnboardedUserStore({
								...userData,
								supabaseUser: supabaseUser.user,
								preferences: userPreferencesData,
							}),
						},
					}
				case 'NOT_ONBOARDED':
					return {
						...stores,
						user: {
							notOnboarded: new NotOnboardedUserStore({
								...userData,
								supabaseUser: supabaseUser.user,
								preferences: userPreferencesData,
							}),
						},
					}
				default:
					return notReachable(userData)
			}
		}
		return undefined
	}, [requiredDataIsLoaded])

	if (!requiredDataIsLoaded) {
		return <Loadings.Spinner />
	}

	return (
		<FeaturesStoreContext.Provider value={stores?.features}>
			<BotStoreContext.Provider value={stores?.bot}>
				<ChannelsStoreContext.Provider value={stores?.channels}>
					<PostsStoreContext.Provider value={stores?.posts}>
						{(() => {
							switch (userData.type) {
								case 'ONBOARDED':
									return (
										<OnboardedUserStoreContext.Provider value={stores?.user.onboarded}>
											<WithWebsockets>{OnboardedUserRoutes}</WithWebsockets>
										</OnboardedUserStoreContext.Provider>
									)

								case 'NOT_ONBOARDED':
									return (
										<NotOnboardedUserStoreContext.Provider value={stores?.user.notOnboarded}>
											{NotOnboardedUserRoutes}
										</NotOnboardedUserStoreContext.Provider>
									)

								default:
									return notReachable(userData)
							}
						})()}
					</PostsStoreContext.Provider>
				</ChannelsStoreContext.Provider>
			</BotStoreContext.Provider>
		</FeaturesStoreContext.Provider>
	)
}
