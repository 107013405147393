import { useMutation } from 'react-query'
import type { UseMutationOptions } from 'react-query'
import type { AxiosError } from 'axios'

import { parseFirstError } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, ParsedError, JsonApiResponseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

type Data = { variant?: 'monthly' | 'annual'; extraChannels: number }

const knownErrors = [] as const

type Response = null

const modifyStripeSubscription = (data: Data): Promise<null> =>
	axiosInstance
		.patch<JsonApiResponseSingle>('/auth/modify-subscription', data)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(() => null)

export const useStripeSubscriptionModify = (
	options?: UseMutationOptions<Response, ParsedError<(typeof knownErrors)[number]>, Data>,
) => {
	return {
		modifyStripeSubscriptionMutation: useMutation<Response, ParsedError<(typeof knownErrors)[number]>, Data>(
			modifyStripeSubscription,
			options,
		),
	}
}
