import { init as initMobx } from './mobx'
import { init as initQuery } from './query'
import { init as initModals } from './modals'
import { init as initSentry } from './sentry'
import { init as initCaptcha } from './captcha'
import { init as initEmotion } from './emotion'
import { init as supabaseInit } from './supabase'
import { init as initLocalisation } from './localisation'
import { axiosInstance } from './network'
import { cookiesTyped } from './cookies'
import { localStorageTyped } from './localstorage'
import * as constants from './constants'

export type { RouteObjectWithDataLoader as SSRRouteObjectWithDataLoader } from './ssr/express'

type ConfigArgs = {
	DOMAIN: string
}

const initConfig = (opts: ConfigArgs) => {
	initMobx()
	initSentry()
	initLocalisation()

	return {
		cookies: cookiesTyped,
		localStorage: localStorageTyped,
		constants: {
			...constants,
			DOMAIN: opts.DOMAIN,
		},
		captcha: initCaptcha(),
		supabase: supabaseInit(),
		query: initQuery(),
		modals: initModals(),
		emotion: initEmotion(),
		network: { proxy: axiosInstance },
	}
}

// TODO: Pass here values from env
export const config = initConfig({
	DOMAIN: import.meta.env.VITE_DOMAIN,
})

export const Z_INDEXES = {
	EDITOR_BLURRED_OVERLAY: 2,
}
