import { useEffect } from 'react'

type Args =
	| {
			isAllowed: true
			userId: string
			channel?: string | null
	  }
	| {
			isAllowed: false
			userId?: never
			channel?: never
	  }

export const useAnalyticsIdentity = ({ isAllowed, userId, channel }: Args) => {
	useEffect(() => {
		if (!window.umami?.identify) return
		if (isAllowed) {
			window.umami?.identify({
				id: userId,
				channel: channel ?? 'none',
			})
		} else {
			window.umami?.identify({})
		}
	}, [userId, channel, isAllowed, window.umami?.identify])
}

export const useAnalytics = () => ({
	trackEvent: (eventName: string, params?: UmamiData, options?: UmamiOptions) => {
		window.umami?.track(eventName, params, options)
	},
})
