import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'

const CODE_FIELD_NAME = 'code'

type FormData = {
	code: string
}

export const useBotLinkForm = ({ onSubmit }: { onSubmit: (fd: FormData) => void }) => {
	const { t } = useTranslation()

	const formSchema: z.ZodSchema<FormData> = z.object({
		code: z
			.string()
			.min(1, t('notify:bot.botLink.codeIsRequired.error', 'Code is required'))
			.regex(/\b\d{6}\b/g, t('notify:bot.botLink.codeFormatInvalid.error', 'Code must contain 6 digits only')),
	})

	const {
		control,
		register,
		setError,
		setValue,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		reValidateMode: 'onSubmit',
		resolver: (values, context, options) => {
			const code = values.code.replace(' - ', '')
			return zodResolver(formSchema)({ ...values, code }, context, options)
		},
		defaultValues: {
			code: '',
		},
	})

	return {
		control,
		register,
		setValue,
		onSubmit: handleSubmit(onSubmit),
		fields: {
			code: {
				error: errors.code?.message,
				setError: (message: string) => setError(CODE_FIELD_NAME, { message }),
				inputProps: {
					mask: { mask: '000{ - }000' },
					placeholder: t('content:bot.botLink.form.codePlaceholder', '000 - 000'),
					onChange: (value: string) => {
						clearErrors(CODE_FIELD_NAME)
						setValue(CODE_FIELD_NAME, value)
					},
				},
			},
		},
	}
}
