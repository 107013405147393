import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from '@prostpost/toast'
import { notReachable } from '@prostpost/utils'
import { Modal, Heading, Paragraph } from '@prostpost/uikit'

import { useUserAnalyticsEvents } from 'app/config/analytics/events/user'
import { useDeleteUser } from 'app/domains/User/api'

type Msg = { type: 'on_success' } | { type: 'on_close' }
type Props = {
	isOpen: boolean
	onMsg: (msg: Msg) => void
}

const useToasts = () => {
	const { t } = useTranslation()

	const deleteAccountFailed = useToast({
		type: 'error',
		text: t('notify:auth.removeAccount.error', 'Unable to delete account. Please try again'),
	})

	const deleteAccountSucceed = useToast({
		type: 'success',
		text: t('notify:auth.removeAccount.success', 'Account was successfully deleted'),
	})

	return {
		deleteAccountSucceed,
		deleteAccountFailed,
	}
}

export const DeleteAccount = ({ isOpen, onMsg }: Props) => {
	const toasts = useToasts()
	const { t } = useTranslation()
	const userEvents = useUserAnalyticsEvents()

	const { deleteUserMutation } = useDeleteUser()

	useEffect(() => {
		switch (deleteUserMutation.status) {
			case 'idle':
			case 'loading':
				break
			case 'success':
				toasts.deleteAccountSucceed.show()
				onMsg({ type: 'on_success' })
				break
			case 'error':
				toasts.deleteAccountFailed.show()
				break
			default:
				notReachable(deleteUserMutation)
		}
	}, [deleteUserMutation.status])

	return isOpen ? (
		<Modal variant="confirm">
			<Modal.Close
				onClick={() => {
					userEvents.clicks.deleteAccountCancel()
					onMsg({ type: 'on_close' })
				}}
			/>
			<Modal.Content>
				<Heading h={4}>{t('userSettings.security.deleteAccountTitle', 'Are you sure?')}</Heading>
				<Paragraph my={4} size={14} color="blue_40">
					{t(
						'userSettings.security.deleteAccountDescription',
						'This action is permanent and cannot be undone. Deleting your account will permanently remove all your personal data, including settings, preferences, and any associated content. Once deleted, your account and data cannot be recovered.',
					)}
				</Paragraph>
			</Modal.Content>
			<Modal.ConfirmSecondaryAction
				onClick={() => {
					userEvents.clicks.deleteAccountCancel()
					onMsg({ type: 'on_close' })
				}}
			>
				{t('channel.settings.archiveConfirm.secondaryAction', 'Cancel')}
			</Modal.ConfirmSecondaryAction>
			<Modal.ConfirmPrimaryAction
				variant="danger"
				state={deleteUserMutation.isLoading ? 'pending' : 'normal'}
				onClick={() => {
					userEvents.clicks.deleteAccountSubmit()
					deleteUserMutation.mutate()
				}}
			>
				{t('userSettings.danger.deleteAccountAction', 'Delete account')}
			</Modal.ConfirmPrimaryAction>
		</Modal>
	) : null
}
