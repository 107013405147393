import { useLocation } from 'react-router-dom'

import { trackEvent } from '../track'

export const usePlansAnalyticsEvents = () => {
	const ref = useLocation().pathname
	const trackClick = (e: string, params?: UmamiData) => trackEvent('plan', ref, 'click', e, params)
	return {
		clicks: {
			// Plan tab
			upgradeNow: (plan: string) => trackClick('plan_upgrade_now', { plan }),
			// Trial
			trialBannerUpgradeNow: () => trackClick('trial_banner_upgrade_now'),
			trialBannerClosed: () => trackClick('trial_banner_closed'),
			// Manage
			manageSubscription: () => trackClick('manage_subscription'),
			cancelSubscription: () => trackClick('cancel_subscription'),
			renewSubscription: () => trackClick('renew_subscription'),
			extendPlanLimits: (plan: string) => trackClick('extend_plan_limits', { plan }),
			annualWarningModalClosed: () => trackClick('annual_warning_modal_closed'),
			annualWarningModalConfirm: () => trackClick('annual_warning_modal_confirm'),
			// Additional channels
			moreChannelsModalClosed: () => trackClick('more_channels_modal_closed'),
			moreChannelsModalConfirm: () => trackClick('more_channels_modal_confirm'),
			switchToAnnualOnAddingChannels: () => trackClick('switch_to_annual_on_adding_channels'),
			contactUsForHigherLimits: () => trackClick('contact_us_for_higher_limits'),
			chooseAdditionalChannels: (action: 'inc' | 'dec') => trackClick('choose_additional_channels', { action }),
		},
	}
}
