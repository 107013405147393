import { useLocation } from 'react-router-dom'

import { trackEvent } from '../track'

export const useUserAnalyticsEvents = () => {
	const ref = useLocation().pathname
	const trackClick = (e: string, params?: UmamiData) => trackEvent('user', ref, 'click', e, params)
	const trackInput = (e: string, params?: UmamiData) => trackEvent('user', ref, 'input', e, params)
	return {
		fields: {
			firstNameChange: () => trackInput('first_name_change'),
			lastNameChange: () => trackInput('last_name_change'),
		},
		clicks: {
			// Navigation
			logout: (from: 'sidebar' | 'plans' | 'onboarding') => trackClick('logout', { from }),
			accountModal: () => trackClick('account_modal'),
			// Account modal nav
			accountModalTab: (tab: string) => trackClick('account_modal_tab', { tab }),
			accountModalClosed: () => trackClick('account_modal_closed'),
			// Ava
			avaUpload: () => trackClick('ava_upload'),
			avaRemove: () => trackClick('ava_remove'),
			// Email
			emailChange: () => trackClick('email_change'),
			emailChangeSubmit: () => trackClick('email_change_submit'),
			emailChangeCancel: () => trackClick('email_change_cancel'),
			// Password
			passwordChange: () => trackClick('password_change'),
			passwordChangeSubmit: () => trackClick('password_change_submit'),
			passwordChangeCancel: () => trackClick('password_change_cancel'),
			// Delete account
			deleteAccount: () => trackClick('delete_account'),
			deleteAccountSubmit: () => trackClick('delete_account_submit'),
			deleteAccountCancel: () => trackClick('delete_account_cancel'),
			// Preferences tab
			selectLanguage: (language: string) => trackClick('select_language', { language }),
			selectTimeZone: (timezone: string) => trackClick('select_timezone', { timezone }),
			timeFormat: (isAm: boolean) => trackClick('time_format', { isAm }),
			postsImagePreview: (isEnabled: boolean) => trackClick('posts_image_preview', { isEnabled }),
			supportedPosts: (isEnabled: boolean) => trackClick('supported_posts', { isEnabled }),
			personalizedTracking: (isEnabled: boolean) => trackClick('personalized_tracking', { isEnabled }),
			emailSubscription: (isEnabled: boolean) => trackClick('email_subscription', { isEnabled }),
		},
	}
}
