import moment from 'moment-timezone'
import { getTimeZones } from '@vvo/tzdb'

import { canUseDOM, SESSION_STORAGE_DATETIME_OFFSET, SESSION_STORAGE_DATETIME_USE_24 } from '@prostpost/utils'

// Get current user timezone based on browser
const timezones = getTimeZones()
const defaultTimezone = timezones.find(tz => tz.name === moment.tz.guess(true))?.name

export const updateSessionStorage = (timeZone: string | undefined, timeFormat24: boolean) => {
	const utcOffset = timezones.find(tz => tz.name === (timeZone || defaultTimezone))?.currentTimeOffsetInMinutes
	canUseDOM(() => {
		sessionStorage.setItem(SESSION_STORAGE_DATETIME_OFFSET, `${utcOffset || 0}`)
		sessionStorage.setItem(SESSION_STORAGE_DATETIME_USE_24, timeFormat24 ? '1' : '0')
	})
}
