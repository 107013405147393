import moment from 'moment'
import { useTranslation } from 'react-i18next'
import type { ComponentProps } from 'react'

import { useOpenWindow } from '@prostpost/utils'
import { useCurrentMedia } from '@prostpost/css'
import { Hr, HStack, Nav, Button, Text, Box } from '@prostpost/uikit'

import { routesUrls } from 'app/routes/urls'
import { ProstpostLogo } from 'app/shared/components'
import { useProstpostChannelLink } from 'app/domains/Telegram/hooks'

export const Footer = (props: ComponentProps<typeof Box>) => {
	const { t } = useTranslation()
	const openNewWindow = useOpenWindow()

	const MEDIA = useCurrentMedia()
	const isMobile = MEDIA === 'MOBILE'
	const isTablet = MEDIA === 'TABLET'

	return (
		<Box as="footer" {...props}>
			<Hr color="blue_20" />
			<HStack.Responsive
				py={6}
				space={4}
				responsive={{ MOBILE: { rule: 'full-width' }, TABLET: { rule: 'full-width' } }}
				align="center"
				just="space-between"
			>
				<ProstpostLogo variant="dark" />
				<Box ml={isTablet || isMobile ? -2 : 0}>
					<Nav variant="dimmed" mediaVerticalOnRes="not-desktop">
						<Nav.Item variant="plain">
							<Button.Nav to={routesUrls.contactUs} title={t('footer.contact', 'Contact us')} />
						</Nav.Item>
						<Nav.Item variant="plain">
							<Button.Nav
								title={t('footer.join', 'Join our channel')}
								onClick={() => openNewWindow(useProstpostChannelLink())}
							/>
						</Nav.Item>
						<Nav.Item variant="plain">
							<Button.Nav to={routesUrls.policy.pp} title={t('footer.terms', 'Terms and Privacy')} />
						</Nav.Item>
					</Nav>
				</Box>
				<Text color="blue_40" size={14}>{`©${moment.utc().year()} - Prostpost`}</Text>
			</HStack.Responsive>
		</Box>
	)
}
