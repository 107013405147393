import type { PropsWithChildren, ComponentProps } from 'react'

import { VStack } from '@prostpost/uikit'
import { useCurrentMedia } from '@prostpost/css'

export const Left = ({
	children,
	...vStackProps
}: PropsWithChildren & Omit<ComponentProps<typeof VStack>, 'space'>) => {
	const MEDIA = useCurrentMedia()
	const isTablet = MEDIA === 'TABLET'
	const isDesktopWide = MEDIA === 'DESKTOP_WIDE'
	const isDesktop = isDesktopWide || MEDIA === 'DESKTOP_NARROW'
	return (
		<VStack {...vStackProps} as="section" space={6} py={6} maxW={isDesktop || isTablet ? '700px' : '100%'}>
			{children}
		</VStack>
	)
}
