import { useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { useToast } from '@prostpost/toast'
import { notReachable } from '@prostpost/utils'
import { Text, Button, HStack, PricingPlanTile } from '@prostpost/uikit'

import { waitlists } from 'app/domains/User'
import { useCurrency } from 'app/domains/PricingPlan/hooks'
import { useGetWaitlists, useJoinWaitlistPrivate } from 'app/domains/User/api'
import { usePlansAnalyticsEvents } from 'app/config/analytics/events/plans'

const useToasts = () => {
	const { t } = useTranslation()

	const unableToJoinWaitlist = useToast({
		type: 'error',
		text: t('content:waitlist.actions.join.failed', 'Unable to join a waitlist'),
	})

	const joinedWaitlist = useToast({
		type: 'success',
		text: t('content:waitlist.actions.join.success', 'You were successfully added to waitlist'),
	})

	return { joinedWaitlist, unableToJoinWaitlist }
}

const Side = () => {
	const theme = useTheme()
	const toasts = useToasts()
	const { t } = useTranslation()

	const planEvents = usePlansAnalyticsEvents()

	const { waitlistsQuery } = useGetWaitlists()
	const { joinWaitlistMutation } = useJoinWaitlistPrivate()

	useEffect(() => {
		switch (joinWaitlistMutation.status) {
			case 'idle':
			case 'loading':
				break
			case 'success':
				toasts.joinedWaitlist.show()
				waitlistsQuery.refetch()
				break
			case 'error':
				toasts.unableToJoinWaitlist.show()
				break
			default:
				notReachable(joinWaitlistMutation)
		}
	}, [joinWaitlistMutation.status])

	switch (waitlistsQuery.status) {
		case 'idle':
		case 'loading':
			return (
				<HStack space={2} align="center">
					<Icons.Loader size={16} strokeWidth={2.5} color={theme.colors.black_80} />
					<Text size={14} color="black_80">
						{t('userSettings.plans.loading', 'Loading')}
					</Text>
				</HStack>
			)
		case 'error':
			return (
				<HStack space={2} align="center">
					<Icons.XCircle size={16} strokeWidth={2.5} color={theme.colors.black_80} />
					<Text size={14} color="black_80">
						{t('userSettings.plans.error', 'Error')}
					</Text>
				</HStack>
			)
		case 'success':
			if (waitlistsQuery.data.includes(waitlists.TEAM_PLAN)) {
				return (
					<HStack space={2} align="center">
						<Icons.Check size={16} strokeWidth={2.5} color={theme.colors.blue_100_on_black} />
						<Text size={14} color="blue_100_on_black">
							{t('userSettings.plans.waitlistJoined', 'Joined')}
						</Text>
					</HStack>
				)
			}

			return (
				<Button.Action
					size="mid"
					detail="inverted"
					variant="secondary"
					state={joinWaitlistMutation.isLoading ? 'pending' : 'normal'}
					onClick={() => {
						joinWaitlistMutation.mutate(null)
						planEvents.clicks.upgradeNow('TEAM')
					}}
				>
					{t('userSettings.plans.joinWaitlist', 'Join waitlist')}
				</Button.Action>
			)
		default:
			return notReachable(waitlistsQuery)
	}
}

export const TeamPlanTile = () => {
	const { t } = useTranslation()
	// Probably in future we will support more currencies and they will be added to BE plans
	// but now it's only USD now
	const { sign: currencySign } = useCurrency('USD')

	return (
		<PricingPlanTile w="100%" variant="disabled">
			<PricingPlanTile.Title>{t('userSettings.plans.proTitle', 'Team')}</PricingPlanTile.Title>
			<PricingPlanTile.Price currencySign={currencySign} hint={t('userSettings.plans.teamHint', 'Coming soon')} />
			<PricingPlanTile.Features>
				<PricingPlanTile.Feature variant="iconed" icon={Icons.Check}>
					{t('userSettings.plans.channelFeature3', 'More channel limits')}
				</PricingPlanTile.Feature>
				<PricingPlanTile.Feature variant="iconed" icon={Icons.Users} iconColor="blue_40">
					{t('userSettings.plans.teamFeature2', 'Shared workspace')}
				</PricingPlanTile.Feature>
			</PricingPlanTile.Features>
			<PricingPlanTile.Side>
				<Side />
			</PricingPlanTile.Side>
		</PricingPlanTile>
	)
}
