import React from 'react'
import * as Sentry from '@sentry/react'
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom'

const ERRORS_MAP_4XX = {
	400: 'Bad Request',
	401: 'Unauthorized',
	402: 'Payment Required',
	403: 'Forbidden',
	404: 'Not Found',
	405: 'Method Not Allowed',
	406: 'Not Acceptable',
	407: 'Proxy Authentication Required',
	408: 'Request Timeout',
	409: 'Conflict',
	410: 'Gone',
	411: 'Length Required',
	412: 'Precondition Failed',
	413: 'Payload Too Large',
	414: 'URI Too Long',
	415: 'Unsupported Media Type',
	416: 'Range Not Satisfiable',
	417: 'Expectation Failed',
	418: "I'm a teapot",
	421: 'Misdirected Request',
	422: 'Unprocessable Entity',
	423: 'Locked',
	424: 'Failed Dependency',
	426: 'Upgrade Required',
	428: 'Precondition Required',
	429: 'Too Many Requests',
	431: 'Request Header Fields Too Large',
	451: 'Unavailable For Legal Reasons',
}

export const init = () => {
	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: [
			Sentry.captureConsoleIntegration({
				levels: ['error'],
			}),
			Sentry.replayIntegration(),
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
		],
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur
		release: `poster@${import.meta.env.VITE_APP_RELEASE}`,
		profilesSampleRate: 1.0,
		tracesSampleRate: 1.0,
		// https://docs.sentry.io/product/session-replay/getting-started/#replays-for-backend-errors
		tracePropagationTargets: [
			/^\/api/,
			import.meta.env.VITE_DOMAIN.replace('app.', ''),
			import.meta.env.VITE_DOMAIN,
		],
		beforeSend: event => {
			// to debug logs and filter out more errors from Sentry
			console.log('[SENTRY]: Before send', event)

			// HTTP request error
			if (event?.request?.url) {
				// ignore 4xx errors
				if (
					Object.values(ERRORS_MAP_4XX).includes(
						(event.extra?.arguments as { status: string }[])?.[0]?.status,
					)
				) {
					console.log('[SENTRY]: Error filtered out and should not be sent')
					return null
				}
			}

			for (const e of event?.exception?.values || []) {
				// filter out UnhandledRejection errors that have no information
				if (
					e.type === 'UnhandledRejection' &&
					e.value === 'Non-Error promise rejection captured with value: '
				) {
					console.log('[SENTRY]: Error filtered out and should not be sent')
					return null
				}

				// filter out TurnstileError
				if (e.type === 'TurnstileError' && e.value?.includes('600010')) {
					console.log('[SENTRY]: Error filtered out and should not be sent')
					return null
				}
			}

			// When we enable Sentry for local testing
			if (import.meta.env.NODE_ENV === 'development') {
				// HMR errors
				if (event.message?.includes('[hmr] Failed to reload')) {
					console.log('[SENTRY]: Error filtered out and should not be sent')
					return null
				}

				// React 19 warning
				if (event.message?.includes('Support for defaultProps')) {
					console.log('[SENTRY]: Error filtered out and should not be sent')
					return null
				}
			}

			return event
		},
	})
}
