import { useState } from 'react'
import { useTheme } from '@emotion/react'
import { useTranslation, Trans } from 'react-i18next'
import * as Icons from 'lucide-react'

import { HStack } from '@prostpost/uikit'
import { Typography } from '@prostpost/uikit-next'

import { UserSettings } from 'app/modals/UserSettings'

import { Banner, BannerTextLink } from '../Banner'

export const BotNotConnected = () => {
	const theme = useTheme()
	const { t } = useTranslation()

	const [isUserSettingsOpen, setIsUserSettingsOpen] = useState(false)

	return (
		<Banner>
			<HStack space={2} alignItems="center">
				<Icons.RefreshCwOff size={14} strokeWidth={2.5} color={theme.colors.black_80} />
				<Typography variant="heading" color="common_black" size={5}>
					{t('content:feedBanners.botNotConnected.title', 'Bot is not connected to your channel')}
				</Typography>
			</HStack>

			<Typography variant="paragraph" color="common_black" size={12}>
				<Trans i18nKey="content:feedBanners.botNotConnected.title">
					{'Please '}
					<BannerTextLink variant="plain" color="blue_100" onClick={() => setIsUserSettingsOpen(true)}>
						{'add our bot'}
					</BannerTextLink>
					{
						' as an admin to your channel to be able to preview, schedule and publish posts. It will not take longer than a minute to setup.'
					}
				</Trans>
			</Typography>

			<UserSettings
				isOpen={isUserSettingsOpen}
				currentTab="account"
				nestedModal="connect_bot"
				onClose={() => setIsUserSettingsOpen(false)}
			/>
		</Banner>
	)
}
