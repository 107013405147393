import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { useCurrentMedia } from '@prostpost/css'
import { Stat, HStack } from '@prostpost/uikit'

import { useUserPostsLimit, useUserChannelsLimit } from 'app/domains/UserPreferences/hooks'

const LimitsTrial = observer(({ postsLimit }: { postsLimit: number }) => {
	const { t } = useTranslation()
	const MEDIA = useCurrentMedia()
	const isMobile = MEDIA === 'MOBILE'

	const { postsLimitUsed, postsLimitReached } = useUserPostsLimit()
	const { channelsLimitTotal, channelsLimitUsed, channelsLimitReached } = useUserChannelsLimit()

	return (
		<HStack space={isMobile ? 6 : 8} mb={2}>
			<Stat variant="dimmed" size="big" useIcon={Icons.Radio}>
				<Stat.Value
					color={channelsLimitReached ? 'red_100' : 'black_80'}
				>{`${channelsLimitUsed} of ${channelsLimitTotal}`}</Stat.Value>
				<Stat.Label>
					{t('userSettings.plans.channelsLimits', 'Active channels', { count: channelsLimitTotal || 1 })}
				</Stat.Label>
			</Stat>

			<Stat size="big" variant="dimmed" useIcon={Icons.MessageSquare}>
				<Stat.Value color={postsLimitReached ? 'red_100' : 'black_80'}>
					<HStack space={2} align="center">
						{postsLimitUsed} {'of'}
						{` ${postsLimit}`}
					</HStack>
				</Stat.Value>
				<Stat.Label>{t('userSettings.plans.postsLimitsSent', 'Delivered posts')}</Stat.Label>
			</Stat>
		</HStack>
	)
})

const LimitsPlan = observer(() => {
	const { t } = useTranslation()
	const MEDIA = useCurrentMedia()
	const isMobile = MEDIA === 'MOBILE'

	const { postsLimitUsed, postsLimitReached } = useUserPostsLimit()
	const { channelsLimitTotal, channelsLimitUsed, channelsLimitReached } = useUserChannelsLimit()

	return (
		<HStack space={isMobile ? 6 : 8} mb={2}>
			<Stat variant="dimmed" size="big" useIcon={Icons.Radio}>
				<Stat.Value
					color={channelsLimitReached ? 'red_100' : 'black_80'}
				>{`${channelsLimitUsed} of ${channelsLimitTotal}`}</Stat.Value>
				<Stat.Label>
					{t('userSettings.plans.channelsLimits', 'Active channels', { count: channelsLimitTotal || 1 })}
				</Stat.Label>
			</Stat>

			<Stat size="big" variant="dimmed" useIcon={Icons.MessageSquare}>
				<Stat.Value color={postsLimitReached ? 'red_100' : 'black_80'}>
					<HStack space={2} align="center">
						{postsLimitUsed} {'of'}
						<Icons.Infinity size={24} strokeWidth={2.5} style={{ marginTop: '2px' }} />
					</HStack>
				</Stat.Value>
				<Stat.Label>
					{isMobile
						? t('userSettings.plans.postsLimitsMobile', 'Delivered posts')
						: t('userSettings.plans.postsLimitsMonthly', 'Delivered posts (monthly)')}
				</Stat.Label>
			</Stat>
		</HStack>
	)
})

export const Limits = { Trial: LimitsTrial, Plan: LimitsPlan }
