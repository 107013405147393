import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Observer, observer } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { useTheme } from '@emotion/react'
import * as Icons from 'lucide-react'

import { Group } from '@prostpost/uikit'
import { Input } from '@prostpost/uikit-next'

import { useUserAnalyticsEvents } from 'app/config/analytics/events/user'

import { useOnboardedUserStore } from 'app/domains/User/store/slices'

import { useUserBasicInformationForm } from '../../hooks'

export const UserBasicInformationPlain = observer(function UserBasicInformation() {
	const theme = useTheme()
	const { t } = useTranslation()

	const user = useOnboardedUserStore()
	const userEvents = useUserAnalyticsEvents()
	const { control, fields, handleSubmit } = useUserBasicInformationForm()

	return (
		<Group>
			<Group.Title title={t('userSettings.basics.title', 'Basics')}>
				<Icons.User size={16} strokeWidth={2.5} color={theme.colors.black_80} />
			</Group.Title>
			<Group.Item>
				<Group.ItemContent>
					<Controller
						name="firstName"
						control={control}
						render={({ field }) => (
							<Observer>
								{() => (
									<Input
										size="xs"
										variant={fields.firstName.error ? 'error' : 'normal'}
										message={fields.firstName.error}
										defaultValue={user.firstName}
										label={t('userSettings.basics.firstNameLabel', 'First name')}
										labelPosition="outside"
										{...field}
										onBlur={() =>
											runInAction(() => {
												userEvents.fields.firstNameChange()
												fields.firstName.clearErrors()
												if (field.value !== user.firstName) void handleSubmit()
											})
										}
									/>
								)}
							</Observer>
						)}
					/>
				</Group.ItemContent>
			</Group.Item>
			<Group.Item>
				<Group.ItemContent>
					<Controller
						name="lastName"
						control={control}
						render={({ field }) => (
							<Observer>
								{() => (
									<Input
										size="xs"
										variant={fields.lastName.error ? 'error' : 'normal'}
										message={fields.lastName.error}
										defaultValue={user.lastName}
										label={t('userSettings.basics.lastNameLabel', 'Last name')}
										labelPosition="outside"
										{...field}
										onBlur={() =>
											runInAction(() => {
												userEvents.fields.lastNameChange()
												fields.firstName.clearErrors()
												if (field.value !== user.lastName) void handleSubmit()
											})
										}
									/>
								)}
							</Observer>
						)}
					/>
				</Group.ItemContent>
			</Group.Item>
		</Group>
	)
})
