import { canUseDOM, noop, constant } from '@prostpost/utils'

type LocalStorageItems = {
	isSigningIn: 'true'
	isAuthOpen: 'true'
	imagesProcessingWarning: 'visible' | 'hidden'
	showTopStickerTimeout: string
	'umami.disabled': '0' | '1'
}

const getItem = <K extends keyof LocalStorageItems>(key: K): LocalStorageItems[K] | null => {
	return localStorage.getItem(key) as LocalStorageItems[K] | null
}

const setItem = <K extends keyof LocalStorageItems>(key: K, value: LocalStorageItems[K]) => {
	localStorage.setItem(key, value)
}

const removeItem = (key: keyof LocalStorageItems) => {
	localStorage.removeItem(key)
}

export const localStorageTyped = canUseDOM()
	? {
			getItem,
			setItem,
			removeItem,
		}
	: {
			setItem: noop,
			removeItem: noop,
			getItem: constant(null),
		}
