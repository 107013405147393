import { useTranslation } from 'react-i18next'

import { notReachable } from '@prostpost/utils'
import { Flex, Group } from '@prostpost/uikit'

import { usePricingPlans } from 'app/domains/PricingPlan/api'
import { ErrorWidget } from 'app/domains/Error/components'

import { MonthlyPlanTile, AnnualPlanTile, TeamPlanTile, PlansLoading } from './components'

export const PricingPlansTilesGroup = () => {
	const { t } = useTranslation()
	const { pricingPlansQuery } = usePricingPlans()

	switch (pricingPlansQuery.status) {
		case 'idle':
		case 'loading':
			return <PlansLoading />

		case 'success': {
			const { data } = pricingPlansQuery
			return (
				<>
					<Group.Item>
						<Group.ItemContent>
							<MonthlyPlanTile plan={data.MONTH} />
						</Group.ItemContent>
					</Group.Item>
					<Group.Item>
						<Group.ItemContent>
							<AnnualPlanTile plan={data.ANNUAL} />
						</Group.ItemContent>
					</Group.Item>
					<Group.Item>
						<Group.ItemContent>
							<TeamPlanTile />
						</Group.ItemContent>
					</Group.Item>
				</>
			)
		}

		case 'error':
			return (
				// height: <height of plan tile> * <count of plans> + <margin between tiles> * <count of plans - 1>
				<Flex align="center" just="center" w="100%" h={`${145 * 3 + 16 * 2}px`}>
					<ErrorWidget
						size="mid"
						error={pricingPlansQuery.error}
						summary={t('userSettings.plans.fetchFailed', 'Unable to load pricing plans')}
						onTryAgain={pricingPlansQuery.refetch}
					/>
				</Flex>
			)

		default:
			return notReachable(pricingPlansQuery)
	}
}
