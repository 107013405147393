import { makeLoggable } from 'mobx-log'
import { observable, action, makeObservable } from 'mobx'

import type { ChannelActive } from '..'

type UpdateData = { title?: string; description?: string } & (
	| { avaBig: string; avaSmall: string }
	| { avaBig?: never; avaSmall?: never }
)

type InitArgs = { activeChannels: ChannelActive[]; callbacks?: Callbacks }

type Callbacks = { onArchiveChannel?: (channelName: string) => void }

export class StoreChannels {
	declare channelsLimitReached: boolean
	declare current: ChannelActive | undefined

	declare activeList: ChannelActive[]

	declare callbacks: Callbacks

	init({ activeChannels, callbacks = {} }: InitArgs) {
		this.current = undefined
		this.channelsLimitReached = false

		this.activeList = activeChannels

		this.callbacks = callbacks
	}

	constructor(args: InitArgs) {
		this.init(args)

		makeObservable(this, {
			current: observable,
			activeList: observable,
			channelsLimitReached: observable,

			init: action,
			setCurrent: action,
			resetCurrent: action,
			updateChannel: action,
			setActiveList: action,
			addNewChannel: action,
			archiveChannel: action,
			isChannelActive: action,
			getActiveChannelByName: action,
			setChannelsLimitReached: action,
		})

		makeLoggable(this)
	}

	isChannelActive(channelName: string) {
		return !!this.activeList.some(ch => ch.name === channelName)
	}

	getActiveChannelByName(channelName: string) {
		return this.activeList.find(ch => ch.name === channelName)
	}

	getActiveChannelByUuid(uuid: string) {
		return this.activeList.find(ch => ch.uuid === uuid)
	}

	addNewChannel(channel: ChannelActive) {
		this.setActiveList([...this.activeList, channel])
	}

	setActiveList(channels: ChannelActive[]): void {
		this.activeList = channels
	}

	setChannelsLimitReached(reached: boolean): void {
		this.channelsLimitReached = reached
	}

	setCurrent(channel: ChannelActive): void {
		this.current = channel
		this.activeList = this.activeList.map(ch => (ch.name === channel.name ? channel : ch))
	}

	resetCurrent(): void {
		this.current = undefined
	}

	archiveChannel(channel: ChannelActive) {
		this.activeList = this.activeList.filter(ch => ch.name !== channel.name)
		this.callbacks.onArchiveChannel?.(channel.name)

		if (this.current?.name === channel.name) {
			this.resetCurrent()
		}
	}

	updateChannel(chName: string, data: UpdateData) {
		this.setActiveList(this.activeList.map(ch => (ch.name === chName ? { ...ch, ...data } : ch)))
		if (this.current?.name === chName) {
			this.setCurrent({ ...this.current, ...data })
		}
	}
}
