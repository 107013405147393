import { useMutation } from 'react-query'
import type { AxiosError } from 'axios'

import { parseFirstError } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, ParsedError, JsonApiResponseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import { StripeCheckoutSessionSchema } from './schemas'

type Data = { variant: 'monthly' | 'annual'; extraChannels?: number }

const knownErrors = ['UNABLE_TO_CREATE_CHECKOUT_LINK', 'STRIPE_RETURNS_EMPTY_CHECKOUT_SESSION_LINK'] as const

type Response = { url: string } | { url: 'EMPTY'; reason: 'EXISTING_ACTIVE_SUBSCRIPTION_ASSIGNED' }

const createCheckoutSession = (data: Data): Promise<Response> =>
	axiosInstance
		.post<JsonApiResponseSingle>('/auth/checkout-session', data)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(({ data }) => StripeCheckoutSessionSchema.parse(data))

export const useStripeCheckoutSession = () => {
	return {
		createCheckoutSessionMutation: useMutation<Response, ParsedError<(typeof knownErrors)[number]>, Data>(
			createCheckoutSession,
		),
	}
}
