import { useMutation } from 'react-query'
import type { AxiosError } from 'axios'

import { parseFirstError, parseSingle } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, ParsedError, JsonApiResponseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import { WaitlistSchema } from '../api/schemas'

type Data = {
	email: string
	privacy: boolean
	captchaToken: string
}

type PublicUserResult = {
	email: string
	updateToken: string
}

const knownErrors = ['YOU_ARE_ALREADY_IN_WAITLIST'] as const

const joinWaitlistPrivate = (): Promise<null> =>
	axiosInstance
		.post<JsonApiResponseSingle>('/auth/waitlist')
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(() => null)

const joinWaitlistPublic = (data: Data): Promise<PublicUserResult> =>
	axiosInstance
		.post<JsonApiResponseSingle>('/auth/public/waitlist', data)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return WaitlistSchema.parse(result.data)
		})

export const useJoinWaitlistPublic = () => {
	return {
		joinWaitlistMutation: useMutation<PublicUserResult, ParsedError<(typeof knownErrors)[number]>, Data>(
			joinWaitlistPublic,
		),
	}
}

export const useJoinWaitlistPrivate = () => {
	return {
		joinWaitlistMutation: useMutation<null, ParsedError<(typeof knownErrors)[number]>, null>(joinWaitlistPrivate),
	}
}
