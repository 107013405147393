import { observer } from 'mobx-react-lite'

import { useShowTopSticker } from 'app/shared/hooks'

import { TrialSticker } from './stickers/Trial'
import { SubscriptionCanceledSticker } from './stickers/SubscriptionCanceled'

export const TopSticker = observer(() => {
	const { topSticker, onDismissSticker } = useShowTopSticker()

	if (topSticker.visible === 'NONE') return null

	switch (topSticker.visible) {
		case 'TRIAL':
			return <TrialSticker onDismiss={onDismissSticker} />
		case 'SUBSCRIPTION_CANCELED':
			return <SubscriptionCanceledSticker onDismiss={onDismissSticker} />
		default:
			return null
	}
})
