import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

import { getAnimation } from '@prostpost/css'
import { Flex, VStack } from '@prostpost/uikit'

const animationSkeleton = keyframes`
	0% { transform: translateX(-90%); }
	100% { transform: translateX(90%); }
`

export const LoadingOverlay = styled(Flex)`
	content: '';

	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background: linear-gradient(90deg, transparent 0%, rgb(255 255 255 / 50%) 52%, transparent 100%);
	animation-name: ${animationSkeleton};

	animation-duration: 2s;
	animation-iteration-count: infinite;
`

export const FloatingButtonStyled = styled(Flex)`
	visibility: hidden;
	transition: transform ${getAnimation('tt_tf')};

	&:hover {
		transform: scale(1.1);
		transition: transform ${getAnimation('tt_tf')};
	}
`

export const ContainerStyled = styled(VStack)<{ isPending: boolean; isDisabled: boolean }>`
	&:hover {
		> [data-label='floating-button'] {
			visibility: visible;
		}
	}
`
