import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useTheme } from '@emotion/react'
import * as Icons from 'lucide-react'

import { Toggle, Group } from '@prostpost/uikit'

import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import type { UpdatePreferencesData, AdditionalPreferences } from 'app/domains/UserPreferences/api'

type Msg =
	| { type: 'on_change_show_post_images'; value: boolean }
	| { type: 'on_change_show_unsupported_posts'; value: boolean }

type Props = {
	loadingState: Array<keyof UpdatePreferencesData | keyof AdditionalPreferences>
	onMsg: (msg: Msg) => void
}

export const UpdateFeedViewPreferences = observer(function UpdateFeedViewPreferences({ loadingState, onMsg }: Props) {
	const theme = useTheme()
	const { t } = useTranslation()
	const { preferences } = useOnboardedUserStore()

	return (
		<Group>
			<Group.Title title={t('userSettings.preferences.feed2', 'Posts feed')}>
				<Icons.ChartNoAxesGantt size={16} strokeWidth={2.5} color={theme.colors.black_80} />
			</Group.Title>
			<Group.Item>
				<Group.ItemContent>
					<Toggle
						on={preferences.feed.isPostImagesShown}
						disabled={loadingState.includes('simpleFeedView')}
						onToggle={value => onMsg({ type: 'on_change_show_post_images', value })}
					>
						<Toggle.Label color="black_80">
							{t('userSettings.preferences.feedShowImages', 'Show post images')}
						</Toggle.Label>
					</Toggle>
				</Group.ItemContent>
			</Group.Item>
			<Group.Item>
				<Group.ItemContent>
					<Toggle
						on={preferences.feed.isUnsupportedPostsShown}
						onToggle={value => onMsg({ type: 'on_change_show_unsupported_posts', value })}
					>
						<Toggle.Label color="black_80">
							{t('userSettings.preferences.feedOnlySupportedPosts', 'Show not supported posts')}
						</Toggle.Label>
					</Toggle>
				</Group.ItemContent>
			</Group.Item>
		</Group>
	)
})
