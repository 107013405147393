import ReactModal from 'react-modal'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { Moment } from 'moment'

import { useToast } from '@prostpost/toast'
import { notReachable } from '@prostpost/utils'
import { Modal, Heading, Paragraph } from '@prostpost/uikit'

import { usePricingPlans } from 'app/domains/PricingPlan/api'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { useStripeSubscriptionModify } from 'app/domains/PricingPlan/api'
import { usePlansAnalyticsEvents } from 'app/config/analytics/events/plans'

type Props = {
	isOpen: boolean
	currentPlanValidUntil: Moment
	onClose: () => void
	onConfirm?: () => void
}

export const useToasts = () => {
	const { t } = useTranslation()

	const successfullySwitchedToAnnual = useToast({
		type: 'success',
		text: t('userSettings.plans.annualSwitch.success', 'You are now switched to annual plan'),
	})

	const annualSwitchFailed = useToast({
		type: 'error',
		text: t('userSettings.plans.annualSwitch.failed', 'Unable to switch to annual plan'),
	})

	return { successfullySwitchedToAnnual, annualSwitchFailed }
}

export const ModalSwitchAnnualWarning = ({ isOpen, currentPlanValidUntil, ...props }: Props) => {
	const { t } = useTranslation()
	const planEvents = usePlansAnalyticsEvents()

	const { pricingPlansQuery } = usePricingPlans()
	const { preferences } = useOnboardedUserStore()
	const { modifyStripeSubscriptionMutation } = useStripeSubscriptionModify()

	const onClose = () => {
		planEvents.clicks.annualWarningModalClosed()
		props.onClose()
	}

	useEffect(() => {
		switch (modifyStripeSubscriptionMutation.status) {
			case 'idle':
			case 'loading':
				break
			case 'success':
				// must be there - just for TS
				if (!pricingPlansQuery.data) return
				preferences.setPreferences({
					...preferences,
					plan: pricingPlansQuery.data.ANNUAL,
					limits: {
						...preferences.limits,
						posts: pricingPlansQuery.data.ANNUAL.limits.posts,
						channels:
							pricingPlansQuery.data.ANNUAL.limits.channels +
							// extra channels are already added to the main subscription
							(preferences.limits.channels - pricingPlansQuery.data.MONTH.limits.channels),
					},
				})
				break
			case 'error':
				break
			default:
				notReachable(modifyStripeSubscriptionMutation)
		}
	}, [modifyStripeSubscriptionMutation.status])

	// must be already loaded
	if (!pricingPlansQuery.data) return null

	return (
		<ReactModal
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
			isOpen={isOpen}
			onRequestClose={e => {
				e.preventDefault()
				e.stopPropagation()
				onClose()
			}}
		>
			<Modal variant="confirm">
				<Modal.Close onClick={onClose} />
				<Modal.Content>
					<Heading h={4}>{t('content:plans.annualSwitch.cancelTitle', 'Switch to Annual plan')}</Heading>
					<Paragraph my={3} size={14} color="blue_40">
						{t(
							'content:plans.annualSwitch.description',
							'You currently are on a monthly plan active until {{date}}. If you switch to an annual plan your subscription will start today and monthly plan will be automatically canceled.',
							{ date: currentPlanValidUntil.format('DD MMM YYYY') },
						)}
					</Paragraph>
				</Modal.Content>
				<Modal.ConfirmPrimaryAction
					variant="primary"
					state={modifyStripeSubscriptionMutation.isLoading ? 'pending' : 'normal'}
					onClick={() => {
						modifyStripeSubscriptionMutation.mutate({
							variant: 'annual',
							extraChannels: preferences.limits.channels - pricingPlansQuery.data.MONTH.limits.channels,
						})

						planEvents.clicks.annualWarningModalConfirm()
						props.onConfirm?.()
					}}
				>
					{t('content:plans.annualSwitch.confirm', 'Switch to Annual plan')}
				</Modal.ConfirmPrimaryAction>
			</Modal>
		</ReactModal>
	)
}
