import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from '@prostpost/toast'
import { notReachable, useOpenWindow, canUseDOM } from '@prostpost/utils'

import { config } from 'app/config'
import { routesUrls } from 'app/routes/urls'
import { useStripeCheckoutSession } from 'app/domains/PricingPlan/api'

const useToasts = () => {
	const { t } = useTranslation()
	const checkoutSessionFailedToOpen = useToast({
		type: 'error',
		text: t('notify:auth.checkoutSession.failed', 'Unable to proceed with checkout'),
	})

	return { checkoutSessionFailedToOpen }
}

export const useStripeCheckout = () => {
	const openNewWindow = useOpenWindow()
	const { checkoutSessionFailedToOpen } = useToasts()
	const { createCheckoutSessionMutation } = useStripeCheckoutSession()

	useEffect(() => {
		switch (createCheckoutSessionMutation.status) {
			case 'idle':
			case 'loading':
				break
			case 'error':
				switch (createCheckoutSessionMutation.error.code) {
					case 'UNKNOWN_ERROR':
					case 'NOT_VALID_ERROR':
					case 'UNABLE_TO_CREATE_CHECKOUT_LINK':
					case 'STRIPE_RETURNS_EMPTY_CHECKOUT_SESSION_LINK':
						checkoutSessionFailedToOpen.show()
						break
					default:
						notReachable(createCheckoutSessionMutation.error.code)
				}
				break
			case 'success':
				// existing subscription was applied
				if (createCheckoutSessionMutation.data.url === 'EMPTY') {
					canUseDOM(() =>
						window.location.assign(
							`${window.location.protocol}//${window.location.host}${routesUrls.inbox}?modal=plan`,
						),
					)
					break
				}

				// since we redirect a user to Stripe Checkout we need to enable
				// success and error state pages after user is back
				config.cookies.setCookie('billing_success', 'true')
				config.cookies.setCookie('billing_failed', 'true')
				openNewWindow(createCheckoutSessionMutation.data.url)
				break
			default:
				notReachable(createCheckoutSessionMutation)
		}
	}, [createCheckoutSessionMutation.status])

	return {
		stripeSessionIsLoading: createCheckoutSessionMutation.isLoading,
		stripeSessionPlan: createCheckoutSessionMutation.variables?.variant,
		createStripeCheckoutSession: createCheckoutSessionMutation.mutate,
	}
}
