import { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import type { Icon } from 'react-feather'

import { notReachable } from '@prostpost/utils'
import { useCurrentMedia } from '@prostpost/css'
import { Button, Flex } from '@prostpost/uikit'
import { Input } from '@prostpost/uikit-next'

import { useBotAnalyticsEvents } from 'app/config/analytics/events/bot'
import { useSetBotLink, useBotLinkForm } from 'app/domains/Bot/features/LinkBotToUser/hooks'

export type Msg =
	| { type: 'on_bot_is_linked_to_user' }
	| { type: 'on_bot_link_error'; error: ReturnType<typeof useSetBotLink>['botLinkError'] }

type Props = {
	size: 'normal' | 'mid'
	submitButton: { label: string; icon: Icon }
	onMsg: (msg: Msg) => void
}

export const LinkBotToUserForm = ({ size, submitButton, onMsg }: Props) => {
	const UseSubmitIcon = submitButton.icon

	const MEDIA = useCurrentMedia()
	const isMobile = MEDIA === 'MOBILE'

	const { botLinkStatus, setBotLink, botLinkError } = useSetBotLink()
	const form = useBotLinkForm({ onSubmit: setBotLink })
	const botEvents = useBotAnalyticsEvents()

	useEffect(() => {
		switch (botLinkStatus.type) {
			case 'idle':
			case 'loading':
				break
			case 'error':
				form.fields.code.setError(botLinkStatus.message)
				onMsg({ type: 'on_bot_link_error', error: botLinkError })
				break
			case 'success':
				onMsg({ type: 'on_bot_is_linked_to_user' })
				break
			default:
				notReachable(botLinkStatus)
		}
	}, [botLinkStatus])

	return (
		<Flex gap={4} w="100%" dir={isMobile ? 'column' : 'row'} maxW={isMobile ? '100%' : '500px'}>
			<Controller
				name="code"
				control={form.control}
				render={({ field }) => (
					<Input
						onPaste={event => {
							// if reading without timeout - we get empty value
							setTimeout(() => {
								form.setValue('code', (event.target as HTMLInputElement).value)
								form.onSubmit()
							}, 0)
						}}
						size={size === 'mid' ? 'xs' : 'md'}
						variant={form.fields.code.error ? 'error' : 'normal'}
						message={form.fields.code.error}
						{...field}
						{...form.fields.code.inputProps}
					/>
				)}
			/>

			<Button.Action
				size={size}
				variant="primary"
				iconPosition="right"
				onClick={() => {
					botEvents.clicks.reconnectSubmit()
					form.onSubmit()
				}}
				fullWidth={isMobile}
				state={botLinkStatus.type === 'loading' ? 'pending' : 'normal'}
				icon={(() => {
					switch (size) {
						case 'normal':
							return <UseSubmitIcon size={20} strokeWidth={2.5} />
						case 'mid':
							return <UseSubmitIcon size={18} strokeWidth={3} />
						default:
							return notReachable(size)
					}
				})()}
			>
				{submitButton.label}
			</Button.Action>
		</Flex>
	)
}
