import { useLocation } from 'react-router-dom'

import { trackEvent } from '../track'

export const useNewChannelAnalyticsEvents = () => {
	const ref = useLocation().pathname
	const trackClick = (e: string, params?: UmamiData) => trackEvent('new_channel', ref, 'click', e, params)
	const trackError = (e: string, params?: UmamiData) => trackEvent('new_channel', ref, 'error', e, params)
	return {
		clicks: {
			add: () => trackClick('add'),
			addBanner: () => trackClick('add_banner'),
			botExternalUrl: () => trackClick('bot_link'),
			modalClosed: () => trackClick('modal_closed'),
			submitNewChannel: () => trackClick('submit'),
		},
		errors: {
			invalidChannelName: () => trackError('invalid_name'),
		},
	}
}

export const useChannelAnalyticsEvents = () => {
	const ref = useLocation().pathname
	const trackClick = (e: string, params?: UmamiData) => trackEvent('channel', ref, 'click', e, params)
	return {
		clicks: {
			// Nav
			editor: () => trackClick('editor'),
			overview: () => trackClick('overview'),
			settings: () => trackClick('settings'),
			selectChannel: () => trackClick('select_channel'),
			// Info
			updateInfo: () => trackClick('update_info'),
			refreshFromTelegram: () => trackClick('refresh_from_telegram'),
			// Ava
			uploadAva: () => trackClick('upload_ava'),
			removeAva: () => trackClick('remove_ava'),
			cropAva: () => trackClick('crop_ava'),
			// Share
			copyShareLink: () => trackClick('copy_share_link'),
			// Archive
			archive: () => trackClick('archive'),
			archiveCancel: () => trackClick('archive_cancel'),
			archiveConfirm: () => trackClick('archive_confirm'),
			archiveModalClosed: () => trackClick('archive_modal_closed'),
		},
	}
}
