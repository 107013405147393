import styled from '@emotion/styled'
import type { ComponentProps } from 'react'

import { isMobile } from '@prostpost/utils'
import { Flex, Scrollbars } from '@prostpost/uikit'
import { mediaQueries, MEDIA, useCurrentMedia } from '@prostpost/css'

import importedStyles from './Scrollbars.module.less'
const styles = importedStyles as Styles

type Styles = {
	wrapper: string
	trackYMain: string
	trackYMainHidden: string
}

type Props = Omit<ComponentProps<typeof Flex>, 'w' | 'just'> & {
	centered?: boolean
	width?: 'auto' | 'full'
}

export const ContentContainer = styled(Flex)<Props>`
	position: relative;

	display: flex;
	flex-direction: column;
	flex-grow: 1;

	width: ${({ width = 'auto' }) => (width === 'full' ? '100%' : 'auto')};

	${mediaQueries[MEDIA.MOBILE]} {
		padding-right: ${({ theme }) => theme.space[6]};
		padding-left: ${({ theme }) => theme.space[6]};
	}
`

export const Content = ({ children, centered, ...props }: Props) => {
	const media = useCurrentMedia()
	return (
		<Scrollbars
			position="relative"
			styles={{
				trackY: isMobile() ? styles.trackYMainHidden : styles.trackYMain,
				wrapper: styles.wrapper,
			}}
		>
			<ContentContainer
				px={9}
				h="100dvh"
				align={media === 'DESKTOP_WIDE' || media === 'DESKTOP_NARROW' ? 'center' : 'normal'}
			>
				<Flex dir="column" just="space-between" h="100%" {...props}>
					{/* On mobile always align to the top instead of center */}
					{centered && media !== 'MOBILE' && <span />}
					{children}
					{centered && media !== 'MOBILE' && <span />}
				</Flex>
			</ContentContainer>
		</Scrollbars>
	)
}
