/* eslint-disable */
// @ts-nocheck
// import type React from 'react'
/// <reference types="vite-plugin-svgr/client" />

import VerifyEmail from './images/verify-email.png'
import NoImage from './images/no-image.png'
import AddNewChannel from './images/add-new-channel.png'
import BotLink from './images/bot-link.png'
import NoChannelsPlaceholder from './images/no-channels-placeholder.png'
import PricingPro from './images/pricing-pro.png'

// auth
import SigninSide from './images/signin-side.webp'
import SignupSide from './images/signup-side.webp'

// onboarding
import OnboardingWelcome from './images/onboarding-welcome.png'
import OnboardingBot from './images/onboarding-bot.png'

// changelog illustrations
import ChangelogSharedTemplates from './images/changelog/shared-templates.webp'
import ChangelogPostsStreak from './images/changelog/posts-streak.webp'
import ChangelogDraftColorTags from './images/changelog/draft-color-tags.webp'

// it's necessary to use "ReactComponent as" otherwise we have an error while building with Vite
import Google from './icons/google.inline.svg?react'
import GraphPlaceholder from './icons/graph-placeholder.inline.svg?react'

import EnFlag from './icons/en.inline.svg?react'
import DeFlag from './icons/de.inline.svg?react'

import LogoIconYellow from './icons/logo-icon-yellow.inline.svg?react'
import LogoWithTextYellowWhite from './icons/logo-with-text-yellow-white.inline.svg?react'
import LogoWithTextBlueBlack from './icons/logo-with-text-blue-black.inline.svg?react'

import PaymentFailed from './icons/payment-failed.inline.svg?react'
import PaymentSucceed from './icons/payment-succeed.inline.svg?react'

export const Icons = {
	// flags
	EnFlag: EnFlag as React.FC,
	DeFlag: DeFlag as React.FC,

	// socials
	Google: Google as React.FC,

	// logo
	LogoIconYellow: LogoIconYellow as React.FC,
	LogoWithTextBlueBlack: LogoWithTextBlueBlack as React.FC,
	LogoWithTextYellowWhite: LogoWithTextYellowWhite as React.FC,

	// doodles
	PaymentFailed: PaymentFailed as React.FC,
	PaymentSucceed: PaymentSucceed as React.FC,

	// other
	GraphPlaceholder: GraphPlaceholder as React.FC,
}

export const Images = {
	VerifyEmail: VerifyEmail as string,
	NoImage: NoImage as string,
	OnboardingWelcome: OnboardingWelcome as string,
	OnboardingBot: OnboardingBot as string,
	AddNewChannel: AddNewChannel as string,
	BotLink: BotLink as string,
	NoChannelsPlaceholder: NoChannelsPlaceholder as string,
	PricingPro: PricingPro as string,
	SigninSide: SigninSide as string,
	SignupSide: SignupSide as string,
	// changelog illustrations
	ChangelogDraftColorTags: ChangelogDraftColorTags as string,
	ChangelogSharedTemplates: ChangelogSharedTemplates as string,
	ChangelogPostsStreak: ChangelogPostsStreak as string,
}

import confettiLottieAnimation from './animations/confetti.json'
import React from 'react'

export const LottieAnimations = { Confetti: confettiLottieAnimation as string }
