import { useTranslation } from 'react-i18next'

import { useToast } from '@prostpost/toast'
import { Modal, Paragraph, Heading } from '@prostpost/uikit'

import { ReactModal } from 'app/modals'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { useStripeSubscriptionModify } from 'app/domains/PricingPlan/api'

type Msg = { type: 'on_modal_close' }

type MsgProp = {
	onMsg: (msg: Msg) => void
}

type Interface = {
	isOpen: boolean
	isPending: boolean
}

const useToasts = () => {
	const { t } = useTranslation()

	const unableToUpdateSubscription = useToast({
		type: 'error',
		text: t('content:modals.reclaim.error.subscription', 'Unable to update subscription'),
	})

	return { unableToUpdateSubscription }
}

export const ModalConfirmRemoveSlot = ({ isOpen, isPending, onMsg }: Interface & MsgProp) => {
	const toasts = useToasts()
	const { t } = useTranslation()

	const user = useOnboardedUserStore()

	const { modifyStripeSubscriptionMutation } = useStripeSubscriptionModify({
		onError: () => toasts.unableToUpdateSubscription.show(),
		onSuccess: () => {
			user.preferences.setPreferences({
				...user.preferences,
				channelSlotsToReclaim: user.preferences.channelSlotsToReclaim + 1,
				limits: {
					...user.preferences.limits,
					channels: user.preferences.limits.channels - 1,
				},
			})
			onMsg({ type: 'on_modal_close' })
		},
	})

	return (
		<ReactModal
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
			isOpen={isOpen}
			onRequestClose={e => {
				e.stopPropagation()
				e.preventDefault()
				onMsg({ type: 'on_modal_close' })
			}}
		>
			<Modal variant="confirm">
				<Modal.Close onClick={() => onMsg({ type: 'on_modal_close' })} />
				<Modal.Content>
					<Heading h={4}>{t('userSettings.usage.removeSlotModal.title', 'Remove channel slot')}</Heading>
					<Paragraph my={3} size={14} color="blue_40">
						{t(
							'userSettings.usage.removeSlotModal.desc',
							'Are you sure you want to remove empty channel slot? It will be removed from billing starting from next cycle.',
						)}
					</Paragraph>
				</Modal.Content>
				<Modal.ConfirmSecondaryAction onClick={() => onMsg({ type: 'on_modal_close' })}>
					{t('userSettings.usage.removeSlotModal.cancel', 'Keep slot')}
				</Modal.ConfirmSecondaryAction>
				<Modal.ConfirmPrimaryAction
					variant="danger"
					state={isPending ? 'pending' : 'normal'}
					onClick={() =>
						modifyStripeSubscriptionMutation.mutate({
							extraChannels: user.preferences.limits.channels - user.preferences.plan.limits.channels - 1,
						})
					}
				>
					{t('userSettings.usage.removeSlotModal.confirm', 'Remove')}
				</Modal.ConfirmPrimaryAction>
			</Modal>
		</ReactModal>
	)
}
