import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { getNow } from '@prostpost/utils'

import { UserSettings } from 'app/modals/UserSettings'
import { usePlansAnalyticsEvents } from 'app/config/analytics/events/plans'
import { useSubscriptionExpired } from 'app/domains/PricingPlan/hooks'
import { useLimitsResetDate } from 'app/domains/UserPreferences/hooks'

import { TopSticker } from '../TopSticker'

type Props = { onDismiss: () => void }

export const SubscriptionCanceledSticker = ({ onDismiss }: Props) => {
	const { t } = useTranslation()
	const planEvents = usePlansAnalyticsEvents()

	const isExpired = useSubscriptionExpired()
	const limitsResetDate = useLimitsResetDate()
	const subscriptionDaysLeft = limitsResetDate.diff(getNow(false, { to: 'local' }), 'days') + 1

	const [isUserSettingsModalOpen, setIsUserSettingsModalOpen] = useState(false)

	const notificationMessage = (() => {
		if (isExpired) {
			return t(
				'content:sticker.subscriptionCanceled.titleExpired',
				'Your subscription is expired. Renew subscription to continue using the app.',
			)
		}

		if (subscriptionDaysLeft === 1) {
			return t('content:sticker.subscriptionCanceled.titleTomorrow', 'Your subscription expires tomorrow')
		} else if (subscriptionDaysLeft === 0) {
			return t('content:sticker.subscriptionCanceled.titleToday', 'Your subscription period expires today')
		} else {
			return t(
				'content:sticker.subscriptionCanceled.title',
				'Your subscription is canceled and expires in {{count}} days',
				{ count: subscriptionDaysLeft },
			)
		}
	})()

	return (
		<>
			<TopSticker
				isDismissible
				icon={Icons.TriangleAlert}
				message={notificationMessage}
				action={{
					isVisible: !isExpired,
					label: t('content:sticker.subscriptionCanceled.action', 'Renew'),
					onClick: () => {
						planEvents.clicks.trialBannerUpgradeNow()
						setIsUserSettingsModalOpen(true)
					},
				}}
				onDismiss={() => {
					planEvents.clicks.trialBannerClosed()
					onDismiss()
				}}
			/>

			<UserSettings
				currentTab="plan"
				isOpen={isUserSettingsModalOpen}
				onClose={() => setIsUserSettingsModalOpen(false)}
			/>
		</>
	)
}
