import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useTheme } from '@emotion/react'
import * as Icons from 'lucide-react'

import { Box, Toggle, Group, Paragraph, VStack } from '@prostpost/uikit'

import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import type { UpdatePreferencesData, AdditionalPreferences } from 'app/domains/UserPreferences/api'

type Msg =
	| { type: 'on_change_personalized_tracking'; value: boolean }
	| { type: 'on_change_email_subscription'; value: boolean }

type Props = {
	loadingState: Array<keyof UpdatePreferencesData | keyof AdditionalPreferences>
	onMsg: (msg: Msg) => void
}

export const UpdatePrivacyPreferences = observer(function UpdatePrivacyPreferences({ loadingState, onMsg }: Props) {
	const theme = useTheme()
	const { t } = useTranslation()
	const { preferences } = useOnboardedUserStore()

	return (
		<Group>
			<Group.Title title={t('userSettings.preferences.privacy', 'Privacy')}>
				<Icons.ShieldCheck size={16} strokeWidth={2.5} color={theme.colors.black_80} />
			</Group.Title>
			<Group.Item>
				<Group.ItemContent>
					<VStack space={1}>
						<Toggle
							on={preferences.more.emailSubscription}
							onToggle={value => onMsg({ type: 'on_change_email_subscription', value })}
						>
							<Toggle.Label color="black_80">
								{t('userSettings.preferences.emailSubscription', 'Email subscription')}
							</Toggle.Label>
						</Toggle>
						<Box pl="48px" maxW="500px">
							<Paragraph color="blue_40" size={12}>
								{t(
									'userSettings.preferences.emailSubscriptionDesc',
									"If opt-out, you won't receive any special offers and product updates. We will still send you reports and other important information about your account.",
								)}
							</Paragraph>
						</Box>
					</VStack>
				</Group.ItemContent>
			</Group.Item>
			<Group.Item>
				<Group.ItemContent>
					<VStack space={1}>
						<Toggle
							on={preferences.more.personalizedTracking}
							disabled={loadingState.includes('personalizedTracking')}
							onToggle={value => onMsg({ type: 'on_change_personalized_tracking', value })}
						>
							<Toggle.Label color="black_80">
								{t('userSettings.preferences.personalizedTracking', 'Personalized tracking')}
							</Toggle.Label>
						</Toggle>
						<Box pl="48px" maxW="500px">
							<Paragraph color="blue_40" size={12}>
								{t(
									'userSettings.preferences.personalizedTrackingDesc',
									"We don't use cookies or personal data for tracking and rely only on anonymous data. Opt-in to our personalized tracking helps us to improve our services and resolve your issues faster since we can link your actions to your account.",
								)}
							</Paragraph>
						</Box>
					</VStack>
				</Group.ItemContent>
			</Group.Item>
		</Group>
	)
})
