import { Page } from './Page'
import { Header } from './Header'
import { Content } from './Content'
import { Footer } from './Footer'
import { Right } from './Right'
import { Left } from './Left'

export const PublicPageLayout = Object.assign(Page, {
	Header,
	Footer,
	Content,
	Left,
	Right,
})
